'use strict';
angular.module('ChicoApp.CC-Dashboard')
    .controller('CC-DashboardController', function(
      $rootScope,
      $scope,
      $state,
      $http,
      $modal,
      $translate,
      Metronic,
      moment,
      $window,
      $log,
      $cookies,
      scgSharedFactory,
      ccDashboardFactory,
      ccUsageFactory,
      scgGlobalSpinnerFactory,
      scgLicenceFactory,
      sseFactory,
      AuthFactory,
      DeviceFactory,
      apiFunctions,
      menuFactory,
      $interval,
      API,
      modalFactory) {

        var watchers = [];

      var apiDateFormat = 'YYYY-MM-DD HH:mm:SS';

      //Dierk TODO: API Implemention

      $scope.data = {};
      $scope.data.isSomeActive = null;
      $scope.serverTimeDiff = 0;
      $scope.deviceType = -1;
      $scope.data.isSomeActive = true;
      $scope.hasNotice = false;   //trial stuff
      $scope.lastSync = '   ' + $translate.instant('MISC.RESOLVING');
      $scope.data.isTimeActive = true;      //Kombination aus Timelimits und Sperrzeit, wird errechnet
      $scope.data.isWebFilterActive = false;
      $scope.data.isPrgFilterActive = false;
      $scope.data.isEmailReportActive = false;
      $scope.data.isSecurityActive  = false; //berrechnet aus den Security settings
      //$scope.data.blockTimesPC = '';
      $scope.todayTotal = $translate.instant('USAGE.TODAYTOTAL') + ': ..:..';
      $scope.data.hasUnlock = false; //Freigabewünsche
      $scope.data.turnOffOption = 0;
      $scope.data.turnOffUntilDateSelection;
      $scope.data.turnOffUntilDate;
      $scope.extensionInfo = $translate.instant('CC_DASH.CURRENT_EXTENSION') + ': .. Min.';
      $scope.pauseInfo = $translate.instant('CC_DASH.PAUSE_RULES') + ': ... ';
      $scope.secureBootWarning = false;
      $scope.showUnlockInfo = false;
      $scope.syncDateInPast = '';
      $scope.trialNotice = '';

      //Im Win32 client beides auf false!
      $scope.isOnline = true;
      $scope.isOffline = false;
      $scope.lastSync = '   ' + $translate.instant('MISC.RESOLVING');
      $scope.logbookTeaser = {};

      $scope.extensionDuration = "00:15"; //this is the modal default value

      var modalInstance;

      $log.info('WE ARE CC_DASHBOARD, jumpId is', scgSharedFactory.jumpId);
      $log.info('CC-DASH, activeLicence:' ,AuthFactory.activeLicence);

      if (scgSharedFactory.jumpId && scgSharedFactory.jumpId == 7) {
        scgSharedFactory.jumpId = 0;    //do only once
        $state.go('cc-usage.unblock');
      }

      //Hotfix security issue: Are we in report view? (then immediately logout)
       if ($cookies.get('role') == 'report')
       {
           $state.go('login-logoff-success');
       }

      $scope.uriScreenshot = '';
      // scgSharedFactory.getLastSync().then(function(funcresult) {
      //   $scope.lastSync = scgSharedFactory.formatDateTime(funcresult);
      // });


      var watcher1 = $scope.$watch('data.turnOffOption', function(nV, oV) {
        if (nV && nV != oV) {

          if (nV == 1) { //turn off
            $scope.turnOff();
          }else if (nV == 2) { //turn until reboot
            $scope.turnOff();

          }else if (nV == 3) { //turn off until date
            $scope.showTurnOffUntilModal();
          }

        }
      });

      watchers.push(watcher1);

      $scope.setTurnOffValue = function(val) {
        $scope.data.turnOffOption = val;
      };

      var watcher2 = $scope.$watch('data.turnOffUntilDateSelection', function(nV, oV) {
        if (nV && nV != oV) {
          $log.info('turn off until date', nV);
          $scope.turnOffUntilDate = moment(nV).format('YYYY-MM-DD hh:mm');
          modalInstance.dismiss('cancel');
          $scope.turnOff();
        }
      });

      watchers.push(watcher2);

        $scope.$on('$destroy', function() {
            _.each(watchers, function(watcher){
                watcher();
            });
        });

      $scope.showUsages = function() {
        $log.info('SHOW USAGES!');
        $state.go('cc-usage');
      };

      $scope.showScreenshot = function() {

        modalInstance = $modal.open({
          animation: false,
          templateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-screenshot.html',
          windowTemplateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-window.html',
          scope: $scope
        });

      };

      $scope.showExtensionModal = function() {

        modalInstance = $modal.open({
          animation: false,
          templateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-extension.html',
          windowTemplateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-window-small.html',
          scope: $scope
        });

      };

      $scope.instantSync = function() {
        if ($scope.isPCOnline) {
          //go for sync
          sseFactory.sync()
              .then(function success(response) {
                if (response.data.success == true) {
                  $log.info('SYNC SUCCESS');
                  $log.info('NEEDSSYNC=FALSE 7');
                  scgSharedFactory.needsSync = false;
                  $scope.syncRunning = false;
                }
              });
        } else {
          //PC is offline, display notice
          scgSharedFactory.needsSync = true;
        }
      };

      $scope.showTurnOffUntilModal = function() {

        modalInstance = $modal.open({
          animation: false,
          templateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-turnoffuntil.html',
          windowTemplateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-window-small.html',
          scope: $scope
        });

      };

      $scope.turnOff = function() {
        $log.info('TURN OFF!');

        modalFactory.infoModal.open({
            modal_settings: {
              title: $translate.instant('MSG.QUESTION'),
              message: $translate.instant('MSG.SURE'),
              butOkLabel: $translate.instant('BUTTON.YES'),
              butCancelLabel: $translate.instant('BUTTON.NO'),
              iconNo: 2,
              refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
            },
            callbacks: {
              ok: function() {

                //TODO
                //Send turnoff data to api
                $log.info('TURNOFF OPTION:' + $scope.data.turnOffOption);

                //if ($scope.data.turnOffOption ==1) $scope.data.onetimelocklevel=1;
                /* 8a: var postData = {
                   turnOff: $scope.data.turnOffOption,
                   turnOffUntil: $scope.data.turnOffUntil
                 }; */

                $log.info('TURN OFF INCOMING turnOffUntil:', $scope.data.turnOffUntilDateSelection);

                var tmplevel = 0;
                if ($scope.data.turnOffOption == 1) {
                  tmplevel = -1;  //Always off
                  scgSharedFactory.logMeCC('Web Portal: ' + $translate.instant('CC_HEADLINE.CHILDCONTROL') + ' ' + $translate.instant('BUTTON.TURNOFF_ALWAYS'));
                }
                if ($scope.data.turnOffOption == 2) {
                  tmplevel = 1;  //restart off
                  scgSharedFactory.logMeCC('Web Portal: ' + $translate.instant('CC_HEADLINE.CHILDCONTROL') + ' ' + $translate.instant('BUTTON.TURNOFF_RESTART'));
                }
                if ($scope.data.turnOffOption == 3) {
                  tmplevel = 2;  //until date
                  scgSharedFactory.logMeCC('Web Portal: ' + $translate.instant('CC_HEADLINE.CHILDCONTROL') + ' ' + $translate.instant('BUTTON.TURNOFF_DATE'));
                }

                var postData = {'onetimelocklevel': tmplevel};
                if (tmplevel == 2) {
                  var unlockdate = moment($scope.data.turnOffUntilDateSelection).format(apiDateFormat);
                  postData = {
                    'onetimelocklevel': tmplevel,
                    'unlockdate': unlockdate
                  };
                }

                $scope.data.onetimelocklevel = tmplevel;
                $scope.data.isSomeActive = false;

                ccDashboardFactory.setOneTimeLockLevel(postData)
                    .then(function success() {
                      $log.info('TURN OFF SUCCESS! is online', $scope.isPCOnline);
                      //If PC is online sync directly, w/o message
                      $scope.instantSync();
                    });

              },
            },
            cancel: function() {
              $log.info('turnoff canceled!');
            }
          });
      };

      $scope.showUnlock = function() {
        $state.go('cc-usage.unblock');
      };

      $scope.turnOn = function() {
        var postData = {'onetimelocklevel': 0};
        ccDashboardFactory.setOneTimeLockLevel(postData)
            .then(function success() {
              $log.info('TURN ON SUCCESS!');
              $scope.data.onetimelocklevel = 0;
              $scope.data.isSomeActive = true;
              $scope.instantSync();
            });
      };

      $scope.isSomethingActive = function() {
        //IsSomeActive gets false if OneTimeLockLevel is -1 (always off) ,1 (off restart) or 2 (off datetime)
        $scope.data.isSomeActive = true; //default

        if ($scope.data.onetimelocklevel == -1) {
          $scope.data.isSomeActive = false;
        }
        if ($scope.data.onetimelocklevel == 1) {
          $scope.data.isSomeActive = false;
        }
        if ($scope.data.onetimelocklevel == 2) {
          $scope.data.isSomeActive = false;
        }
      };

      //Loading wrapper for each dashboard tile
      $scope.data.isActiveLoading = true;
      $scope.data.isTimeActiveLoading = true;
      $scope.data.isPrgFilterLoading = true;      //done by 8a
      $scope.data.isWebFilterLoading = true;
      $scope.data.isEmailReportLoading = true;
      $scope.data.isSecurityLoading = true;
      $scope.data.isUnlockLoading = true;

      ccDashboardFactory.getCCSettings()
          .then(function success(response) {

            //Ok, dashboard tile content has been loaded
            $scope.data.isActiveLoading = false;
            $scope.data.isPrgFilterLoading = false;
            $scope.data.isTimeActiveLoading = false;
            $scope.data.isWebFilterLoading = false;
            $scope.data.isEmailReportLoading = false;
            $scope.data.isSecurityLoading = false;
            $scope.data.isUnlockLoading = false;

            $scope.data = response.data;
            $log.info('getCCSettings done with scope.data=' , $scope.data);
            $scope.data.isTimeActive = false;       //Das feld hier wird berrechnet aus den beiden Limits!
            $scope.data.isSomeActive = true; //Main switch (green check)

            //Calculating the isTimeActive flag (for dashboard timelimits on/off)
            if ($scope.data.isTimeLimitActive) {
              $scope.data.isTimeActive = true;
            }
            if ($scope.data.isWeekDayLimitActive) {
              $scope.data.isTimeActive = true;
            }
            if ($scope.data.blockTimesPC.indexOf('1') != -1) {
              $scope.data.isTimeActive = true;
            }
            // if ($scope.data.blockTimesPC != scgSharedFactory.blockOff()) $scope.data.isTimeActive = true;

            //Calculating security settings
            if ($scope.data.isDisableCMD) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableRegedit) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableStartSearch) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableControlPanel) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableUserAccounts) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableStartRun) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableStartSearch) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableTaskmanager) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableTimeDate) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableLockStation) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.isDisableIcon) {
              $scope.data.isSecurityActive = true;
            }
            if ($scope.data.DrvLock.indexOf('1') != -1) {
              $scope.data.isSecurityActive = true;
            }

            $scope.isSomethingActive();
            $scope.contentLoading = false;
            Metronic.unblockUI();

            scgGlobalSpinnerFactory.unblockUI();
          });

      //Secure Boot warning?
      ccDashboardFactory.getSecureBoot()
          .then(function success(response) {
            if (response.data.secureboot === 1) {
              $scope.secureBootWarning = true;
              $log.info('secureboot:', response.data.secureboot);
            }
          });

      ccUsageFactory.getUnlockReqs()
          .then(function success(response) {
            $log.info('response.data=', response.data);
            $log.info('response.data length=', response.data.length);
            if (response.data && response.data.length > 0) {
              $scope.showUnlockInfo = true;
            }
          });


      // ***********************************************************************************************************
      //                                     LIVE FUNCTIONS (SSE)
      // ***********************************************************************************************************
      scgSharedFactory.checkHasChangedTimer();

      scgSharedFactory.checkPCOnline()
        .then(function success(result) {
          $scope.isPCOnline = result;
        });

      $scope.delExtension = function() {
        $log.info('THIS IS DEL EXTENSION');
        scgGlobalSpinnerFactory.blockUI();
        ccDashboardFactory.setExtensionTime(0)
            .then(function success() {
              $log.info('YES, WE DELETED IT');
              $scope.data.extension = 0;
              scgGlobalSpinnerFactory.unblockUI();
              scgSharedFactory.logMeCC('Web Portal: ' + $translate.instant('MSG.EXTENSION_DELETED'));
              $scope.myExtensionDeleteModal($translate.instant('MSG.EXTENSION_DELETED'));
              $scope.extensionInfo = $translate.instant('CC_DASH.CURRENT_EXTENSION') + ': '  + $translate.instant('ALL.NONE');
            });
      };

      $scope.myExtensionDeleteModal = function(msg) {
        scgGlobalSpinnerFactory.unblockUI();
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: msg,
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: false
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
            }
          }
        });
      };

      //---------------------------------------------------------------------------------------------------------------
      //infomdal for re-use in this scope
      $scope.myInfoModal = function(msg) {
        scgGlobalSpinnerFactory.unblockUI();
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: msg,
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
            }
          }
        });
      };
      //---------------------------------------------------------------------------------------------------------------
      $scope.doSSEExt = function(extensionTime) {
        modalInstance.dismiss('cancel');

        scgGlobalSpinnerFactory.blockUI();
        var extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');

        sseFactory.extensionTime(extTimeMinutes)
            .then(function success(response) {
              if (response.data.success == true) {
                if (response.data.id !== null && response.data.id == 'NO_SESSION') {
                  $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                } else {
                  $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                }
              } else {
                $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
              }
            });
      };

      //---------------------------------------------------------------------------------------------------------------
      $scope.doSSEMsg = function(mymsg) {
        scgGlobalSpinnerFactory.blockUI();
        sseFactory.msg(mymsg)
            .then(function success(response) {
              if (response.data.success == true) {
                if (response.data.id !== null && response.data.id == 'NO_SESSION') {
                  $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                } else {
                  $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                }
              } else {
                $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
              }
            });
      };

      $scope.fixMessageString = function() {
        $log.info('changed');
        $scope.message = $scope.message.replace('ß','ss');
        $scope.message = $scope.message.replace('ö','oe');
        $scope.message = $scope.message.replace('ü','ue');
        $scope.message = $scope.message.replace('ä','ae');
        $scope.message = $scope.message.replace('Ö','Oe');
        $scope.message = $scope.message.replace('Ü','Ue');
        $scope.message = $scope.message.replace('Ä','Ae');
        $scope.message = $scope.message.replace(/[^a-zA-Z0-9\s\!]+/,'');
      };
      //---------------------------------------------------------------------------------------------------------------
      $scope.doSSESync = function() {
        scgGlobalSpinnerFactory.blockUI();
        sseFactory.sync()
            .then(function success(response) {
              if (response.data.success == true) {
                $log.info('SYNC SUCCESS');
                $scope.myInfoModal($translate.instant('MSG.SYNCSUCCESS'));
              } else {
                $log.info('SYNC FAILED');
                $scope.myInfoModal($translate.instant('MSG.SYNCFAILURE'));
              }
            });
      };

      //---------------------------------------------------------------------------------------------------------------
      $scope.doSSEShutdown = function() {
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.QUESTION'),
            message: $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 2,
            refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('yes, ok was click, he/she is sure');
              scgGlobalSpinnerFactory.blockUI();
              sseFactory.shutdown()
                  .then(function success(response) {
                    if (response.data.success == true) {
                      $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                    } else {
                      $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
                    }
                  });
            },
          },
          cancel: function() {
            $log.info('canceled!');
          }
        });
      };

      //---------------------------------------------------------------------------------------------------------------
      $scope.doSSEScreenshot = function() {
        scgGlobalSpinnerFactory.blockUI();
        $scope.pollDone = false;
        sseFactory.screenshot()
            .then(function success(response) {
              $log.info('screenshot response:' , response.data);
              if (response.data.success == true) {
                if (response.data.id  !== null && response.data.id == 'NO_SESSION') {
                  $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                  return;
                }
                $scope.pollimg_interval = $interval(function() {
                  if ($scope.pollDone) {
                    //8a: ADD THE FAILURE
                    $interval.cancel($scope.pollimg_interval);
                    $log.info('We are done, show pic here');
                    scgGlobalSpinnerFactory.unblockUI();
                    var rnd = Math.floor(Math.random() * (999999 - 100001)) + 100001; //login controller because of NO AUTH for uriScreenshot, rnd against caching
                    $scope.uriScreenshot = "http://portal.salfeld.net/api/login/getscreen/" + rnd + "/" + response.data.id;
                    $log.info('uriScreenshot:' + $scope.uriScreenshot); //used by <img src> in modal
                    $scope.showScreenshot();
                  } else {
                    $log.info('interval');
                    $scope.checkScreenshot(response.data.id);
                  }
                }, 1000, 20);

              } else {
                $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
              }
            });
      };

      $scope.checkScreenshot = function(filename) {
        sseFactory.checkScreenshot(filename)
            .then(function success(response) {
          $log.info('image exists', response);
          $scope.pollDone = true;
        }, function error(e1) {
          $log.info('head check error', e1);
        });
      };
      //---------------------------------------------------------------------------------------------------------------

      scgSharedFactory.getLastSync().then(function success(lastSync) {
        if (lastSync && (moment(new Date()).format('YYYY-MM-DD') != lastSync.substring(0,10))) {
          $scope.syncDateInPast = 'As of ' + moment(new Date(lastSync)).format('DD.MMM YYYY') + ': '; //Default
          if ($translate.use() == 'de_DE') {
            $scope.syncDateInPast = 'Stand ' + moment(new Date(lastSync)).format('DD.MM.YY') + ': ';
          }
        }
        $scope.lastSync = scgSharedFactory.formatDateTime(lastSync);
      });

      $scope.underConstruction = function() {
        modalFactory.okModal(1, $translate.instant('MSG.TODO'));
      };

      Metronic.unblockUI();

      ccDashboardFactory.getTodayTotal(moment(new Date()).format('YYYY-MM-DD'))
          .then(function success(response) {
            $scope.todayTotal = $translate.instant('USAGE.TODAYTOTAL') + ': ' + response.data;
          }
      );

      ccDashboardFactory.getCurrentExtension()
          .then(function success(response) {
            $log.info('CUREXT', response.data);
            var extTime = $translate.instant('ALL.NONE');
            if (response.data.extension) {
              extTime = response.data.extension + ' Min.';
            }
            $scope.extensionInfo = $translate.instant('CC_DASH.CURRENT_EXTENSION') + ': '  + extTime; // + ' ' + $translate.instant('HEADER.EXTENSIONMINUTES');
            $scope.extensionInfoNote = $translate.instant('INFO.EXTENSION').replace('%%', response.data.extension);
          }
      );

      scgLicenceFactory.getSerialInfo()
          .then(function success(response) {
            $log.info('LICENCETEST Success', response);
            if (response.data.serial === '' && response.data.expireDays <= 30) {
              $scope.trialNotice = $translate.instant('MISC.TRIALVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX',response.data.expireDays);
              //TODO DIERK8A
              /*if (response.data.expireDays<0) {
                $log.info("TRIAL EXPIRED");
                $state.go('licence');
              }*/
            }
          },
          function error() {
            $log.info('LICENCETEST ERROR');
          });

      //New extension approach without using SSE
      $scope.doExtension = function(extensionTime) {
        modalInstance.dismiss('cancel');
        scgGlobalSpinnerFactory.blockUI();
        $log.info('DoEXTENSION with extensionTime=', extensionTime);
        var extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');
        ccDashboardFactory.setExtensionTime(extTimeMinutes)
            .then(function success() {
              $scope.myInfoModal($translate.instant('MSG.EXTENSION_SET'));
              scgSharedFactory.logMeCC('Web Portal: ' + $translate.instant('MSG.EXTENSION_SET') + ' (' + extTimeMinutes + ' Min)');
            });
      };

      $scope.resetTimes = function() {
        $log.info('reset times goes here');
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.QUESTION'),
            message: $translate.instant('MSG.SUREDELETEUSER') + '<br><br>' + $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 2,
            refreshStateAfterClose: true //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('yes, ok was click, he/she is sure');
              scgGlobalSpinnerFactory.unblockUI();
              ccUsageFactory.deleteUsage()
                  .then(function success(response) {
                    $log.info('HAS DATA 200, response.data=' + response.data);
                    if (response.data != null) {
                      sseFactory.isOnline()
                          .then(function success() {
                            $log.info('YES WE ARE ALSO ONLINE');
                            sseFactory.sync()
                                .then(function success() {
                                  $log.info('YES DO SYNC ALSO SUCCEEDED');
                                });
                          });
                      Metronic.unblockUI();
                      modalFactory.okModal(1, $translate.instant('MSG.DELETECCDONE'));
                    }
                  }, function error() {
                    Metronic.unblockUI();
                    modalFactory.okModal(1, $translate.instant('MSG.ERROR')  + ' ' + $translate.instant('MSG.ERRORHINT2'));
                  });
              $log.info('Ok, all done');
            },
          },
          cancel: function() {
            $log.info('canceled!');
          }
        });
      };






      $scope.showWizard = function() {

        //TODO check if still relevant
        //wizard-modal-window.html was deleted in
        //https://github.com/salfeldgmbh/ChicoOne/commit/dc51c763316a429e884f155d3548bc55ddc0b8b9

        $log.info('Show wizard');

        /*var modaloptions = {
          onClose: exportSettings   //ADJUST
        };*/

        $modal.open({
          animation: false,
          template: '<scg-wizard-modal></scg-wizard-modal>', //see folder directive!!!
          windowTemplateUrl: 'templates/childcontrol/cc_dashboard/templates/wizard-modal-window.html',
          controller: 'SCG-CC-Wizard-Controller',
          resolve: {
            modalCallbacks: function() {
              //TODO 8A
              //dismiss('cancel');
              //return modaloptions;   //ADJUST
            }
          }
        });

      };


      //Logbook Teaser
        scgSharedFactory.getLogbookTeaserV2().then(function(funcresult) {
            $scope.logbookTeaser = funcresult.data;
            $log.info('logbook teaser data=' , $scope.logbookTeaser );
      });


      scgSharedFactory.getServerTimeDiff().then(function(funcresult) {
        $scope.serverTimeDiff = funcresult;
      });

      //watcher for deviceType change (CBE!)
      $scope.$watch(function() {
          return AuthFactory.activeDevice + AuthFactory.activeUser;
      }, function(newVal) {
          if( $scope.deviceType != AuthFactory.activeDevice.deviceType ){
              $scope.deviceType = AuthFactory.activeDevice.deviceType;
          }
      });



      window.scrollTo(0,0);
      menuFactory.showSidebar();

    });
