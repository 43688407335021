'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Until-Controller', function(
    $scope,
    $log,
    $state,
    $http,
    API,
    apiFunctions,
    moment,
    scgGlobalSpinnerFactory,
    $modalInstance,
    modal_settings)
{

    $scope.modal_settings = modal_settings; //transfer settings to scope for use in template


    $scope.modal_settings.untilDays = 0;
    $scope.modal_settings.untilHours = 1;
    $scope.modal_settings.untilMinutes = 15;

    $scope.showUntilAction = function (defaultRadio) {
        $log.info('show UntilAction');
        var modalUntilActionInstance = $modal.open({
            animation: false,
            templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-until.html',
            windowTemplateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-window-small.html',
            scope: modalUntilScope
        });

        modalUntilActionInstance.rendered.then(function(){
            if (defaultRadio==1) {modalUntilScope.untilAction=1;}
            if (defaultRadio==2) {modalUntilScope.untilAction=2;}
        });
    }


    $scope.cancel = function() {
        $log.info('modal CANCEL inside modal');
        $modalInstance.dismiss('cancel');
    }

    $scope.setUntilAction = function() {

        $log.info('modal-until setUntilAction');
        $log.info('modal-until ok click, selected defaultRadio=' , $scope.modal_settings.defaultRadio);
        $log.info('modal-until ok click, selected contexto=' , $scope.modal_settings.context);


        var untilDateTime = moment().add($scope.modal_settings.untilDays, 'days').add($scope.modal_settings.untilHours, 'hours').add($scope.modal_settings.untilMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        var untilAction = $scope.modal_settings.defaultRadio;

        var obj = {"untilAction" : untilAction, "untilDateTime" : untilDateTime};
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings',
            data: obj
        };

        //Overwrite if we are context CC
        if ($scope.modal_settings.context=="CC") {
            if (untilAction==1) {
                req = {
                    method: 'POST',
                    url: apiFunctions.get_cc_base_url() + 'ccsettings',
                    data: {"onetimelocklevel": 2, "unlockdate": untilDateTime}
            }}
            if (untilAction==2) {
                req = {
                    method: 'POST',
                    url: apiFunctions.get_cc_base_url() + 'ccsettings',
                    data: {"onetimelocklevel": 3, "lockdate": untilDateTime}
            }}
        }

        $log.info('modal-until obj=', obj);
        //scgGlobalSpinnerFactory.blockUI();
        $http(req).then(function success() {
            $log.info("modal-until HTTP SUCCESS");
            scgGlobalSpinnerFactory.unblockUI();
        }).catch(function(error){
            $log.info("modal-until HTTP ERROR:" , error);
            scgGlobalSpinnerFactory.unblockUI();
        });
    }
});

