'use strict';
angular.module('ChicoApp.Shared').controller('CC-Modal-Edit-Controller', function ($scope,
                                                                                   $modalInstance,
                                                                                   $translate,
                                                                                   modal_settings,
                                                                                   ccListsFactory,
                                                                                   cbListsFactory,
                                                                                   scgSettingsFactory,
                                                                                   scgValidationFactory,
                                                                                   scgSharedFactory,
                                                                                   modalFactory,
                                                                                   moment,
                                                                                   AuthFactory,
                                                                                   scgGotItFactory,
                                                                                   scgGlobalSpinnerFactory,
                                                                                   _,
                                                                                   $log) {

    $scope.scgGotItFactory = scgGotItFactory;

    $scope.currentContext = AuthFactory.activeContext; //will be cc or cb as String
    $scope.currentListFactory = null;
    $scope.taglistEnabled = true;
    $scope.hintHide = true;

    $scope.deviceType = -1;
    $scope.loading = {};
    $scope.loading.taglist = false;
    $scope.loading.groupnames = false;
    $scope.loading.timeinputs = false;
    $scope.loading.savebutton = false;

    if ($scope.currentContext == 'cc') {
        $scope.currentListFactory = ccListsFactory;
    } else if ($scope.currentContext == 'cb') {
        $scope.currentListFactory = cbListsFactory;
    }

    $scope.modal_settings = modal_settings;

    $scope.data = {};
    $scope.data_list = [];
    $scope.taginput_list = [];

    var kind_types = $scope.currentListFactory.listKinds; //just for convenience put it into local variable

    //MUST HAVE DATA
    $scope.kind = {value: 0, name: ''}; //stores the list kind we are currently in, will be used to save

    var callsToDo = 0;

    var groupNamePrefix = $translate.instant('MODAL_EDIT_RECORD.GROUP_NAME_PREFIX_DROPDOWN');

    var mixedKindsMapping = [
        {
            fromKind: [1], //if started with kind 1
            toKind: 7, //move entries to kind 7
            onRegEx: '.(exe|bat)\"?$', //if the result to the test versus this regex
            onRegExResult: false //is false
        }, {
            fromKind: [2],
            toKind: 8,
            onRegEx: '.(exe|bat)\"?$',
            onRegExResult: false
        }, {
            fromKind: [3],
            toKind: 6,
            onRegEx: '.(exe|bat)\"?$',
            onRegExResult: false
        }, {
            fromKind: [26],
            toKind: 25,
            onRegEx: '.(exe|bat)\"?$',
            onRegExResult: false
        }, {
            fromKind: [5, 6],
            toKind: 3,
            onRegEx: '.(exe|bat)\"?$',
            onRegExResult: true
        }, {
            fromKind: [7],
            toKind: 1,
            onRegEx: '.(exe|bat)\"?$',
            onRegExResult: true
        }, {
            fromKind: [8],
            toKind: 2,
            onRegEx: '.(exe|bat)\"?$',
            onRegExResult: true
        }, {
            fromKind: [25],
            toKind: 26,
            onRegEx: '.(exe|bat)\"?$',
            onRegExResult: true
        }
    ];

    //when restoring original data per key, those ones will be ignored
    var bulk_ignore_keys = [
        'kind',
        'tagID',
        'tagDetails',
        'isTimeLimitActive',
        'blockedTimesCB',
        'isTimeLimitActive',
        'isWeekdayLimitActive',
        'timeLimitDay',
        'timeLimitFriday',
        'timeLimitMonday',
        'timeLimitMonth',
        'timeLimitSaturday',
        'timeLimitSunday',
        'timeLimitThursday',
        'timeLimitTuesday',
        'timeLimitWednesday',
        'timeLimitWeek',
        'optBonusType',
        'blockTimes'];

    //UI Flags
    $scope.uiflags = {};
    $scope.uiflags.but_save_disabled = true;
    $scope.uiflags.isOnlyTagList = true;
    $scope.uiflags.isTimeLimitContent = false;
    $scope.uiflags.isCcAllowedContent = false;
    $scope.uiflags.isCbAllowedContent = false;
    $scope.uiflags.showCB3AllowedApps = true;

    //add permament dropdown records
    $scope.kindDropdownContent = [];
    $scope.kindColorClass = "green-haze"; //make scg-green

    $scope.kindColorMapping = {
        1: "scg-backgreen",
        2: "scg-backyellow",
        3: "scg-backred",
        4: "scg-backgreen",
        5: "scg-backred",
        6: "scg-backred",
        7: "scg-backgreen",
        8: "scg-backgreen",
        9: "scg-backgreen",
        10: "scg-backyellow",
        11: "scg-backyellow",
        12: "scg-backyellow",
        13: "scg-backyellow",
        14: "scg-backyellow",
        15: "scg-backyellow"
    };

    $scope.kindDefaultColor = "scg-backdefault";

    $scope.setDropdownKind = function (selectedObj) {

        if (selectedObj.value) {
            $scope.kind = {name: selectedObj.name, value: selectedObj.value};
        }

        $scope.kindColorClass = $scope.kindColorMapping[selectedObj.value] || $scope.kindDefaultColor;

    }


    $scope.onTagAdded = function (tagObj) {

        var tagName = tagObj.text;
        //Dierk: Replace tag inputs according kind.value here:
        $log.info('tagname=' + tagName + 'kind=' + $scope.kind.value);

        /*if ($scope.kind.value == 1) {

        }*/

        tagName = tagName.replace('http://', '');
        tagName = tagName.replace('https://', '');
        tagName = tagName.replace('www.', '');

        if ($scope.kind.value == 7 || $scope.kind.value == 8) {
            tagName = scgSharedFactory.getTLD(tagName);
        }
        $scope.taginput_list[$scope.taginput_list.length - 1].text = tagName;
        return true;
    };

    $scope.onTagAdding = function (tagObj) {

        var tagName = tagObj.text;
        if (!tagName) {
            return false;
        }


        $log.info('tagname=' + tagName + 'kind=' + $scope.kind.value);

        if ($scope.currentContext == 'cc' && modal_settings.context == 'prg') {
            if (tagName.indexOf(':') != -1 || tagName.indexOf('/') != -1 || tagName.indexOf('\\') != -1) {
                $log.info('INVALID CHAR FOR PROGRAM IN CC CONTEXT');
                return false;
            }
            if (tagName.match('\.exe|\.bin') == null) {
                return false;
            }
        } else if ($scope.currentContext == 'cc' && modal_settings.context == 'net') {
            if (tagName.match('\.exe|\.bin') != null) {
                return false;
            }
        }

        return true;
    };

    //add context specific records (internet or programs)
    if (modal_settings.context == 'net') {
        $scope.taglist_hint = $translate.instant('MODAL_EDIT_RECORD.HINT_TAGLIST_NET');
        if ($scope.currentContext == 'cb' && modal_settings.context == 'prg') {
            $scope.taglist_hint = ' ';
        }

        $scope.kindDropdownContent.push({
            value: parseInt(kind_types.list_kind_allowed_kw),
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_allowed_kw)
        });
        $scope.kindDropdownContent.push({
            value: parseInt(kind_types.list_kind_blocked_url),
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_blocked_url)
        });
        $scope.kindDropdownContent.push({
            value: parseInt(kind_types.list_kind_timelimited),
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_timelimited)
        });

    } else if (modal_settings.context == 'prg') {
        $scope.taglist_hint = $translate.instant('MODAL_EDIT_RECORD.HINT_TAGLIST_PROG');
        //Dierk: This was old apporach, now also CC case prg should not be editable
        //if ($scope.currentContext == 'cb' && modal_settings.context == 'prg') {
        if (modal_settings.context == 'prg') {
            $scope.taglist_hint = ' ';
        }

        $scope.kindDropdownContent.push({
            value: parseInt(kind_types.list_kind_allowed_prg),
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_allowed_prg)
        });
        $scope.kindDropdownContent.push({
            value: parseInt(kind_types.list_kind_blocked_prg),
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_blocked_prg)
        });
        $scope.kindDropdownContent.push({
            value: parseInt(kind_types.list_kind_timelimited_prg),
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_timelimited_prg)
        });
        if ($scope.currentContext != 'cb') {
            $scope.kindDropdownContent.push({
                value: 1098,
                name: '',
                isDisabled: true
            });
            $scope.kindDropdownContent.push({
                value: parseInt(kind_types.list_kind_group_internet),
                name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_group_internet)
            });

            $scope.kindDropdownContent.push({
                value: parseInt(kind_types.list_kind_group_system),
                name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_group_system)
            });

            $scope.kindDropdownContent.push({
                value: parseInt(kind_types.list_kind_group_winapps),
                name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_group_winapps)
            });

            $scope.kindDropdownContent.push({
                value: 1099,
                name: '',
                isDisabled: true
            });

        }
    }

    $scope.errors = []; //when an error occurs, place it here, will prevent from saving until accepted

    $scope.$watch('data', function (n) {
        if (n.isWeekdayLimitActive == true) {
            var compare = [n.timeLimitMonday, n.timeLimitTuesday, n.timeLimitWednesday, n.timeLimitThursday, n.timeLimitFriday, n.timeLimitSaturday, n.timeLimitSunday];
            var largestweekday = '02:30';
            _.map(compare, function (value) {
                if (moment.duration(value) > moment.duration(largestweekday)) {
                    largestweekday = value;
                }
            });
            moment.duration($scope.data.timeLimitDay) <= moment.duration(largestweekday) ? $scope.data.timeLimitDay = largestweekday : null;
        }
    }, true);

    $scope.cancel = function () {

        $modalInstance.dismiss('cancel');
        scgGlobalSpinnerFactory.unblockUI();
    };

    $scope.selectHintSetGotIt = function () {
        scgGotItFactory.gotItValues.editModalSelectHint = true;
    };

    var saveSuccess = function () {

        //$modalInstance.close(return_val);
        $modalInstance.close();
        scgGlobalSpinnerFactory.unblockUI();
    };

    var setKindById = function (intKindId) {
        $scope.kind = _.findWhere($scope.kindDropdownContent, {value: intKindId});
    };

    /*
    * ADD
    */
    var initAddMode = function () {

        // setTimeout(function() {
        // TODO: CHECK WHY THIS TIMEOUT COULD BE NEEDED?!?!?

        //build up default values
        $scope.data = {
            'isTimeLimitActive': false,
            'timeLimitDay': '02:30',
            'timeLimitWeek': '20:00',
            'timeLimitMonth': '80:00',
            'isWeekdayLimitActive': false,
            'timeLimitMonday': '01:30',
            'timeLimitTuesday': '01:30',
            'timeLimitWednesday': '01:30',
            'timeLimitThursday': '01:30',
            'timeLimitFriday': '02:00',
            'timeLimitSaturday': '02:00',
            'timeLimitSunday': '02:00',
            'tagID': 0
        };

        var defaultTimingString = '00000000000000000000000000000000000000000000000000' +
            '00000000000000000000000000000000000000000000000000' +
            '00000000000000000000000000000000000000000000000000' +
            '000000000000000000';
        //add context specific timing key
        if ($scope.currentContext == 'cc') {
            $scope.data.blockTimes = defaultTimingString;
        } else if ($scope.currentContext == 'cb') {
            $scope.data.blockedTimesCB = defaultTimingString;
        }

        // }, 250);

        if (modal_settings.newRecord) {

            //set kind from modal parameters
            if (modal_settings.newRecord.kind) {
                setKindById(modal_settings.newRecord.kind);
                $scope.data.kind = modal_settings.newRecord.kind;
            }

            //insert new tags from modal parameters
            if (modal_settings.newRecord.texts) {
                _.each(modal_settings.newRecord.texts, function (tag) {
                    $scope.taginput_list.push({text: tag});
                });
            }

        } else {
            $log.info('You have to pass a valid modal_settings.newRecord Object');
            return;
        }

    };

    var saveNewRecords = function (tag_list_to_save, cb) {

        var postData = []; //array for the new records we will later post

        //add each tag to the array
        _.each(tag_list_to_save, function (tag_obj) {

            //add basic data
            var record = {};
            record.kind = $scope.kind.value;
            record.entry = tag_obj.text;

            //if this is a program, add some more default values
            if ($scope.currentContext == 'cc' && (modal_settings.context == 'prg' || modal_settings.context == 'net')) {
                record.blockTimes = '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000';
                record.description = '';
                record.installationDate = '2010-01-01';
                record.isDeleted = false;
                record.isModified = true;
                record.isTimeLimitActive = false;
                record.isWeekdayLimitActive = false;
                record.tagDetails = '';
                record.tagDetailsBonusTime = '00:30';
                record.tagDetailsBonusUsage = '00:15';
                record.tagID = $scope.data.tagID || 0;
                record.timeLimitDay = '02:30';
                record.timeLimitFriday = '02:30';
                record.timeLimitMonday = '02:30';
                record.timeLimitMonth = '28:00';
                record.timeLimitSaturday = '02:30';
                record.timeLimitSunday = '02:30';
                record.timeLimitThursday = '02:30';
                record.timeLimitTuesday = '02:30';
                record.timeLimitWednesday = '02:30';
                record.timeLimitWeek = '07:00';

            }

            //add duration infos if this is a timelimit entry

            if ($scope.kind.value == kind_types.list_kind_timelimited ||
                $scope.kind.value == kind_types.list_kind_timelimited_prg ||
                $scope.kind.value == kind_types.list_kind_custom_group_1 ||
                $scope.kind.value == kind_types.list_kind_custom_group_2 ||
                $scope.kind.value == kind_types.list_kind_custom_group_3 ||
                $scope.kind.value == kind_types.list_kind_custom_group_4 ||
                $scope.kind.value == kind_types.list_kind_custom_group_5
            ) {

                record.isTimeLimitActive = $scope.data.isTimeLimitActive;
                record.isWeekdayLimitActive = $scope.data.isWeekdayLimitActive;

                //add context specific timing key
                if ($scope.currentContext == 'cc') {
                    record.blockTimes = $scope.data.blockTimes;
                } else if ($scope.currentContext == 'cb') {
                    record.blockedTimesCB = $scope.data.blockedTimesCB;
                }

                //add duration detail infos (maybe)
                if ($scope.data.isTimeLimitActive === true) {
                    record.timeLimitDay = $scope.data.timeLimitDay;
                    record.timeLimitWeek = $scope.data.timeLimitWeek;
                    record.timeLimitMonth = $scope.data.timeLimitMonth;
                }

                if ($scope.data.isWeekdayLimitActive === true) {
                    record.timeLimitDay = $scope.data.timeLimitDay;
                    record.timeLimitWeek = $scope.data.timeLimitWeek;
                    record.timeLimitMonth = $scope.data.timeLimitMonth;
                    record.timeLimitMonday = $scope.data.timeLimitMonday;
                    record.timeLimitTuesday = $scope.data.timeLimitTuesday;
                    record.timeLimitWednesday = $scope.data.timeLimitWednesday;
                    record.timeLimitThursday = $scope.data.timeLimitThursday;
                    record.timeLimitFriday = $scope.data.timeLimitFriday;
                    record.timeLimitSaturday = $scope.data.timeLimitSaturday;
                    record.timeLimitSunday = $scope.data.timeLimitSunday;

                }

            }

            if ($scope.data.tagID == 2) {
                record.tagDetails = $scope.data.tagDetailsBonusUsage + '|' + $scope.data.tagDetailsBonusTime;
            } else if ($scope.data.tagID != 2) {
                record.tagDetails = '';
            }

            //check if we have to re-map some kinds
            var kindMapping = [];
            _.each(mixedKindsMapping, function (mapping) {
                if (_.contains(mapping.fromKind, $scope.kind.value)) {
                    kindMapping.push(mapping);
                }
            });

            if (kindMapping.length == 1) {
                var mapping = kindMapping[0];

                if (new RegExp(mapping.onRegEx) &&
                    new RegExp(mapping.onRegEx).test(record.entry) === mapping.onRegExResult) {
                    //we have to re-map the kind no
                    record.kind = mapping.toKind;
                }

            }

            postData.push(record);

        });

        $scope.currentListFactory.postRecords(postData)
            .then(function success() {
                if (cb) {
                    cb();
                }
            });

    };

    var saveAddMode = function () {
        $scope.loading.savebutton = true;
        //just save the whole tag list
        saveNewRecords($scope.taginput_list, function () {
            $scope.loading.savebutton = false;
            saveSuccess();
        });
    };

    /*
    * EDIT
    */
    /*
    * EDIT
    */
    var initEditMode = function () {

        //if we are aditing a single record
        if (modal_settings.editRecords) {

            $scope.loading.taglist = true;

            var currCount = 0;
            var maxCount = modal_settings.editRecords.length;

            var checkCount = function () {

                //break and close if invalid app id's found
                if (appIDZero && appIDZero.length > 0) {
                    modalFactory.okModal(3, $translate.instant('MSG.ERRORAPPID', {appname: appIDZero[0].entry}));
                    $scope.cancel();
                    return;
                }

                currCount++;
                if (currCount >= maxCount) {
                    $scope.loading.taglist = false;
                }
            };

            var appIDZero = [];

            _.each(modal_settings.editRecords, function (editRecord, editRecordIndex) {

                $scope.currentListFactory.getByObjectKey(editRecord.text || editRecord.data.entry)
                    .then(function success(response) {

                            var webKinds = [
                                kind_types.list_kind_blocked_url,
                                kind_types.list_kind_blocked_kw,
                                kind_types.list_kind_allowed_kw,
                                kind_types.list_kind_timelimited,
                                kind_types.list_kind_group_internet
                            ];

                            if (_.contains(webKinds, response.data.kind) == false && response.data.applicationID == 0) {
                                appIDZero.push(response.data);
                            }

                            //set kind to blocked url for blocked keywords, too
                            if (response.data.kind === kind_types.list_kind_blocked_kw) {
                                response.data.kind = kind_types.list_kind_blocked_url;
                            }

                            $scope.data_list.push(response.data);
                            $scope.taginput_list.push({text: response.data.entry});

                            //we use first record as template for global settings
                            if (editRecordIndex === 0) {
                                _.each(response.data, function (resp_val, resp_key) {
                                    $scope.data[resp_key] = resp_val; //we use the kind of edited record

                                    if (resp_key == 'tagDetails') {
                                        if (resp_val && resp_val != '') {
                                            var splittedTagDetails = resp_val.split('|');
                                            if (splittedTagDetails[0]) {
                                                $scope.data.tagDetailsBonusUsage = splittedTagDetails[0];
                                            }
                                            if (splittedTagDetails[1]) {
                                                $scope.data.tagDetailsBonusTime = splittedTagDetails[1];
                                            }
                                        } else {
                                            $scope.data.tagDetailsBonusUsage = '00:15';
                                            $scope.data.tagDetailsBonusTime = '00:30';
                                        }
                                    }
                                });
                                setKindById(response.data.kind);
                            }

                            checkCount();
                        },
                        function error() {
                            $log.info('MODAL ERROR');
                            checkCount();
                        });

            });

            if ($scope.currentContext == 'cb' && modal_settings.context == 'prg') {
                //$scope.taglistEnabled=false;
            }

        } else {
            $log.info('You have to pass a valid modal_settings.editRecords array');
            return;
        }

    };

    var saveEditMode = function () {

        $scope.loading.savebutton = true;
        $log.info('saveEditMode:' , $scope.data);
        $scope.data.kind = $scope.kind.value; //let data object reflect the right kind

        //divide into edited and added records
        var edited_records = _.filter($scope.taginput_list, function (tag_obj) {
            return _.findWhere($scope.data_list, {entry: tag_obj.text}) === undefined ? false : true;
        });

        //create a string linst from object array
        var edited_records_arr = _.map(edited_records, function (record) {
            return record.text;
        });

        var added_records_arr = _.filter($scope.taginput_list, function (tag_obj) {
            return _.findWhere($scope.data_list, {entry: tag_obj.text}) === undefined ? true : false;
        });

        var deleted_records_arr = _.filter($scope.data_list, function (data_obj) {
            return _.findWhere($scope.taginput_list, {text: data_obj.entry}) === undefined ? true : false;
        });

        //remove the entry key, otherwise it will be the
        //same for all records, we dont want that
        var data_to_save = _.clone($scope.data);
        delete data_to_save.entry;

        //determine how many api calls we will have to wait for
        callsToDo = 0;
        if (edited_records_arr.length > 0) {
            callsToDo = callsToDo + 1;
        }

        if (added_records_arr.length > 0) {
            callsToDo = callsToDo + 1;
        }

        if (deleted_records_arr.length > 0) {
            callsToDo = callsToDo + 1;
        }

        //save the edited records
        if (edited_records_arr.length > 0) {

            var edit_post_arr = [];
            _.each(edited_records, function (v) {

                var original_record = _.findWhere($scope.data_list, {entry: v.text});

                var postData = _.clone(data_to_save);
                postData.entry = v.text;

                _.each(original_record, function (orig_v, orig_k) {

                    if (!_.contains(bulk_ignore_keys, orig_k)) {
                        postData[orig_k] = orig_v;
                    }
                });

                edit_post_arr.push(postData);
                $log.info('edited arr', edit_post_arr);
            });

            $scope.currentListFactory.postRecords(edit_post_arr)
                .then(function success() {
                    closeAfterNumberOfCalls();
                }, function error() {
                    closeAfterNumberOfCalls();
                });

        }

        if (added_records_arr.length > 0) {

            var add_post_arr = [];
            _.each(added_records_arr, function (v) {
                var postData = _.clone(data_to_save);
                postData.entry = v.text;

                //if this is a program we have to modify some more values
                if ($scope.currentContext == 'cc' && modal_settings.context == 'prg') {
                    postData.description = '';
                    postData.isModified = true;
                    postData.isDeleted = false;
                    postData.installationDate = '2010-01-01';
                }

                add_post_arr.push(postData);
            });

            $scope.currentListFactory.postRecords(add_post_arr, data_to_save)
                .then(function success() {
                    closeAfterNumberOfCalls();
                }, function error() {
                    closeAfterNumberOfCalls();
                });

        }

        if (deleted_records_arr.length > 0) {

            //SPECIAL: If this is CB and PRG Context (Mobile Apps),
            // we cannot delete them but we will re-group them to allowed.

            if (modal_settings.context == 'net') {

                //we will really delete the records

                var delete_post_arr = [];
                _.each(deleted_records_arr, function (v) {
                    delete_post_arr.push(v.text || v.entry);
                });

                $scope.currentListFactory.deleteRecords(delete_post_arr)
                    .then(function success() {
                        closeAfterNumberOfCalls();
                    }, function error() {
                        closeAfterNumberOfCalls();
                    });

            } else if (modal_settings.context == 'prg') {

                //we will only regroup the records to allowed
                var regroup_post_arr = [];
                _.each(deleted_records_arr, function (del_rec) {
                    var postData = del_rec;
                    postData.kind = cbListsFactory.listKinds.list_kind_allowed_prg;
                    regroup_post_arr.push(postData);
                });

                $scope.currentListFactory.postRecords(regroup_post_arr)
                    .then(function success() {
                        closeAfterNumberOfCalls();
                    }, function error() {
                        closeAfterNumberOfCalls();
                    });

            }

        }

        var count = 0;
        var closeAfterNumberOfCalls = function () {
            count++;
            if (count >= callsToDo) {
                $scope.loading.savebutton = false;
                saveSuccess();
                callsToDo = 0;
                count = 0;
            }
        };

    };

    /**
     * INIT
     */

    var continueInitAfterGroupFetch = function () {

        if (modal_settings.mode == 'add') {
            initAddMode();
        } else if (modal_settings.mode == 'edit') {
            initEditMode();
        }
    };

    //get groups if not webfilter mode
    if (modal_settings.context == 'prg') {

        //disable taglist and dropdown
        $scope.loading.groupnames = true;

        //add groups to dropdown
        scgSettingsFactory.getGroups()
            .then(function success(response) {

                _.each(response.data, function (group) {
                    $scope.kindDropdownContent.push({
                        value: group.kind,
                        name: group.fullname
                    });

                });


                continueInitAfterGroupFetch();

                $scope.loading.groupnames = false;

            });

    } else {
        continueInitAfterGroupFetch();
    }

    /**
     * SAVE
     */
    $scope.save = function () {
        scgSharedFactory.needsSync = true;
        if (modal_settings.mode == 'add') {
            saveAddMode();
        } else if (modal_settings.mode == 'edit') {
            saveEditMode();
        }
    };

    /*
    * Error accepted by user
    */
    $scope.errorAccepted = function (error, index) {
        $scope.errors.splice(index, 1);
    };

    /**
     * Watch taginput, we can only save if there is tag content in it
     */
    $scope.$watch('[taginput_list, errors]', function () {

        var deleted_recs = _.filter($scope.data_list, function (data_obj) {
            return _.findWhere($scope.taginput_list, {text: data_obj.entry}) === undefined ? true : false;
        });

        if (($scope.taginput_list.length > 0 || deleted_recs.length > 0) && $scope.errors.length < 1) {
            $scope.uiflags.but_save_disabled = false;
        } else {
            $scope.uiflags.but_save_disabled = true;
        }

    }, true);

    $scope.refreshLayout = function(kind, activeDevice){


        if (kind) {

            console.log("refresh layout, kind=", kind);

            //check if timelimit content area should be displayed based on list kind id
            if (_.contains([
                    kind_types.list_kind_blocked_url,
                    kind_types.list_kind_blocked_prg,
                    kind_types.list_kind_group_system,
                    kind_types.list_kind_group_winapps,

                ], $scope.kind.value)) {

                $scope.uiflags.isTimeLimitContent = false;
                $scope.uiflags.isCcAllowedContent = false;
                $scope.uiflags.isCbAllowedContent = false;
                $scope.uiflags.isOnlyTagList = true;

            } else if (_.contains([
                    kind_types.list_kind_allowed_prg,
                    kind_types.list_kind_allowed_kw
                ], $scope.kind.value)) {

                $scope.uiflags.isTimeLimitContent = false;

                if ($scope.currentContext == 'cb') {
                    $scope.uiflags.isCcAllowedContent = false;
                    $scope.uiflags.isCbAllowedContent = true;
                    console.log("activeY device=", AuthFactory.activeDevice);
                    console.log("activeY kind.value=", $scope.kind.value);
                    if( $scope.kind.value == kind_types.list_kind_allowed_prg && AuthFactory.activeDevice.deviceType == 30 ){
                        $scope.uiflags.isOnlyTagList = false;
                    }else {
                        $scope.uiflags.isOnlyTagList = true;
                        $scope.uiflags.showCB3AllowedApps = false;
                    }


                } else {
                    $scope.uiflags.isCcAllowedContent = true;
                    $scope.uiflags.isCbAllowedContent = false;
                    $scope.uiflags.isOnlyTagList = false;
                }

            } else {

                $scope.uiflags.isCcAllowedContent = false;
                $scope.uiflags.isCbAllowedContent = false;
                $scope.uiflags.isTimeLimitContent = true;
                $scope.uiflags.isOnlyTagList = false;
            }

            //check if we have to prefill timings based on group defaults
            if (_.contains([10, 11, 12, 13, 14, 15], kind.value)) {

                $scope.loading.timeinputs = true;
                $scope.uiflags.isOnlyTagList = false;

                //get group timings from api
                $scope.currentListFactory.getGroupDefaults(kind.value - 10)
                    .then(function success(response) {

                        var defaultData = response.data;

                        //set scope to group defaults, will then be saved to every record
                        $scope.data = {
                            'isTimeLimitActive': defaultData.isTimeLimitActive,
                            'timeLimitDay': defaultData.timeLimitDay,
                            'timeLimitWeek': defaultData.timeLimitWeek,
                            'timeLimitMonth': defaultData.timeLimitMonth,
                            'isWeekdayLimitActive': defaultData.isWeekdayLimitActive,
                            'timeLimitMonday': defaultData.timeLimitMonday,
                            'timeLimitTuesday': defaultData.timeLimitTuesday,
                            'timeLimitWednesday': defaultData.timeLimitWednesday,
                            'timeLimitThursday': defaultData.timeLimitThursday,
                            'timeLimitFriday': defaultData.timeLimitFriday,
                            'timeLimitSaturday': defaultData.timeLimitSaturday,
                            'timeLimitSunday': defaultData.timeLimitSunday
                        };

                        //add context specific timing key
                        if ($scope.currentContext == 'cc') {
                            $scope.data.blockTimes = defaultData.blockTimes;
                        } else if ($scope.currentContext == 'cb') {
                            $scope.data.blockedTimesCB = defaultData.blockedTimesCB;
                        }

                        $scope.loading.timeinputs = false;

                    });
            }

        }

    }

    //monitor kind and set ui flag
    $scope.$watch('kind', function (new_val) {

        $scope.kindColorClass = $scope.kindColorMapping[$scope.kind.value] || $scope.kindDefaultColor;

        $scope.refreshLayout(new_val, AuthFactory.activeDevice);

    }, true);

    //watch limit internal logic and create error if necessary
    $scope.$watch('[data.timeLimitDay, data.timeLimitWeek, data.timeLimitMonth, data.isTimeLimitActive, kind.value]',
        function () {

            if ($scope.data.timeLimitDay && $scope.data.timeLimitWeek && $scope.data.timeLimitMonth && $scope.kind && //all vars are here
                ($scope.kind.value == kind_types.list_kind_timelimited || $scope.kind.value == kind_types.list_kind_timelimited_prg) && //we are in timelimited mode
                $scope.data.isTimeLimitActive === true //time limits are active
            ) {

                var valdata = {
                    timeLimitDay: $scope.data.timeLimitDay,
                    timeLimitWeek: $scope.data.timeLimitWeek,
                    timeLimitMonth: $scope.data.timeLimitMonth
                };

                $log.info('scgValidationFactory', scgValidationFactory);

                scgValidationFactory.validateTimelimits(valdata, addUniqueError, removeErrorById);

            } else if ($scope.data.isTimeLimitActive === false || $scope.kind.value != kind_types.list_kind_timelimited) {
                removeErrors();
            }

        });

    $scope.$watch('[data.tagDetailsBonusTime, data.tagDetailsBonusUsage]', function () {

        //Do we have undefined?
        if (!$scope.data.tagDetailsBonusUsage) {
            $scope.data.tagDetailsBonusUsage = '00:15';
        }
        if (!$scope.data.tagDetailsBonusTime) {
            $scope.data.tagDetailsBonusTime = '00:30';
        }

        var newTagDetailsValue = $scope.data.tagDetailsBonusUsage + '|' + $scope.data.tagDetailsBonusTime;

        if ($scope.data.tagID == 2 && newTagDetailsValue != $scope.data.tagDetails) {
            $scope.data.tagDetails = newTagDetailsValue;
        } else if ($scope.data.tagID != 2) {
            $scope.data.tagDetails = '';
        }
    });

    var addUniqueError = function (err) {
        //if there is no such error yet, push it to array, otherwise ignore
        if (_.where($scope.errors, err).length === 0) {
            $scope.errors.push(err);
        }
    };

    var removeErrorById = function (id) {
        $scope.errors = _.filter($scope.errors, function (error) {
            return error.id !== id;
        });
    };

    var removeErrors = function () {
        $scope.errors = [];
    };


    //watcher for deviceType change (CBE!)
    $scope.$watch(function () {
        return AuthFactory.activeDevice + AuthFactory.activeUser;
    }, function (newVal) {
        if ($scope.deviceType != AuthFactory.activeDevice.deviceType) {
            $scope.deviceType = AuthFactory.activeDevice.deviceType;
        }
        $scope.refreshLayout($scope.kind, AuthFactory.activeDevice);
    });

    //Hint stuff, Add $scope.hintHide at top of page
    //--------------------------------------------------------------------------------
    scgSharedFactory.getHint(200).//adjust hintID here
    then(function success(response) {
            if (response.data.hint200) {                         //adjust hintID here
                $scope.hintHide = response.data.hint200;
            }        //adjust hintID here
            else {
                //not assgined, set to false!
                $scope.hintHide = false;
            }
        }
    );
    $scope.hintDone = function () {
        $scope.hintHide = true;
        scgSharedFactory.setHint(200).then(function success() {
                $log.info('HINT DONE SUCCESS');
            }
        );
    };
    //--------------------------------------------------------------------------------

});
