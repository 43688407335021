'use strict';
angular.module('ChicoApp.CC-TimeLimits', [])
    .config(function($stateProvider) {
      $stateProvider.state('cc-timelimits', {
        redirectTo: 'cc-timelimits.pc',
        url: '/timelimits',
        template: '<ui-view/>'
      })
            .state('cc-timelimits.pc', {
              url: '/pc',
              templateUrl: 'templates/childcontrol/cc_timelimits/templates/timelimits-pc.html',
              controller: 'CC-TimeLimitsControllerPC',
            })

            .state('cc-timelimits.internet', {
              url: '/internet',
              templateUrl: 'templates/childcontrol/cc_timelimits/templates/timelimits-internet.html',
              controller: 'CC-TimeLimitsControllerInternet',
            })

          .state('cc-timelimits.special', {

              url: '/special',
              templateUrl: 'templates/childcontrol/cc_timelimits/templates/timelimits-special.html',
              controller: 'CC-TimeLimitsControllerSpecial',
            })

          .state('cc-timelimits.roaming', {

              url: '/roaming',
              templateUrl: 'templates/childcontrol/cc_timelimits/templates/timelimits-roaming.html',
              controller: 'CC-TimeLimitsControllerRoaming',
          });
    })
    .run(function() {});
