'use strict';
angular.module('ChicoApp')

.constant('API', {
  'url': 'https://dev.salfeld.net/api/',
    'rooturl': 'https://dev.salfeld.net/',
  'filesurl': 'https://files.salfeld.net/',
  'add-dev-headers': true
})

