'use strict';
angular.module('ChicoApp.CC-Internet')

.controller('CC-InternetController', function(
  $scope,
  $state,
  $modal,
  $window,
  ccListsFactory,
  ccInternetFactory,
  ccInternetMiscFactory,
  scgGlobalSpinnerFactory,
  modalFactory,
  $log,
  _,
  scgSharedFactory) {

  $scope.data = {};
  $scope.data.internetFilterLevel = 1;
  $scope.hintHide = true;
  $scope.ageStr = '';
  $scope.listCounters = {};
  $scope.selectModel = 0;

  ccListsFactory.getListCounters().then(function(response) {
    $log.info('list counters', response);
    $scope.listCounters = response.data;
  });


    $scope.importExport = function() {
        $log.info('We are importExport this is select=', $scope.selectModel);
        if ($scope.selectModel==0) {$scope.exportWeb(2);}
        if ($scope.selectModel==1) {$scope.exportWeb(1);}
        if ($scope.selectModel==2) {$scope.importWeb(2);}
        if ($scope.selectModel==3) {$scope.importWeb(1);}
    }


    $scope.importWeb = function(kind) {
        //kind=1 blacklist, kind=2 whitelist
        $log.info('IMPORT WEB');
        modalFactory.importModal.open({
            modal_settings: {
                title: 'aaaa',
                message: 'xx',
                butOkLabel: 'xx',
                butCanelLabel: 'xx',
                refreshStateAfterClose: false
            },
            data: {
                kind: kind
            },
            callbacks: {
                ok: function() {
                },
                cancel: function() {
                }
            }
        });
    };


    $scope.exportWeb = function(kind) {
        //kind=1 blacklist, kind=2 whitelist
        $log.info('EXPORT WEB kind=' + kind);
        scgGlobalSpinnerFactory.blockUI();
        ccInternetMiscFactory.export(kind).then(function success(response) {
            $log.info('Export kind=' + kind);
            $log.info('export text=', response.data)
            scgGlobalSpinnerFactory.unblockUI();

            var res = String(response.data);
            var list = res.replace(new RegExp(',', 'g'), '\n');
            $scope.exportlist = list;

            var modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/childcontrol/cc_internet/templates/internet-modal-export.html',
                windowTemplateUrl: 'templates/childcontrol/cc_internet/templates/internet-modal-window-small.html',
                scope: $scope
            });
        });
    }


      $scope.importTestOLD = function() {
        $log.info('this is import test v2');
        modalFactory.showImportModal();
      }

  ccInternetFactory.getSettings()
        .then(function success(response) {


          $scope.data = response.data;
          $log.info('INTERNET data=', $scope.data);

          $scope.data.internetFilterLevel = 2; //default
          if ($scope.data.isNewWebsitesBlocked == true) {
            $scope.data.internetFilterLevel = 3;
          }
          $log.info('INTERNET internetFilterLevel=', $scope.data.internetFilterLevel);
          if ($scope.data.webFilterAge != '0') {
            $scope.ageStr = $scope.data.webFilterAge + '+';
          }else {
            $scope.ageStr = '';
          }

        if ($scope.data.internetFilterLevel == 3) $state.go('cc-internet.tabs.allowed');

          //add watchers
          _.each($scope.data, function(v, k) {
            $scope.$watch('data.' + k, function(new_val, old_val) {
              if (new_val != old_val) {
                var changed_object = {};
                changed_object[k] = new_val;
                scgSharedFactory.needsSync = true;
                $scope.data.isNewWebsitesBlocked = ($scope.data.internetFilterLevel == 3);
                ccInternetFactory.setSetting(changed_object);
                $log.info('Changed, DATA', $scope.data);
                if ($scope.data.internetFilterLevel == 3) $state.go('cc-internet.tabs.allowed');
              }
            }, true);
          });

        });



    $scope.checkUrl = function() {
        if ($scope.data.webRedirectUrl.indexOf(".")!=-1)
        {
            $scope.data.webRedirectUrl = $scope.data.webRedirectUrl.toLowerCase();
            if ($scope.data.webRedirectUrl.substring(0,4)!='http')      {
                $scope.data.webRedirectUrl = "http://" + $scope.data.webRedirectUrl;     }
        }
        else { //no "." found, clear
            $scope.data.webRedirectUrl = "";
            $scope.data.webRedirect = false;    }
    }

    $scope.testUrl = function() {
        $log.info("TestUrl for URL=" + $scope.data.webRedirectUrl);
        $window.open($scope.data.webRedirectUrl, '_blank');
    }


    //Hint stuff, Add $scope.hintHide at top of page
  //--------------------------------------------------------------------------------
  scgSharedFactory.getHint(30).                                //adjust hintID here
          then(function success(response) {
            if (response.data.hint30) {                         //adjust hintID here
              $scope.hintHide = response.data.hint30;}        //adjust hintID here
            else {
              //not assgined, set to false!
              $scope.hintHide = false;}
          }
      );
  $scope.hintDone = function() {
    $scope.hintHide = true;
    scgSharedFactory.setHint(30).then(function success() {
              $log.info('HINT DONE SUCCESS');
            }
  );};
  //--------------------------------------------------------------------------------

});
