'use strict';
angular.module('ChicoApp.CB-Usage', [])
    .config(function($stateProvider) {
      $stateProvider.state('cb-usage', {
        url: '/reports',
        template: '<ui-view/>',
        redirectTo: 'cb-usage.reportsv2',
        controller: null
      })

        .state('cb-usage.reports', {
          url: '/cb-reports',
          templateUrl: 'templates/chicobrowser/cb_usage/templates/reports.html',
          controller: 'CB-Usage-Reports'
        })

          .state('cb-usage.reportsv2', {
              url: '/cb-reports-v2',
              templateUrl: 'templates/chicobrowser/cb_usage/templates/reportsv2.html',
              controller: 'CB-Usage-Reports-V2',
              params: {
                  weekday: null,
              }
          })



          .state('cb-usage.limitinfo', {
              url: '/cb-limitinfo',
              templateUrl: 'templates/chicobrowser/cb_usage/templates/limitinfo.html',
              controller: 'CB-Usage-Limitinfo'
          })


          .state('cb-usage.unblock', {
          url: '/cb-unblock',
          templateUrl: 'templates/chicobrowser/cb_usage/templates/unblock.html',
          controller: 'CB-Usage-Unblock'
        });

    })
    .run(function() {});
