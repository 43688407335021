angular.module('ChicoApp.Shared').directive('timetable', function(
  $window,
  $document,
  $rootScope,
  $timeout,
  $log,
  timetableFactory) {
  // Runs during compile
  return {
    templateUrl: 'templates/shared/timetable/timetable.html',
    restrict: 'E',
    scope: {
      blockedtimes: '=',
      hideResetButton: '@',
      disableDataInput: '@',
      control: '='
    },
    controller: function($scope, $element, _) {

      $scope.$on('$destroy', function() {
        // remove maybe existing quarter menu when timetable is detroyed
        // for whatever reason
        angular.element(document).find('.quartermenu').remove();
      });

      if ($scope.control) {
        $scope.control = {
          reset: function() {
            $scope.reset();
          }
        };
      }

      if ($scope.blockedtimes) {
        timetableFactory.setBlockedtimes($scope.blockedtimes);
      }

      $scope.$watch(function() {return $scope.blockedtimes;},
        function watchCallback(newValue, oldValue) {
          if (newValue && newValue != oldValue) {
            //console.log('blocktimes changed by watcher');
            timetableFactory.setBlockedtimes($scope.blockedtimes);
          }
        });

      $scope.$watch(function() {
        return timetableFactory.blockedtimes;
      },function watchCallback(newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          //console.log('blocktimes in factory changed');
          $scope.blockedtimes = newValue;
        }
      });

      // Remove existing Quartermenus on Statechange
      $rootScope.$on('$stateChangeStart', function() {
        angular.element(document).find('.quartermenu').remove();
      });

      //data

      $scope.days = timetableFactory.days;
      $scope.hoursPerDay = timetableFactory.hoursPerDay;
      //functions
      $scope.toogleSingleHour = function(hour) {

        if ($scope.clicked || $scope.disableDataInput == "true" ) {
          $scope.cancelClick = true;
          return;
        }

        $scope.clicked = true;
        $timeout(function() {
          if ($scope.cancelClick) {
            $scope.cancelClick = false;
            $scope.clicked = false;
            return;
          }
          //handle click
          //remove visible quartermenus

          angular.element(document)
          .find('.quartermenu')
          .remove();
          var hourIsPartiallyBlocked = false;
          hour.quarters.forEach(function(quarter) {
            if (quarter.blocked) {
              hourIsPartiallyBlocked = true;
            }
          });
          if (hourIsPartiallyBlocked) {
            //unblock all quarters
            //console.log('unblock all quarters');
            hour.quarters.forEach(function(quarter) {
              quarter.blocked = false;
            });
          } else {
            //block all quarters
            //console.log('block all quarters');
            hour.quarters.forEach(function(quarter) {
              quarter.blocked = true;
            });
          }
          $scope.cancelClick = false;
          $scope.clicked = false;
        }, 300);
      };
      $scope.showQuarterMenu = function(hour, $event) {

        if( $scope.disableDataInput == "true" ){
          return;
        }

        //console.log('hour');
        //console.log(hour);
        $timeout(function() {
          //handle doubleclick
          //remove visible quartermenus

          angular.element(document)
          .find('.quartermenu')
          .remove();
          //add quartermenu class to current target
          var clickedCell = angular.element($event.currentTarget);

          //build quartermenu and append to body
          var quartermenu = angular.element('<div class="quartermenu"></div>');
          //add close button
          var closebutton = angular.element('<div><i class="fa fa-times"></i></div>');
          closebutton.css({
            width: '100px',
            height: '20px',
            fontSize: '15px',
            lineHeight: '20px',
            textAlign: 'right',
            background: '#fff'
          });
          closebutton.on('click', function() {
            angular.element(document)
            .find('.quartermenu')
            .remove();
          });
          quartermenu.append(closebutton);

          //add quarters
          for (var i = 0; i < hour.quarters.length; i++) {            //build quarter
            var quarter = angular.element(
              '<div data-day="' + clickedCell[0].dataset.day +
              '" data-hour="' + clickedCell[0].dataset.hour +
              '" data-quarter="' + hour.quarters[i].label +
              '">' +
              hour.quarters[i].label + '</div>');
            quarter.css({
              width: '50px',
              height: '50px',
              border: '#fff 1px solid',
              float: 'left',
              textAlign: 'center',
              lineHeight: '50px'
            });
            if (hour.quarters[i].blocked) {
              quarter.addClass('selected');
            }
            quarter.on('click', function() {
              $scope.toggleQuarter(angular.element(this)
              .data());
              if (angular.element(this)
              .hasClass('selected')) {
                angular.element(this)
                .removeClass('selected');
              } else {
                angular.element(this)
                .addClass('selected');
              }
            });
            quartermenu.append(quarter);
          }
          //calculate position for Quartermenu
          var offsetX = 0;
          var offsetY = 0;
          if (clickedCell[0].dataset.hour > $scope.hoursPerDay.length / 2) {
            //afternoon and evening hours
            offsetX = -100;
          }
          //add styling for quartermenu
          quartermenu.css({
            display: 'block',
            position: 'absolute',
            background: '#fff',
            outline: '#fff 3px solid',
            width: '100px',
            height: '120px',
            left: $event.pageX + offsetX + 'px',
            top: $event.pageY + offsetY + 'px',
            zIndex: 9999
          });
          angular.element(document)
          .find('body')
          .append(quartermenu);
          // console.log(clickedCell[0].dataset.day);
          // console.log(clickedCell[0].dataset.hour);
          // console.log(hour);
          // console.log('x: '+$event.pageX + 'px');
          // console.log('y: '+$event.pageY + 'px');
        });
      };
      $scope.toggleDaytime = function(time) {

        if($scope.disableDataInput=="true"){
            return;
        }

        var isblocked = false;
        //check if something is blocked in this timeslot
        $scope.days.forEach(function(day) {
          var hour = _.findWhere(day.hours, {
            hour: time
          });
          hour.quarters.forEach(function(quarter) {
            if (quarter.blocked) {
              isblocked = true;
            }
          });
        });
        if (isblocked) {
          $scope.days.forEach(function(day) {
            var hour = _.findWhere(day.hours, {
              hour: time
            });
            hour.quarters.forEach(function(quarter) {
              quarter.blocked = false;
            });
          });
        } else {
          $scope.days.forEach(function(day) {
            var hour = _.findWhere(day.hours, {
              hour: time
            });
            hour.quarters.forEach(function(quarter) {
              quarter.blocked = true;
            });
          });
        }
      };
      $scope.toggleDay = function(dayname) {

        if($scope.disableDataInput=="true"){
            return;
        }

        var isblocked = false;
        //check if something is blocked in this day
        var day = _.findWhere($scope.days, {
          name: dayname
        });
        day.hours.forEach(function(hour) {
          hour.quarters.forEach(function(quarter) {
            if (quarter.blocked) {
              isblocked = true;
            }
          });
        });
        if (isblocked) {
          day.hours.forEach(function(hour) {
            hour.quarters.forEach(function(quarter) {
              quarter.blocked = false;
            });
          });
        } else {
          day.hours.forEach(function(hour) {
            hour.quarters.forEach(function(quarter) {
              quarter.blocked = true;
            });
          });
        }
      };
      $scope.toggleQuarter = function(data) {
        // console.log(data);
        var day = _.findWhere($scope.days, {
          name: data.day
        });
        //console.log(day);
        var hour = _.findWhere(day.hours, {
          hour: data.hour
        });
        // console.log(hour);
        var quarter = _.findWhere(hour.quarters, {
          label: String(data.quarter)
        });
        // console.log(quarter);
        $scope.$apply(function() {
          quarter.blocked = !quarter.blocked;
        });
      };

      $scope.reset = function() {
        $scope.days.forEach(function(day) {
          day.hours.forEach(function(hour) {
            hour.quarters.forEach(function(quarter) {
              quarter.blocked = false;
            });
          });
        });
      };

      //Drag and Drop
      var startCell = null;
      var dragging = false;

      function setSingleHour(data, status) {

        var day = _.findWhere($scope.days, {
          name: data.day
        });
        var hour = _.findWhere(day.hours, {
          hour: data.hour
        });
        hour.quarters.forEach(function(quarter) {

          quarter.blocked = status;

        });

      }

      function checkStartCellStatus(data) {
        var result = false;
        var day = _.findWhere($scope.days, {
          name: data.day
        });
        var hour = _.findWhere(day.hours, {
          hour: data.hour
        });
        hour.quarters.forEach(function(quarter) {

          if (quarter.blocked) {
            result = true;
          }

        });

        return result;
      }

      function checkWindowSize() {
        if (window.innerWidth < 768) {
          // console.log('small screen');
          return true;
        }
        // console.log('big screen');
        return false;
      }

      function mouseUp(el) {
        if (checkWindowSize() || $scope.disableDataInput == "true") {
          return;
        }
        setEndCell(el);
        dragging = false;

      }

      function mouseDown(el) {
        if (checkWindowSize() || $scope.disableDataInput == "true") {
          return;
        }
        dragging = true;
        setStartCell(el);

      }

      function mouseEnter(el) {
        if (checkWindowSize() || $scope.disableDataInput == "true") {
          return;
        }
        if (!dragging) {
          return;
        }
        setEndCell(el);
      }

      function setStartCell(el) {
        startCell = el;
        startCell.status = checkStartCellStatus(startCell.data());
        // data = el.data();
      }

      function setEndCell(el) {
        var cells = cellsBetween(startCell, el);

        if (cells) {
          cells.each(function() {
            var el = angular.element(this);
            var data = el.data();
            setSingleHour(data,!startCell.status);
          });
        }

      }

      function cellsBetween(start, end) {
        var coordsStart = getCoords(start);
        var coordsEnd = getCoords(end);

        if (coordsStart && coordsEnd) {
          var topLeft = {
            column: $window.Math.min(coordsStart.column, coordsEnd.column),
            row: $window.Math.min(coordsStart.row, coordsEnd.row),
          };
          var bottomRight = {
            column: $window.Math.max(coordsStart.column, coordsEnd.column),
            row: $window.Math.max(coordsStart.row, coordsEnd.row),
          };
          return $element.find('.horizontal td').filter(function() {
            var el = angular.element(this);
            var coords = getCoords(el);
            return coords.column >= topLeft.column &&
            coords.column <= bottomRight.column &&
            coords.row >= topLeft.row &&
            coords.row <= bottomRight.row;
          });
        }else {
          return;
        }
      }

      function getCoords(cell) {
        if (cell) {
          return {
            column: cell[0].cellIndex,
            row: cell.parent()[0].rowIndex
          };
        }
      }

      function wrap(fn) {
        return function() {
          var el = angular.element(this);
          $scope.$apply(function() {
            fn(el);
          });
        };
      }

      $element.delegate('td', 'mousedown', wrap(mouseDown));
      $element.delegate('td', 'mouseenter', wrap(mouseEnter));
      $document.delegate('body', 'mouseup', wrap(mouseUp));
    }
  };
});

//Directive to Handle Rightclicks on Elements
angular.module('ChicoApp.Shared').directive('ngRightClick', function($parse) {
  return function(scope, element, attrs) {
    var fn = $parse(attrs.ngRightClick);
    element.bind('contextmenu', function(event) {
      scope.$apply(function() {
        event.preventDefault();
        fn(scope, {$event: event});
      });
    });
  };
});
