'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Import-Controller', function(
    $scope,
    $state,
    $modalInstance,
    $http,
    $log,
    $modal,
    $translate,
    modalFactory,
    AuthFactory,
    scgGlobalSpinnerFactory,
    apiFunctions,
    modalParams
    ) {

    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.data = {};
    $scope.importString = "";
    $scope.importHeader = $translate.instant('BUTTON.IMPORT');
    $scope.importkind = 5; //blacklist by default

    $log.info("kind from above:", modalParams.kind);
    $log.info("context from above:", modalParams.context);

    if (modalParams.kind==2) $scope.importkind=7; //whitelist

    if (modalParams.kind==1) $scope.importHeader = $translate.instant('BUTTON.IMPORT') + " Blacklist";
    if (modalParams.kind==2) $scope.importHeader = $translate.instant('BUTTON.IMPORT') + " Whitelist";


   function successModal(msg) {
        scgGlobalSpinnerFactory.unblockUI();
        modalFactory.infoModal.open({
            modal_settings: {
                title: $translate.instant('HEADER.INFO'),
                message: msg,
                butOkLabel: $translate.instant('BUTTON.OK'),
                butCancelLabel: null,
                iconNo: 1,
                refreshStateAfterClose: false
            },
            callbacks: {
                ok: function() {
                    if (modalParams.context=='cb') {
                        $log.info('success callback context CB handler, importkind=' + $scope.importkind);
                        scgGlobalSpinnerFactory.unblockUI();
                        if ($scope.importkind == 7) $state.go('cb-webfilter.tabs.allowed');
                        if ($scope.importkind == 5) $state.go('cb-webfilter.tabs.blocked');
                        return;
                     }
                    if ($scope.importkind == 7) $state.go('cc-internet.tabs.allowed');
                    if ($scope.importkind == 5) $state.go('cc-internet.tabs.blocked');
                }
            }
        });
    }

    $scope.ok = function(return_val) {

        //this is import routine
        //$modalInstance.dismiss('cancel');
        //$state.go('cb-webfilter');
        //EOF TEST STUFF

        $log.info('modal import ok pressed context=' , modalParams.context);
        var postString = $scope.importString.replace(" ", "").replace(";", "");

        var urlpath =apiFunctions.get_cc_base_url() + 'ccusage/import/' + $scope.importkind;
        if (modalParams.context=='cb') {urlpath =apiFunctions.get_cc_base_url() + 'cbusage/import/' + $scope.importkind;}

        var req = {
            method: 'POST',
            url: urlpath,
            data: '"' + postString + '"'
        };

        return $http(req).then(function success(){
            $modalInstance.dismiss('cancel');
            successModal($translate.instant('MSG.SSESUCCESS'));
        });

    };




});
