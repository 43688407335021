angular.module('ChicoApp').factory('sseFactory', function(
    $http,
    API,
    $log
  ) {

  var factory = {};
  var mydata = {};

  factory.isOnline = function() {
    var req = {
      method: 'GET',
      url: API.url + 'sse/isonline'
    };
    return $http(req);
  };

  factory.sync = function() {
    var req = {
      method: 'GET',
      url: API.url + 'sse/sync'
    };
    return $http(req);
  };

  factory.msg = function(theMessage) {
    mydata.msg = theMessage;
    var req = {
      method: 'POST',
      url: API.url + 'sse/sendmsg',
      data: mydata
    };
    return $http(req);
  };

  factory.extensionTime = function(extTime) {
    mydata.msg = extTime;
    var req = {
      method: 'POST',
      url: API.url + 'sse/extension',
      data: mydata
    };
    return $http(req);
  };

  factory.screenshot = function() {
    var req = {
      method: 'GET',
      url: API.url + 'sse/screenshot'
    };
    return $http(req);
  };

  //This is for cc-screenshots permanent screenshot update/refreh
  factory.screenshotUpdate = function() {
    var req = {
      method: 'GET',
      url: API.url + 'sse/screenshotupdate'
    };
    return $http(req);
  };

  factory.checkScreenshot = function(filename) {
    $log.info('checkScreenshot filename:' + filename);
    var req = {
      method: 'GET',
      url: 'https://portal.salfeld.net/api/sse/getimg/' + filename
    };
    return $http(req);
  };

  factory.shutdown = function() {
    var req = {
      method: 'GET',
      url: API.url + 'sse/shutdown'
    };
    return $http(req);
  };

  return factory;
});
