angular.module('ChicoApp').factory('licenceFactory', function(
    $http,
    API) {

  var factory = {}; //Dont forget to init, this is also final return value!

  factory.getLicences = function() {
    var req = {
      method: 'GET',
      url: API.url + 'licence/list'
    };
    return $http(req);
  };

  return factory;

});
