/*thisisaplaceholderforAPI-Factories*/
angular.module('ChicoApp').factory('scgSettingsFactory', function(
  $http,
  $cookies,
  $log,
  $translate,
  _,
  API,
  ccListsFactory,
  cbListsFactory,
  AuthFactory) {

  var factory = {};

  var currentContext = AuthFactory.activeContext; //will be cc or cb as String
  var currentListFactory = null; //will be cclists or cblists

  if (currentContext == 'cc') {
    currentListFactory = ccListsFactory;
  }else if (currentContext == 'cb') {
    currentListFactory = cbListsFactory;
  }

  var kind_types = currentListFactory.listKinds; //just for convenience put it into local variable

  factory.getGroups = function(addStaticGroups) {

    addStaticGroups = addStaticGroups || false;

    //is it CB or CC?
    var url = API.url + 'allusers/ccsettings/groupnames'; //default CC
    if (AuthFactory.activeContext === 'cb') {url = API.url + 'cbsettings/groupnames';}
    $log.info('getgroups, url=' + url);

    var req = {
      method: 'GET',
      url: url,
      transformResponse: function(data) {

        //turn seraeated string into array
        var arr = JSON.parse(data).groupnames.split(',');

        var collection = [];

        _.each(arr, function(v, i) {

          v = v.trim();

          var group = {
            raw: v,
            no: v.split(' ')[0],
            kind: parseInt('1' + parseInt(parseInt(i) + 1)),
            name: v,
            fullname: v,
            editable: true
          };
          collection.push(group);
        });

        //add 2 static groups
        if (addStaticGroups === true) {
          collection.push({
            raw: 'Static Internet',
            no: collection.length + 1,
            kind: 10,
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + 10),
            fullname: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + 10),
            editable: false
          });

          collection.push({
            raw: 'Static System',
            no: collection.length + 1,
            kind: 4,
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + 4),
            fullname: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + 4),
            editable: false
          });

          collection.push({
            raw: 'Static WinApps',
            no: collection.length + 1,
            kind: kind_types.list_kind_group_winapps,
            name: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_group_winapps),
            fullname: $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES.' + kind_types.list_kind_group_winapps),
            editable: false
          });

        }

        return collection;
      }
    };
    return $http(req);
  };

  factory.setGroups = function(groups) {

    //this was the old wrong url
    var url = API.url + 'allusers/ccsettings'; //default CC
    if (AuthFactory.activeContext === 'cb') {url = API.url + 'cbsettings';}
    $log.info('SETgroups, url=' + url);

    var req = {
      method: 'POST',
      url: url,
      data: groups,
      transformRequest: function(data) {

        var post_data_arr = [];
        _.each(data, function(group) {
          //recreate group name with no and name
          post_data_arr.push(group.name);
        });

        var post_data_string = post_data_arr.join(',');

        var postData = {'groupnames': post_data_string};

        return JSON.stringify(postData);
      }
    };
    return $http(req);
  };

  return factory;
});
