'use strict';
angular.module('ChicoApp.Login')
    .controller('LogoutController', function(
        $scope,
        $cookieStore,
        $injector,
        $log,
        Metronic,
        AuthFactory) {

        Metronic.unblockUI();

        //This controller is only triggered from ReportRole in  Usage views (CC + CB), because we do
        //not want to show kids not the login screen

        $log.info('I am logout controller');

        $cookieStore.remove('sessionId');
        $cookieStore.remove('user');
        $cookieStore.remove('pcId');
        $cookieStore.remove('oldPcId');
        $cookieStore.remove('role');
        $cookieStore.remove('licenceReloadDone');
        $cookieStore.remove('version');

        //clear http header auth fields
        $injector.get('$http').defaults.headers.common['X-pcId'] = null;
        $injector.get('$http').defaults.headers.common['X-sessionId'] = null;

        $log.info('I am logout controller - logout done');

    });
