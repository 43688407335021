'use strict';
angular.module('ChicoApp.CB-Browsertime')
    .controller('CB-DevicetimeController', function(
        $rootScope,
        $scope,
        $state,
        $http,
        moment,
        $timeout,
        $translate,
        modalFactory,
        AuthFactory,
        _,
        $log,
        cbDevicetimeFactory,
        scgValidationFactory,
        scgSharedFactory,
        scgGlobalSpinnerFactory) {

        var timeTableSaveQueue;

        $scope.hasCBFeature = scgSharedFactory.hasCBFeature;

        $scope.data = {};
        $scope.hintHide = true;
        $scope.contentLoading = false;
        $scope.errors = [];
        $scope.roamingDevices = [];
        $scope.isCB3=false;
        $scope.alwaysAllowedApps = [];
        $scope.hasFeatureNoAppsInBlockTime = false;

        //init timetable
        $scope.timeTableOptions = {
            reset: true
        };


        /*scgSharedFactory.hasCBFeature("hasFeatureNoAppsInBlockTime").then(function (success) {
            $scope.hasFeatureNoAppsInBlockTime = true;
        });*/

        $rootScope.$watch(function() {
            return AuthFactory.activeDevice;
        }, function(activeDevice) {

            //redirect if device app version is not high enough
            if (AuthFactory.activeDevice.versionInfo && (AuthFactory.activeDevice.versionInfo || "0").split("|")[0].replace(/\./g, "") < 211040) {
                $state.go('cbtime.browsertime');
            }

            if (AuthFactory.activeDevice.versionInfo && (AuthFactory.activeDevice.versionInfo || "0").split("|")[0].replace(/\./g, "") < 211054 && $state.current.name === 'cbtime.roaming'){
                $state.go('cb-dashboard');
            }

            if (AuthFactory.activeDevice.deviceType==30) {
                $scope.isCB3=true;
                cbDevicetimeFactory.getAlwaysAllowedApps().
                then(function success(response) {
                    $scope.alwaysAllowedApps = response.data;
                });

            } else {
                $scope.isCB3=false;
            }


        },true);



        $scope.timetableController = {};
        $log.info('hello DeviceTime controller, my state,' ,$state.current.name) ;
        $scope.$on('$viewContentLoaded', function() {


            scgGlobalSpinnerFactory.blockUI();
            cbDevicetimeFactory.getBrowserSettings()
                .then(function success(response) {
                    $scope.data = response.data;
                    if ($state.current.name!='cbtime.roaming') {
                    //watch for inputs, if they do not end in having content, refresh page
                    $timeout(function() {
                        var firstInputValue = angular.element(angular.element('input[ng-model=\'displayedTimeValue\']')[0]).val();
                        if (!firstInputValue) {
                            $state.go($state.current, {}, {reload: true});
                        }
                    }, 2000);
                    }

                    //add watchers
                    _.each($scope.data, function(v, k) {
                        $scope.$watch('data.' + k, function(new_val, old_val) {
                            if (new_val != old_val) {
                                var changed_object = {};
                                changed_object[k] = new_val;

                                //save timetable only with timed queue
                                if (k == 'blockTimesDevice') {
                                    clearTimeout(timeTableSaveQueue);
                                    timeTableSaveQueue = setTimeout(function() {
                                        scgSharedFactory.needsSync = true;
                                        cbDevicetimeFactory.setSetting(changed_object);
                                    }, 1500);
                                }else {
                                    scgSharedFactory.needsSync = true;
                                    cbDevicetimeFactory.setSetting(changed_object);
                                }

                            }
                        }, true);
                    });

                    scgGlobalSpinnerFactory.unblockUI();

                    //Empty object returned (no data in tables!)
                    if (Object.keys(response.data).length === 0) {
                        scgSharedFactory.needsSync = false;
                        modalFactory.okModal(3, '<b>' + $translate.instant('ERROR.ERROR').toUpperCase() + ' :-/</b><br><br>' + $translate.instant('ERROR.INITIAL_NO_DATA'));
                        $state.go('cb-dashboard');
                    }

                });

        });

        $scope.$watch('data',function(n) {
            if (n.isWeekDayLimitActiveDevice == true) {
                $log.info('n', n);
                var compare = [n.timeLimitMondayDevice, n.timeLimitTuesdayDevice, n.timeLimitWednesdayDevice, n.timeLimitThursdayDevice, n.timeLimitFridayDevice, n.timeLimitSaturdayDevice, n.timeLimitSundayDevice];
                var largestweekday = '02:30';
                _.map(compare,function(value) {
                    if (moment.duration(value) > moment.duration(largestweekday)) {
                        largestweekday = value;
                    }
                });
                moment.duration($scope.data.timeLimitDayDevice) <= moment.duration(largestweekday) ? $scope.data.timeLimitDayDevice = largestweekday : null;
            }
        },true);

        //watch limit internal logic and create error if necessary
        $scope.$watch('[data.timeLimitDayDevice, data.timeLimitWeekDevice, data.timeLimitMonthDevice, data.isTimeLimitActiveDevice]',
            function() {

                $log.info('data', $scope.data);

                if ($scope.data.timeLimitDayDevice && $scope.data.timeLimitWeekDevice && $scope.data.timeLimitMonthDevice && //all vars are here
                    $scope.data.isTimeLimitActiveDevice === true //time limits are active
                ) {

                    var valdata = {
                        timeLimitDayDevice: $scope.data.timeLimitDayDevice,
                        timeLimitWeekDevice: $scope.data.timeLimitWeekDevice,
                        timeLimitMonthDevice: $scope.data.timeLimitMonthDevice
                    };

                    scgValidationFactory.validateTimelimits(valdata, addUniqueError, removeErrorById);

                }else if ($scope.data.isTimeLimitActiveDevice === false) {
                    removeErrors();
                }

            });

        $scope.showRoamingDevices = function() {
            $log.info('show Roaming devices');
            $scope.contentLoading=true;
            scgSharedFactory.getRoamingDevices().
                then(function success(response) {
               $log.info('show roaming response:', response);
               $scope.roamingDevices = response.data;
                $scope.contentLoading=false;
            });
        }

        $scope.gotoTAN = function() {
            $log.info("goto tans");
            $state.go('cb-tans');
        }

        $scope.alwaysAllowedAppsInfo = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: $translate.instant('INFO.ALWAYS_ALLOWED_APPS'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function () {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };


        $scope.doUntilAction = function(defaultRadio) {
            modalFactory.untilModal.open({
                    modal_settings: {
                        defaultRadio: defaultRadio,
                        refreshStateAfterClose: true
                    },callbacks: {
                        ok: function () {
                            $log.info('modal-until ok callback');
                            $state.go('cb-dashboard');
                        },
                        cancel: function () {
                        $log.info('modal-until CANCEL callback');
                    }
                    }
                });
        }


        var addUniqueError = function(err) {
            //if there is no such error yet, push it to array, otherwise ignore
            if (_.where($scope.errors, err).length === 0) {
                $scope.errors.push(err);
            }
        };

        var removeErrorById = function(id) {
            $scope.errors = _.filter($scope.errors, function(error) { return error.id !== id; });
        };

        var removeErrors = function() {
            $scope.errors = [];
        };


    });
