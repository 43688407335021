angular.module('ChicoApp').factory('ccInternetFactory', function(
  $http,
  apiFunctions) {

  var factory = {};

  var apikeys = [
      'isWebFilterActive',
      'isWebLogActive',
      'isNewWebsitesBlocked',
      'webFilterAge',
      'webNoWarning',
      'webNeutralWarning',
      'webNoIP',
      'webNoDownload',
      'webDisableSSL',
      'webRedirect',
      'webRedirectUrl'
  ];

  var apikeys_param = apiFunctions.obj_to_url_params(apikeys);

  //main function called by controller
  //---------------------------------------------------------------
  factory.getSettings = function() {
    return factory.getByParams(apikeys_param);
  };

  //only helper func, is called from above func
  //---------------------------------------------------------------
  factory.getByParams = function(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
    };
    return $http(req);
  };

  //is called by watcher from controller
  //---------------------------------------------------------------
  factory.setSetting = function(obj) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: obj
    };
    return $http(req);
  };

  return factory;

});

