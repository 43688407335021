'use strict';
angular.module('ChicoApp.CC-Settings')

    .controller('SCG-Modal-Extension-Controller', function(
        $rootScope,
        $scope,
        $cookies,
        $http,
        $timeout,
        $state,
        $log,
        $modalInstance,
        API,
        extNo) {

        //TODO 8a Woher können wir HIER den Wert aus dem settings controller holen (3er oder 5er Warnung?)
        $scope.warningMinutes = extNo;
        $scope.defaultMinutes = extNo;    
        $log.info('SCG-Modal-Extension has just been opened, controller init running now:' + $scope.warningMinutes); // + "val2=" + valueToChange);

        $scope.lessMinutes = function() {
            $log.info('LESS MINUTES!');
            if ($scope.warningMinutes >= 4) {
              $scope.warningMinutes =  parseInt($scope.warningMinutes) - 1;
            }
          };

        $scope.moreMinutes = function() {
            $log.info('MORE MINUTES!');
            if ($scope.warningMinutes <= 29) {
              $scope.warningMinutes =  parseInt($scope.warningMinutes) + 1;
            }
          };

        $scope.close = function(value) {
            $modalInstance.close(value);
          };

        $scope.setWarntime = function(warntime) {
            $scope.close(warntime);
          };

      });
