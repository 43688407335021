'use strict';
angular.module('ChicoApp.Licence', [])
    .config(function($stateProvider) {
      $stateProvider.state('licence', {
        url: '/licence',
        controller: 'LicenceController',
        templateUrl: 'templates/licence/templates/licence.html',
      })

        .state('mylicences', {
          url: '/mylicences',
          controller: 'LicenceController',
          templateUrl: 'templates/licence/templates/mylicences.html',
        })

        .state('cb-licence', {
          url: '/cb-licence',
          controller: 'CBLicenceController',
          templateUrl: 'templates/licence/templates/cb-licence.html',
        });

    })
    .run(function() {});
