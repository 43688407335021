'use strict';
angular.module('ChicoApp.CC-Programs-V2')
    .controller('CC-Programs-Allowed-Controller-V2', function(
      $scope,
      $state,
      $translate,
      $log,
      ccListsFactory,
      DTOptionsBuilder,
      DTColumnDefBuilder,
      scgDataTableFactory) {

      var modalStartKind = ccListsFactory.listKinds.list_kind_allowed_prg;

      $scope.tableHeaders = [
        $translate.instant('HEADER.ENTRY'),
        $translate.instant('HEADER.DETAILS'),
        $translate.instant('HEADER.INSTALLDATE')
      ];

      $scope.hintHide = true;
      $scope.hideSystem = 4;
      $scope.hideApps = 9;
      $scope.optHideSystem = true;
      $scope.optHideWinApps = true;
      $scope.data = {};
      $scope.data.table_api = [];

      console.log('PROGRAMS V2 ALLOWED');

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);

      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope).withOption('order', [[2, 'asc']]);
      $scope.dtColumnDefs = scgDataTableFactory.getDefaultColumnsIconTable();

        $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };


      var loadList = function() {
        $scope.contentLoading = true;
        $scope.clearInnerList();
        ccListsFactory.getDetailedByListFilter(modalStartKind)
            .then(function success(response) {
              $scope.fillInnerListFromApiList(response.data);
              $scope.data.table_api = response.data;
              $scope.contentLoading = false;
            });
      };

      $scope.changeOpt = function() {
        $log.info('changeOpt fired!');
        if ($scope.optHideSystem==true) $scope.hideSystem=4;
        if ($scope.optHideSystem==false) $scope.hideSystem=-1;
        if ($scope.optHideWinApps==true) $scope.hideApps=9;
        if ($scope.optHideWinApps==false) $scope.hideApps=-1;
        $log.info('changeOpt fired hideSystem now=' + $scope.hideSystem);
        $log.info('changeOpt fired hideWinApps now=' + $scope.hideApps);
        loadList();
      }

        $scope.getImgName = function(entryName){
            var ret = (entryName || "").replace(".exe", ".png");
            return ret;
        }

      //
      loadList();

    });
