'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CB-Usage-Unblock', function(
      $state,
      $scope,
      $http,
      $translate,
      API,
      cbUsageFactory,
      DeviceFactory,
      AuthFactory,
      scgSharedFactory,
      modalFactory,
      _,
      scgDataTableFactory,
      $log
      ) {

      $scope.data = {};
      $scope.data.table_inner = {}; //if using scg data table factory please use data.table_inner to feed the table, otherwise flags for bulk buttons etc will not work

      //$scope.data.table_selected_records will hold the selected records autotmatically

      $scope.data.table_api = [];

      //Prepare data table
      scgDataTableFactory.prepareScopeForTable($scope, 1);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);
      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

      $scope.getRequest = function() {
        var req = {
          method: 'GET',
          url: API.url + 'cbusage/unlockrequests',
        };

        $http(req)
                .then(function success(response) {
                  $log.info('success, res=' , response);
                  $scope.contentLoading = false;

                  $scope.data.table_api = response;

                  var table_arr = [];
                  _.each(response.data, function(entry) {
                    var obj = {selected: false, entry: entry};
                    table_arr.push(obj);
                  });

                  $scope.data.table_inner = table_arr;
                });
      };

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };


      $scope.syncNow = function() {
          var req = {
              method: 'GET',
              url: API.url + 'action/SyncNowAsync'
          };
            return $http(req)
              .then(function success(response) {
              $log.info('syncNow inside unblock');
              });
      }


      $scope.deleteSelection = function() {
        //convert back to string array
        var push_arr = [];
        _.each($scope.data.table_selected_records, function(record) {
          push_arr.push(record.entry);
        });
        var req = {
          method: 'DELETE',
          data: push_arr,
          url: API.url + 'cbusage/unlockrequests',
        };
        $http(req)
                .then(function success() {
                    $scope.reload();
                }, function error() {
                  $scope.reload();
                });
      };

      $scope.allowSelection = function() {
        //convert back to string array
        $log.info('Allowed ENTER');
        var push_arr = [];
        _.each($scope.data.table_selected_records, function(record) {
          push_arr.push(record.entry);
        });
        var req = {
          method: 'POST',
          data: push_arr,
          url: API.url + 'cbusage/unlock',
        };
        $http(req)
                .then(function success() {
                  $log.info('Allowed after response');
                  //scgSharedFactory.needsSync = true;
                  $scope.syncNow();
                  $scope.reload();
                }, function error() {
                  $scope.reload();
                });
      };

      $scope.contentLoading = true;

      $scope.getRequest();
      $log.info('WELCOME UNBLOCK');

    });

