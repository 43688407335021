'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CB-Usage-Reports-V2', function(
        $state,
        $scope,
        $translate,
        $location,
        $anchorScroll,
        $http,
        _,
        moment,
        html2canvas,
        API,
        jsPDF,
        Metronic,
        $log,
        $q,
        $cookies,
        cbUsageFactory,
        cbListsFactory,
        DeviceFactory,
        AuthFactory,
        scgSharedFactory,
        modalFactory,
        scgGlobalSpinnerFactory,
        ccUsageFactory, //used for age lookup
        cbDashboardFactory) {

        $scope.data = {};
        $scope.dataTimes = {};
        $scope.dataHistory = {};
        $scope.weekstats = {};
        $scope.weekstatsPeriod = 7;
        $scope.loadingWeekStats=false;
        $scope.dataLimitinfo = {};
        $scope.dataSettings = {};
        $scope.myTestVar = "3";
        $scope.serverTimeDiff =0;
        $scope.hintHide = true;
        $scope.showLong = false;
        $scope.period = 'week'; //default
        $scope.howMany = '25';
        $scope.isCustomPeriod = false;
        $scope.customStartDate = '2010-01-01';
        $scope.customStopDate = '2012-01-01';
        $scope.howManyUnlockRequests = -1;
        $scope.scgSharedFactory = scgSharedFactory;
        $scope.hasNoData = false;
        $scope.age = {};
        $scope.isAgeEdit = false;
        $scope.perioddropdown = '1';
        $scope.hasRoaming = false;
        $scope.isReport = false;
        $scope.isReport = $cookies.get('role') == 'report' ? true : false;
        $scope.showLimitinfo = false;
        $scope.limitInfoDone = false;
        $scope.isCB3 = false;

        $scope.totalTime = 0;
        $scope.totalExtension = 0;
        $scope.loadingTimes = false;
        $scope.loadingHistory = false;

        $scope.formatSecs = scgSharedFactory.formatSecs;

        $scope.preselectedWeekday = $state.params.weekday;
        $scope.loadingUsage = false;
        $scope.scgSharedFactory = scgSharedFactory;

        //Dierk: use clause for underscore
        $scope._ = _;

        //Reload entire page page-reload
        $scope.myreload = function() {
            $log.info("state go A");
            $state.go($state.current, {}, {reload: true});
        };

        $scope.getStartDate = function() {
            var date = new Date();
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            mymonth = mymonth < 10 ? '0' + mymonth : mymonth;
            var myday = date.getDate();
            myday = myday < 10 ? '0' + myday : myday;
            return myyear + '-' + mymonth + '-' + myday;
        };

        $scope.getStopDate = function() {
            var date = new Date();
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            mymonth = mymonth < 10 ? '0' + mymonth : mymonth;
            var myday = date.getDate();
            myday = myday < 10 ? '0' + myday : myday;
            return myyear + '-' + mymonth + '-' + myday;
        };

        $scope.startDate = $scope.getStartDate();
        $scope.stopDate = $scope.getStopDate();
        $scope.customStartDate = $scope.startDate;
        $scope.customStopDate = $scope.stopDate;

        $scope.$watch('customStopDate', function(nv) {
            $log.info('customStopDate', nv);
        });

        $scope.onTimeSetStart = function(newDate) {
            $scope.customStartDate = moment(newDate).format('YYYY-MM-DD');
        };

        $scope.onTimeSetStop = function(newDate) {
            $scope.customStopDate = moment(newDate).format('YYYY-MM-DD');
        };


        $log.info('DEFAULTS SET!');

        $scope.myShortDate = function(longdate) {
            var ret = '';
            if (longdate.length > 16) {
                ret = longdate.substr(0,16);
            }else {
                ret = longdate;
            }
            return ret;
        };

        $scope.showAgeModal = function(entry) {
            $scope.ageEntry = entry;
            $log.info('showAgeModal with entry=' + entry);
            cbUsageFactory.getAgeDetail(entry)
                .then(function success(response) {
                    if (response.data.Category == '') {
                        response.data.Category = $translate.instant('MISC.NODATA');
                    }
                    if (response.data.Description == '') {
                        response.data.Description = $translate.instant('MISC.NODATA');
                    }
                    $scope.age = response.data;
                    $log.info('age.description=' + $scope.age.description);
                    $log.info('SUCCESS getAGEDETAIL');
                });

        };

        $scope.isIOS = false;
        $log.info('CB-Usage-Reports: Active Device');
        $log.info(AuthFactory.activeDevice);

        DeviceFactory.getDeviceById(AuthFactory.activeDevice.deviceId).then(
            function success(device) {
                $log.info('CB-Usage-Reports: IOS Check');
                $log.info(device);
                if (device.deviceType === 11) {
                    $log.info('CB-Usage-Reports: IOS Check : is IOS Device');
                    $scope.isIOS = true;
                }
            }
        );
        // ---------------------------------------------------------------------------------


        $scope.editAge = function() {
            $log.info('EDIT AGE!');
            $scope.isAgeEdit = true;
        };

        $scope.showAge = function() {
            $scope.isAgeEdit = false;
        };

        $scope.onEditModalReturn = function() {
            $log.info('onEditModalReturn');
        };

        $scope.usageEdit = function(entry, context) {

            $log.info('usageEdit entry=' + entry);
            $log.info('usageEdit context=' + context);

            //Special case Chico Browser -> Redirect time limit page
            if (entry == 'Chico Browser') {
                $log.info("state go B");
                $state.go('cb-browsertime');
                return;
            }

            var modalSettings = {
                mode: 'add',
                context: context,
                title: $translate.instant('HEADER.LIMITENTRY'),
                newRecord: null, //to be overridden later !!!
                editRecords: null, //to be overridden later !!!
                refreshStateAfterClose: true
            };

            var kind = 1;
            if (context == 'net') {
                kind = 7;
                entry = scgSharedFactory.getTLD(entry);
                modalSettings.mode = 'add';
                modalSettings.newRecord = {
                    kind: kind,
                    texts: [entry]
                };
                modalSettings.editRecords = null;
            }else {
                //entry = entry.replace("/", "");
                modalSettings.mode = 'edit';
                modalSettings.newRecord = null;
                modalSettings.editRecords = [{data: {entry: entry}}];
            }

            $log.info('usageEdit modalSettings=' , modalSettings);

            modalFactory.editModal.open({
                modal_settings: modalSettings,
                callbacks: {
                    ok: $scope.onEditModalReturn,
                    cancel: $scope.onEditModalReturn
                }
            });
        };

        $scope.startOfWeek = function() {
            var date = new Date();
            var now = date ? new Date(date) : new Date();
            // set time to some convenient value
            now.setHours(0,0,0,0);
            // Get the previous Monday
            var monday = new Date(now);
            monday.setDate(monday.getDate() - monday.getDay() + 1);
            if (monday.getDay() ==  1) {
                monday.setDate(monday.getDate() - 7);
            } //because SUN 1st day of week and we need MON
            $log.info('monday.getDay()' + monday.getDay());

            //forget all this stuff and use moment:
            //var monday=moment().startOf('isoweek').format('YYYY-MM-DD');
            //notice: moment stuff is done in caller routine
            return monday;
        };

        $scope.makeURL = function(url) {
            return 'http://' + url;
        };

        $scope.filterKind1 = function(item) {
            return item.Kind == 1 || item.Kind == 10;
        };

        $scope.filterKindDevice = function(item) {
            return item.Kind == 2;
        };

        $scope.filterKindGroups = function(item) {
            return item.Kind == 111 || item.Kind == 112 || item.Kind == 113|| item.Kind == 114|| item.Kind == 115;
        }

        $scope.filterKind4 = function(item) {
            return item.Kind == 4;
        };

        $scope.filterKind7 = function(item) {
            return item.Kind == 6 ||  item.Kind == 7 || item.Kind == 8;   //6 = sharedLog!
        };

        $scope.filterKind15 = function(item) {
            return item.Kind == 15;
        };

        $scope.filterKind22 = function(item) {
            return item.Kind == 22;
        };

        //Filterkind50 is counted extension for CB3
        $scope.filterKind50 = function(item) {
            return item.Kind == 50;
        };


        $scope.displayDay = function() {
            $scope.period = 'day';
            $scope.createUsage();
        };

        $scope.displayWeek = function() {
            $scope.period = 'week';
            $scope.createUsage();
        };

        $scope.displayMonth = function() {
            $scope.period = 'day';
            $scope.createUsage();
        };

        $scope.deleteUsage = function() {
            $log.info('delete goes here');
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SUREDELETE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
                },
                callbacks: {
                    ok: function() {
                        $log.info('DELPROC yes, ok was click, he/she is sure');
                        $scope.data = {};
                        scgGlobalSpinnerFactory.blockUI();
                        cbUsageFactory.deleteUsage()
                            .then(function success(response) {
                                $log.info('DELPROC HAS DATA 200, response.data=' + response.data);
                                if (response.data != null) {
                                    modalFactory.okModal(1, $translate.instant('MSG.DELETECBDONE'));
                                    cbUsageFactory.delUsageAction();
                                    Metronic.unblockUI();
                                    $log.info("state go C");
                                    $state.go('cb-dashboard');
                                }

                            }, function error() {
                                Metronic.unblockUI();
                                $log.info('ERROR OCCURED');
                                modalFactory.okModal(1, $translate.instant('MSG.ERROR')  + ' ' + $translate.instant('MSG.ERRORHINT2'));
                            });

                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        };



        //----------------------------------------------
        $scope.createUsage = function() {

            $log.info("perioddropdown=" , $scope.perioddropdown);
            $scope.totalTime = 0;
            $scope.totalExtension = 0;

            //First: Are there unlock requests?
            cbDashboardFactory.getUnlockRequests()
                .then(function success(response) {
                    $scope.howManyUnlockRequests = response.data.length;
                });

            $log.info("perioddropdown=" , $scope.perioddropdown);

            //launch custom dates
            //----------------------------------------------------------------
            if ($scope.perioddropdown === '7') {
                $log.info('CUSTOMDATE - Start:' + $scope.customStartDate);
                $log.info('CUSTOMDATE - Stop:' + $scope.customStopDate);
                $scope.loadingUsage = true;
                cbUsageFactory.getUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                    .then(function success(response) {
                        $scope.data = response.data;
                        $scope.hasNoData = false;
                        if (response.data.logs.length == 0 && response.data.history.length == 0) {
                            $scope.hasNoData = true;
                        }

                        //Iteration for totalTime
                        _.forEach($scope.data.logs, function(dataTime) {
                            if (dataTime && dataTime.Kind && dataTime.Kind === 2) {
                                $scope.totalTime = $scope.totalTime + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                            }
                            if (dataTime && dataTime.Kind && dataTime.Kind === 50) {
                                $scope.totalExtension = $scope.totalExtension + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                            }
                        });

                        $scope.loadingUsage = false;
                    });
                return;
            }
            //calculate the date, launch intervals from dropdown
            //----------------------------------------------------------------
            var someDate = new Date();
            if ($scope.perioddropdown === '1') {
                someDate.setDate(someDate.getDate()); //today
            }
            if ($scope.perioddropdown === '2') {
                someDate.setDate(someDate.getDate() - 1); //yesterday
            }
            if ($scope.perioddropdown === '3') {
                someDate.setDate(someDate.getDate() - 6);
            }
            if ($scope.perioddropdown === '4') {
                someDate.setDate(someDate.getDate() - 30);
            }
            if ($scope.perioddropdown === '5') {
                someDate = $scope.startOfWeek();
            }

            //Format the bullshit date
            var dd = someDate.getDate();
            if (dd <= 9) {
                dd = '0' + dd;
            }
            if ($scope.perioddropdown === '6') {
                dd = '01';     //start of month
            }
            var mm = someDate.getMonth() + 1;
            if (mm <= 9) {
                mm = '0' + mm;
            }
            var y = someDate.getFullYear();
            $scope.someFormattedDate = y + '-' + mm + '-' + dd;
            $scope.startDate = $scope.someFormattedDate;

            if ($scope.perioddropdown === '1' || $scope.perioddropdown === '2') {
                $log.info('createUsage Period=1 OR 2     stopdate=' + $scope.stopDate);
                $scope.stopDate = $scope.startDate; //only if yesterday
            }

            //Override start for this week using moment
            if ($scope.perioddropdown === '5') {
                $scope.startDate = moment().startOf('isoweek').format('YYYY-MM-DD');
            }

            $log.info('createUsage -> perioddropdown=' + $scope.perioddropdown);
            $log.info('createUsage -> startDate=' + $scope.startDate);
            $log.info('createUsage -> stopDate=' + $scope.stopDate);
            $log.info('createUsage -> howMany=' + $scope.howMany);

            $scope.loadingUsage = true;

            //THIS IS NEW APPROACH FOR SPLITTED TIMES / HISTORY -> NOT ACTIVE YET, NO MARKUP YET !!!
            //--------------------------------------------------------------------------------------
            /*$scope.loadingTimes = true;
            $scope.loadingHistory = true;
            cbUsageFactory.getUsageTimes($scope.startDate,$scope.stopDate)
                .then(function success(response) {
                    $scope.dataTimes = response.data;
                    $log.info('USAGE TIMES ARRIVED', response.data);

                    _.forEach($scope.dataTimes.logs, function(dataTime) {
                        $log.info('dataTime=', dataTime);
                        if (dataTime && dataTime.Kind && dataTime.Kind === 2) {
                        $log.info('totalTime to add:' + dataTime.Period);
                            $scope.totalTime = dataTime.Period;
                        }
                        if (dataTime && dataTime.Kind && dataTime.Kind === 50) {$scope.totalExtension = dataTime.Period;}
                    });
                    getAgeValues(response.data.logs);   //necessary for green,yellow,red gear icons on the right
                    $scope.loadingUsage = false;
                });

            cbUsageFactory.getUsageHistory($scope.startDate,$scope.stopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.dataHistory = response.data;
                    $log.info('USAGE HISTORY ARRIVED', response.data);
                    $scope.loadingHistory = false;
                });
                */


            cbUsageFactory.getUsageByDates($scope.startDate,$scope.stopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    getAgeValues(response.data.logs);   //this is called async (method below)!
                    $log.info('USAGE ARRIVED', response.data);
                    $scope.hasNoData = false;
                    if (response.data.logs.length == 0 &&
                        response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }

                    //Iteration for totalTime
                    _.forEach($scope.data.logs, function(dataTime) {
                        if (dataTime && dataTime.Kind && dataTime.Kind === 2) {
                            $scope.totalTime = $scope.totalTime + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                        if (dataTime && dataTime.Kind && dataTime.Kind === 50) {
                            $scope.totalExtension = $scope.totalExtension + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                    });

                    $scope.loadingUsage = false;
                });


        };//----------------------------------------------

        function getAgeValues(logs) {
            var entriesPostArr = [];
            _.forEach(logs, function(val) {
                entriesPostArr.push({entry: val.Entry, kind:val.Kind});
            });

            scgSharedFactory.getAgesByEntries(entriesPostArr)
                .then(function success(response) {
                    var ageEntries = response.data;
                    _.forEach(logs, function(logsEntry) {
                        _.forEach(ageEntries, function(ageEntry) {
                            if (ageEntry.entry === logsEntry.Entry) {
                                logsEntry.ageInfo = ageEntry;
                            }
                        });
                    });
                });

           cbUsageFactory.getLimitEntriesInfo(entriesPostArr)

                .then(function success(response) {
                    var hasLimitEntries = response.data;
                    _.forEach(logs, function(logsEntry) {
                        _.forEach(hasLimitEntries, function(limitEntry) {
                            if (limitEntry.entry === logsEntry.Entry) {
                                logsEntry.limitInfo = limitEntry;
                            }
                        });
                    });
                    $scope.limitInfoDone = true;
                });

        }

        $scope.showUnlock = function() {
            $log.info("state go D");
            $state.go('cb-usage.unblock');
        };


        $scope.pdfExportServer = function () {
            $log.info('PDF Export from Backend!');
            var pdfurl = API.url + 'cbusage/bydatesall/' + $scope.startDate + '/' + $scope.stopDate + '/' + $scope.howMany + '?format=pdf';
            $log.info('PDF url:' + pdfurl);
            scgGlobalSpinnerFactory.blockUI();
            $http({
                url : pdfurl,
                method : 'GET',
                params : {},
                headers : {
                    'Content-type' : 'application/pdf',
                },
                responseType : 'arraybuffer'
            }).success(function(data, status, headers, config) {
                // TODO when WS success
                $log.info('PDF success');
                scgGlobalSpinnerFactory.unblockUI();

                if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/)) || $.browser.msie == 1)
                {
                    var fileIE = new Blob(([data]), { type: 'application/pdf' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(fileIE, 'report.pdf');
                    }
                } else {
                    var file = new Blob([data], {
                        type: 'application/csv'
                    });
                    //trick to download store a file having its URL
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = 'report.pdf';
                    document.body.appendChild(a);
                    a.click();
                }
            }).error(function(data, status, headers, config) {
                //TODO when WS error
                scgGlobalSpinnerFactory.unblockUI();
            });
        }



        cbUsageFactory.hasRoaming().
        then(function success(response) {
            $log.info('hasRoaming:',response);
            $scope.hasRoaming = response.data.hasRoaming;
        });



        $scope.showBadge = function(listKind) {
            var str = '';
            //$log.info('Badge for listKind:' , listKind);
            if (listKind == 2) {
                str = $translate.instant('TABS.TIMELIMITED');
            }
            if (listKind == 11) {
                str = 'Gr.1';
            }
            if (listKind == 12) {
                str = 'Gr.2';
            }
            if (listKind == 13) {
                str = 'Gr.3';
            }
            if (listKind == 14) {
                str = 'Gr.4';
            }
            if (listKind == 15) {
                str = 'Gr.5';
            }
            return str;
        };

        $scope.calculateHideSome=function() {
            $scope.hideUsageSome= false;
            if ($scope.dataSettings.hideUsageRuntimePrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageRuntimeWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryPrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryBlocked==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistorySystem==true) {$scope.hideUsageSome=true;}
            var hideAny_object = {"hideUsageAny":$scope.hideUsageSome};
            if (!$scope.isReport) {cbUsageFactory.setUsageSettings(hideAny_object);}
        }


        $scope.getUsageSettings = function() {
            cbUsageFactory.getUsageSettings()
                .then(function success(response) {
                    $scope.dataSettings = response.data;
                    $scope.calculateHideSome();
                    $log.info('usage get settings!', $scope.dataSettings);

                    //add watchers, important context is here dataSettings!
                    //---------------------------------------------------------
                    _.each($scope.dataSettings, function(v, k) {
                        $scope.$watch('dataSettings.' + k, function(new_val, old_val) {
                            if (new_val != old_val) {
                                var changed_object = {};
                                changed_object[k] = new_val;
                                $log.info('usage changed settings!',k);
                                scgSharedFactory.needsSync = true;
                                $scope.calculateHideSome();
                                cbUsageFactory.setUsageSettings(changed_object);
                            }
                        }, true);
                    });
                });
        }


        $scope.limitClick = function(limitEntry,isDeviceLimit) {


            $log.info('LimitEntry object:', limitEntry);
            $log.info('LimitEntry isDeviceLimit:', isDeviceLimit);

            var entry = limitEntry.limitInfo.entry;
            var kind = limitEntry.limitInfo.kind;
            var hasLimit = limitEntry.limitInfo.hasLimit;
            var listKind = limitEntry.listKind;

            if (limitEntry.Kind>110 && limitEntry.Kind<116)
            {
                kind=limitEntry.Kind-100;
            }


            $log.info('LimitClickCB for kind=' + kind + ' and entry ' + entry + ' and hasLimit=' + hasLimit);


            //if (kind==2) {entry="CHICOBROWSER";}
            //fix for cb3 groups (111-115)
            if (kind>110 && kind<116)
            {
                kind=kind-100;
            }

            if( $scope.isReport === true ){

                scgGlobalSpinnerFactory.blockUI();
                $log.info(' -------- This is REPORT VIEW --------------------------- ');

                if (kind<10 || kind>15) {
                    cbUsageFactory.getLimitinfo(entry)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes == undefined || $scope.dataLimitinfo.blockedTimes === scgSharedFactory.blockOff()) {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes = undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo = true;
                            },
                            function error(response) {
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo = false;
                            }
                        )
                } else {
                    //we have to lookup kind instead of entry
                    cbUsageFactory.getLimitinfoByKind(kind)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes==undefined || $scope.dataLimitinfo.blockedTimes===scgSharedFactory.blockOff())
                                {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes=undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo=true;
                            },
                            function error(response) {
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo=false;
                            }
                        )

                }

            }else {

                $log.info(' -------- This is view for PARENTS --------------------------- ');

                if (isDeviceLimit===true) {
                    $log.info("state go E");
                    $state.go('cbtime.devicetime');
                    return;
                }
                if (entry=="Chico Browser") {
                    $log.info("state go F");
                    $state.go('cbtime.browsertime');
                    return;
                }

                var modal_settings = {
                    mode: 'edit',
                    //context: 'prg' or 'net',
                    title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                    //newRecord: null,
                    //editRecords: editRecords,
                    refreshStateAfterClose: false
                };

                if (kind==5 || kind==6 || kind==7 || kind==8) {
                    modal_settings.context = 'net';
                } else {
                    modal_settings.context = 'prg';
                }

                if( (kind >= 1 && kind <= 4) || (hasLimit===true && (listKind < 10 || listKind > 15))){
                    //Normal edit modal
                    var editRecords = [];
                    var obj = {data: {'entry': entry}};
                    editRecords.push(obj);

                    modal_settings.newRecord = null;
                    modal_settings.editRecords = editRecords;
                    modal_settings.mode = 'edit';

                    openEditModal(modal_settings);

                }else if(listKind >=10 && listKind <=15){

                    //handle group edit (load multimple entries into edit modal)
                    cbListsFactory.getDetailedByListFilter(listKind)
                        .then(function success(response) {
                            var groupEntries = response.data;

                            var editRecords = [];

                            _.each(groupEntries, function(groupEntry){
                                var obj = {data: {'entry': groupEntry.entry}};
                                editRecords.push(obj);
                            });

                            modal_settings.newRecord = null;
                            modal_settings.editRecords = editRecords;
                            modal_settings.mode = 'edit';

                            openEditModal(modal_settings);
                        });

                } else {
                    modal_settings.editRecords = null;
                    modal_settings.newRecord = {
                        kind: kind,
                        texts: [entry]
                    };
                    modal_settings.mode = 'add';

                    openEditModal(modal_settings);
                }

            }

        }

        function openEditModal(modalSettings){
            //open edit modal for this entry
            modalFactory.editModal.open({
                modal_settings: modalSettings,
                callbacks: {
                    ok: function() {},
                    cancel: function() {}
                }
            });
        }

        $scope.logout = function() {
            $log.info("state go E");
            $state.go('login-logoff-success');
        }

        $scope.exitLimitinfo = function() {
            $scope.showLimitinfo=false;
        }



        $scope.scrollToUsageSettings = function() {
            $location.hash('UsageSettings');
            $anchorScroll();
        }


        scgSharedFactory.getServerTimeDiff().then(function(funcresult) {
            $scope.serverTimeDiff = funcresult;
        });


        $scope.listKindToString = function(kind) {
            return "XXX";
        }


        //Watcher for CB3 (necessary if page reload button has been hit)
        $scope.$watch(function() {
            return AuthFactory.activeDevice;
        }, function(activeDevice) {
            if (AuthFactory.activeDevice.deviceType==30) {
                $scope.isCB3=true;
            } else {
                $scope.isCB3=false;
            }
        },true);


        $scope.getWeekStats = function(period) {
            $scope.loadingWeekStats=true;
            cbUsageFactory.getWeekStats(period)
                .then(function success(response) {
                    $scope.weekstats = response.data;
                    $scope.weekstatsPeriod = period;
                    $scope.weekstatsPercentage = $scope.getPercentageFromStats(response.data);
                    $log.info('Weekstats=', $scope.weekstats);
                    $scope.loadingWeekStats=false;
                });
            return;
        }



        $scope.showDayByWeekStats= function(azdate) {
            $scope.customStartDate = azdate;
            $scope.customStopDate = azdate;
            $scope.perioddropdown = "7"; //custom period
            scgGlobalSpinnerFactory.blockUI();
            $scope.loadingUsage = true;
            cbUsageFactory.getUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.hasNoData = false;
                    if (response.data.logs.length == 0 && response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }
                    $scope.loadingUsage = false;
                    scgGlobalSpinnerFactory.unblockUI();
                });
         }


        $scope.getPercentageFromStats = function(weekstats){
            var result = [];
            var total = 0;
            var highestVal = 0;
            var singleVal = 0;

            _.forEach(weekstats, function(obj,index){
                total += obj.seconds;
                if (obj.seconds>highestVal) {highestVal=obj.seconds};
            })
            _.forEach(weekstats, function(obj,index){
                singleVal = obj.seconds/highestVal*100;
                if (isNaN(singleVal)) {
                    result.push(0)}
                 else {result.push(singleVal);}
            })
            return result;
        }


        if ($scope.preselectedWeekday!=null)
        {
            $scope.customStartDate = $scope.preselectedWeekday;
            $scope.customStopDate = $scope.preselectedWeekday;
            $scope.perioddropdown = "7"; //custom period
            scgGlobalSpinnerFactory.blockUI();
            $scope.loadingUsage = true;
            cbUsageFactory.getUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.hasNoData = false;
                    if (response.data.logs.length == 0 && response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }
                    $scope.loadingUsage = false;
                    scgGlobalSpinnerFactory.unblockUI();
                });
        }



        //Default Calls
        //-----------------------------------------------
        $scope.displayDay();                //Attention: if you change, change also perioddrodown value init at top!
        $scope.getUsageSettings();         //get only the settings (last section)

        $scope.getWeekStats($scope.weekstatsPeriod);



    });

