'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CC-Usage-Reports', function(
        $scope,
        $state,
        $window,
        $translate,
        $http,
        $location,
        $injector,
        $cookieStore,
        $anchorScroll,
        ccUsageFactory,
        ccListsFactory,
        cbUsageFactory, //necessary for AgeModals!
        scgSharedFactory,
        AuthFactory,
        sseFactory,
        html2canvas,
        jsPDF,
        _,
        Metronic,
        moment,
        $log,
        $cookies,
        modalFactory,
        apiFunctions,

        scgGlobalSpinnerFactory) {

        $scope.data = {};
        $scope.dataSettings = {};
        $scope.weekstats = {};
        $scope.weekstatsPeriod = 7;
        $scope.loadingWeekStats=false;
        $scope.hideUsageSome= false;
        $scope.serverTimeDiff = 0;
        $scope.hintHide = true;
        $scope.showLong = false;
        $scope.period = 'week'; //default
        $scope.howMany = '25';
        $scope.isCustomPeriod = false;
        $scope.customStartDate = '2010-01-01';
        $scope.customStopDate = '2012-01-01';
        $scope.howManyUnlockRequests = -1;
        $scope.scgSharedFactory = scgSharedFactory;
        $scope.hasNoData = false;
        $scope.age = {};
        $scope.isAgeEdit = false;
        $scope.perioddropdown = '1';
        $scope.showDayHistory = false;
        $scope.showUnlockInfo = false;
        $scope.isReport = false;
        $scope.isReport = $cookies.get('role') == 'report' ? true : false;
        $scope.showLimitinfo = false;
        $scope.limitInfoDone = false;

        $scope.formatSecs = scgSharedFactory.formatSecs;

        $scope.preselectedWeekday = $state.params.weekday;
        $scope.loadingUsage = false;

        $scope.totalTime = 0;
        $scope.totalExtension = 0;
        $scope.scgSharedFactory = scgSharedFactory;

        $scope.hasRoamingGroupData = false;

        //Dierk: use clause for underscore
        $scope._ = _;

        //Reload entire page page-reload
        $scope.myreload = function() {
            $state.go($state.current, {}, {reload: true});
        };

        $scope.gotoUnblock = function() {
            $state.go('cc-usage.unblock');
        };

        $scope.getStartDate = function() {
            var date = new Date();
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            mymonth = mymonth < 10 ? '0' + mymonth : mymonth;
            var myday = date.getDate();
            myday = myday < 10 ? '0' + myday : myday;
            return myyear + '-' + mymonth + '-' + myday;
        };

        $scope.getStopDate = function() {
            var date = new Date();
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            mymonth = mymonth < 10 ? '0' + mymonth : mymonth;
            var myday = date.getDate();
            myday = myday < 10 ? '0' + myday : myday;
            return myyear + '-' + mymonth + '-' + myday;
        };

        $scope.startDate = $scope.getStartDate();
        $scope.stopDate = $scope.getStopDate();
        $scope.customStartDate = $scope.startDate;
        $scope.customStopDate = $scope.stopDate;

        $scope.$watch('customStopDate', function(nv) {
            $log.info('customStopDate', nv);
        });

        $scope.onTimeSetStart = function(newDate) {
            $scope.customStartDate = moment(newDate).format('YYYY-MM-DD');
        };

        $scope.onTimeSetStop = function(newDate) {
            if (moment(newDate).isBefore($scope.customStartDate)) {
                $scope.customStartDate = $scope.customStopDate;
            }else {
                $scope.customStopDate = moment(newDate).format('YYYY-MM-DD');
            }
        };

        $log.info('DEFAULTS SET!');

        $scope.myShortDate = function(longdate) {
            var ret = '';
            if (longdate.length > 16) {
                ret = longdate.substr(0,16);
            }else {
                ret = longdate;
            }
            return ret;
        };

        $scope.showAgeModal = function(entry) {
            $scope.ageEntry = entry;
            $log.info('showAgeModal with entry=' + entry);
            cbUsageFactory.getAgeDetail(entry)
                .then(function success(response) {
                    if (response.data.Category == '') {
                        response.data.Category = $translate.instant('MISC.NODATA');
                    }
                    if (response.data.Description == '') {
                        response.data.Description = $translate.instant('MISC.NODATA');
                    }
                    $scope.age = response.data;
                    $log.info('age.description=' + $scope.age.description);
                    $log.info('SUCCESS getAGEDETAIL');
                });

        };

        $scope.editAge = function() {
            $log.info('EDIT AGE!');
            $scope.isAgeEdit = true;
        };

        $scope.showAge = function() {
            $scope.isAgeEdit = false;
        };

        $scope.onEditModalReturn = function() {
            $log.info('onEditModalReturn');
        };

        //get records for group internet, used in overview "Internet"
        $scope.loadInetPrgrams = function() {
            $log.info("loadInetPrgrams");
            ccListsFactory.getByListFilter(ccListsFactory.listKinds.list_kind_group_internet)
                .then(function success(response) {
                    var editRecords = [];
                    _.each(response.data, function (v) {
                        editRecords.push({data: {entry: v}});
                    });
                    if (editRecords && editRecords.length < 1) {
                        modalFactory.infoModal.open({
                            modal_settings: {
                                title: $translate.instant('HEADER.INFO'),
                                message: $translate.instant('MSG.NO_INETPROGRAMS'),
                                butOkLabel: $translate.instant('BUTTON.OK'),
                                butCancelLabel: null,
                                iconNo: 3,
                                refreshStateAfterClose: true
                            },
                            callbacks: {
                                ok: function () {
                                    $log.info('modal closed with ok -> callback');
                                }
                            }
                        });
                    } else {
                        modalFactory.editModal.open({
                            modal_settings: {
                                mode: 'edit',
                                context: 'prg',
                                title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                                newRecord: null,
                                editRecords: editRecords,
                                refreshStateAfterClose: true
                            },
                            callbacks: {
                                ok: function () {
                                },
                                cancel: function () {
                                }
                            }
                        });
                    }
                });
        }



        $scope.startOfWeek = function() {
            var date = new Date();
            var now = date ? new Date(date) : new Date();
            // set time to some convenient value
            now.setHours(0,0,0,0);
            // Get the previous Monday
            var monday = new Date(now);
            monday.setDate(monday.getDate() - monday.getDay() + 1);
            if (monday.getDay() ==  1) {
                monday.setDate(monday.getDate() - 7); //because SUN 1st day of week and we need MON
            }
            $log.info('monday.getDay()' + monday.getDay());

            //forget all this stuff and use moment:
            //var monday=moment().startOf('isoweek').format('YYYY-MM-DD');
            //notice: moment stuff is done in caller routine
            return monday;
        };

        $scope.makeURL = function(url) {
            return 'http://' + url;
        };

        $scope.filterKind1 = function(item) {
            return item.Kind == 1 || item.Kind== 7;
        };

        $scope.imgDest = function(exename) {
            return 'chrome.png';
        }


        $scope.filterKind2 = function(item) {
            return item.Kind == 2;
        };

        $scope.filterKind4 = function(item) {
            return item.Kind == 4;
        };

        $scope.filterKind5 = function(item) {
            return item.Kind == 5; //extension times
        };

        $scope.filterKind6 = function(item) {
            return item.Kind == 6; //sharedLog
        };

        $scope.filterKind7 = function(item) {
            return item.Kind == 7 || item.Kind == 8;
        };

        $scope.filterKind10 = function(item) {
            return item.Kind == 10; //sharedLog
        };


        $scope.filterKindGroups = function(item) {
            return item.Kind == 11 || item.Kind == 12  || item.Kind == 13  || item.Kind == 14 || item.Kind == 15;
        };

        $scope.filterKind22 = function(item) {
            return item.Kind == 22;
        };

        $scope.displayDay = function() {
            $scope.period = 'day';
            $scope.myRefresh();
        };

        $scope.displayWeek = function() {
            $scope.period = 'week';
            $scope.myRefresh();
        };

        $scope.displayMonth = function() {
            $scope.period = 'day';
            $scope.myRefresh();
        };

        $scope.deleteUsage = function() {
            $log.info('delete goes here');
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SUREDELETEUSER') + '<br><br>' + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
                },
                callbacks: {
                    ok: function() {
                        $log.info('yes, ok was click, he/she is sure');
                        ccUsageFactory.deleteUsage()
                            .then(function success(response) {
                                $log.info('HAS DATA 200, response.data=' + response.data);
                                if (response.data != null) {
                                    sseFactory.isOnline()
                                        .then(function success() {
                                            $log.info('YES WE ARE ALSO ONLINE');
                                            sseFactory.sync()
                                                .then(function success() {
                                                    $log.info('YES DO SYNC ALSO SUCCEEDED');
                                                });
                                        });

                                    modalFactory.okModal(1, $translate.instant('MSG.DELETECCDONE'));
                                    $state.go('cc-dashboard-v3');
                                }

                            }, function error() {

                                modalFactory.okModal(1, $translate.instant('MSG.ERROR') + ' ' + $translate.instant('MSG.ERRORHINT2'));
                            });
                        $log.info('Ok, all done');
                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        };

        //----------------------------------------------
        $scope.myRefresh = function() {

            $log.info('original perioddropdown=' + $scope.perioddropdown);
            $scope.totalTime = 0;
            $scope.totalExtension = 0;

            //launch custom dates
            //----------------------------------------------------------------
            if ($scope.perioddropdown === '7') {
                $log.info('CUSTOMDATE - Start:' + $scope.customStartDate);
                $log.info('CUSTOMDATE - Stop:' + $scope.customStopDate);
                $scope.showDayHistory = true;

                $scope.loadingUsage = true;
                ccUsageFactory.getUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                    .then(function success(response) {
                        $scope.data = response.data;
                        $scope.hasNoData = false;
                        //OLD: if (response.data.logs.length==0 && response.data.history.length==0) $scope.hasNoData=true;
                        if (response.data.history.length == 0) {
                            $scope.hasNoData = true;
                        }

                        //Iteration for totalTime
                        _.forEach($scope.data.logs, function(dataTime) {
                            if (dataTime && dataTime.Kind && dataTime.Kind === 1) {
                                $scope.totalTime = $scope.totalTime + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                            }
                            if (dataTime && dataTime.Kind && dataTime.Kind === 5) {
                                $scope.totalExtension = $scope.totalExtension + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                            }
                        });

                        $scope.loadingUsage = false;

                    });
                return;
            }
            //calculate the date, launch intervals from dropdown
            //----------------------------------------------------------------
            var someDate = new Date();
            if ($scope.perioddropdown === '1') {
                someDate.setDate(someDate.getDate()); //today
                $scope.showDayHistory = false;
            }
            if ($scope.perioddropdown === '2') {
                someDate.setDate(someDate.getDate() - 1); //yesterday
                $scope.showDayHistory = false;
            }
            if ($scope.perioddropdown === '3') {
                someDate.setDate(someDate.getDate() - 6);
                $scope.showDayHistory = true;
            }
            if ($scope.perioddropdown === '4') {
                someDate.setDate(someDate.getDate() - 30);
                $scope.showDayHistory = true;
            }
            if ($scope.perioddropdown === '5') {
                someDate = $scope.startOfWeek();
                $scope.showDayHistory = true;
            }

            //Format the bullshit date
            var dd = someDate.getDate();
            if (dd <= 9) {
                dd = '0' + dd;
            }
            if ($scope.perioddropdown === '6') {
                dd = '01';     //start of month
            }
            var mm = someDate.getMonth() + 1;
            if (mm <= 9) {
                mm = '0' + mm;
            }
            var y = someDate.getFullYear();
            $scope.someFormattedDate = y + '-' + mm + '-' + dd;
            $scope.startDate = $scope.someFormattedDate;

            if ($scope.perioddropdown === '1' || $scope.perioddropdown === '2') {
                $log.info('myRefresh Period=1 OR 2     stopdate=' + $scope.stopDate);
                $scope.stopDate = $scope.startDate; //only if yesterday
            }

            //Override start for this week using moment
            if ($scope.perioddropdown === '5') {
                $scope.startDate = moment().startOf('isoweek').format('YYYY-MM-DD');
            }

            $log.info('myRefresh -> perioddropdown=' + $scope.perioddropdown);
            $log.info('myRefresh -> startDate=' + $scope.startDate);
            $log.info('myRefresh -> stopDate=' + $scope.stopDate);
            $log.info('myRefresh -> howMany=' + $scope.howMany);

            $scope.loadingUsage = true;
            ccUsageFactory.getUsageByDates($scope.startDate,$scope.stopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $log.info('USAGE ARRIVED', response.data);
                    $scope.hasNoData = false;
                    getAgeValues(response.data.logs);
                    //OLD: if (response.data.logs.length==0 && response.data.history.length==0) $scope.hasNoData=true;
                    if (response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }
                    //string replace internet and bonus
                    _.forEach(response.data.logs, function(logs) {
                        if (logs.Entry === "- Bonus") {
                            logs.Entry = $translate.instant('USAGE.PART_BONUS');
                        }
                        if (logs.Entry === "- Internet") {
                            logs.Entry = $translate.instant('USAGE.PART_INTERNET');
                        }
                    });

                    //Iteration for totalTime
                    _.forEach($scope.data.logs, function(dataTime) {
                        if (dataTime && dataTime.Kind && dataTime.Kind === 1) {
                            $scope.totalTime = $scope.totalTime + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                        if (dataTime && dataTime.Kind && dataTime.Kind === 5) {
                            $scope.totalExtension = $scope.totalExtension + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                    });
                    $scope.loadingUsage = false;

                });

        };//----------------------------------------------

        function getAgeValues(logs) {
            var entriesPostArr = [];
            _.forEach(logs, function(val) {
                entriesPostArr.push({entry: val.Entry, kind:val.Kind});
            });
            scgSharedFactory.getAgesByEntries(entriesPostArr)
                .then(function success(response) {
                    var ageEntries = response.data;
                    _.forEach(logs, function(logsEntry) {
                        _.forEach(ageEntries, function(ageEntry) {
                            if (ageEntry.entry === logsEntry.Entry) {
                                logsEntry.ageInfo = ageEntry;
                            }
                        });
                    });
                });

            ccUsageFactory.getLimitEntriesInfo(entriesPostArr)
                .then(function success(response) {
                    var hasLimitEntries = response.data;
                    _.forEach(logs, function(logsEntry) {
                        _.forEach(hasLimitEntries, function(limitEntry) {
                            if (limitEntry.entry == "- Internet") {
                                limitEntry.entry = $translate.instant('USAGE.PART_INTERNET');
                                $log.info('limitInfoDone', $scope.limitInfoDone);
                            }
                            if (limitEntry.entry === logsEntry.Entry) {
                                logsEntry.limitInfo = limitEntry;
                            }
                        });
                    });
                    $scope.limitInfoDone = true;
                });
        }

        $scope.showUnlock = function() {
            $state.go('cc-usage.unblock');
        };

        $scope.hasUnlockReqs = function() {
            $log.info('hasUnlockReqs INIT');
            ccUsageFactory.getUnlockReqs()
                .then(function success(response) {
                    $log.info('response.data=', response.data);
                    $log.info('response.data length=', response.data.length);
                    if (response.data && response.data.length > 0) {
                        $scope.showUnlockInfo = true;
                    }
                });
        };

        //Default Calls
        //-----------------------------------------------
        $scope.displayDay();    //Attention: if you change, change also perioddrodown value init at top!
        $scope.hasUnlockReqs();

        //Hint stuff, Add $scope.hintHide at top of page
        //--------------------------------------------------------------------------------
        scgSharedFactory.getHint(60).                                //adjust hintID here
        then(function success(response) {
                if (response.data.hint60) {                         //adjust hintID here
                    $scope.hintHide = response.data.hint60;}        //adjust hintID here
                else {
                    //not assgined, set to false!
                    $scope.hintHide = false;}
            }
        );
        $scope.hintDone = function() {
            $scope.hintHide = true;
            scgSharedFactory.setHint(60).then(function success() {
                    $log.info('HINT DONE SUCCESS');
                }
            );};
        //--------------------------------------------------------------------------------

        $scope.pdfExportServer = function () {
            $log.info('PDF Export from Backend!');
            var pdfurl = apiFunctions.get_cc_base_url() +  'ccusage/bydatesall/' + $scope.startDate + '/' + $scope.stopDate + '/' + $scope.howMany+ '?format=pdf';
            $log.info('PDF url:' + pdfurl);
            $http({
                url : pdfurl,
                method : 'GET',
                params : {},
                headers : {
                    'Content-type' : 'application/pdf',
                },
                responseType : 'arraybuffer'
            }).success(function(data, status, headers, config) {
                // TODO when WS success
                $log.info('PDF success');

                if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/)) || $.browser.msie == 1)
                {
                    var fileIE = new Blob(([data]), { type: 'application/pdf' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(fileIE, 'report.pdf');
                    }
                } else {
                    var file = new Blob([data], {
                        type: 'application/csv'
                    });
                    //trick to download store a file having its URL
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = 'report.pdf';
                    document.body.appendChild(a);
                    a.click();
                }
            }).error(function(data, status, headers, config) {
                //TODO when WS error
                $log.info("PDF ERROR");
            });
        }



        $scope.pdfExport = function() {
            $log.info('PDF Export');

            var canvasEl = angular.element('#pdfCanvas');
            var docWidth = 595.28;

            angular.element('body').scrollTop(0);
            canvasEl.width((docWidth * 1.33333) - 80).css('max-width','none');

            html2canvas(canvasEl, {
                onrendered: function(canvas) {
                    var img = canvas.toDataURL('image/png');
                    // jscs:disable
                    var doc = new jsPDF('p','pt',[canvasEl.height() * 0.78,canvasEl.width() * 0.84]);
                    // jscs:enable
                    doc.addImage(img, 'JPEG', 20, 20);
                    /* CANCELED DUE TO BAD DESIGNS AND CRAPPY FONTS
                     doc.addHTML(canvasEl.get(0), {pagesplit: true}, function(){
                     doc.save('test.pdf');
                     canvasEl.width(cache_width);
                     }); */

                    doc.save('report.pdf');
                }
            });

        };

        $scope.showRuntimesBadge = function(kind) {
            var str = '';
            if (kind == 11) {
                str = 'Gr.1';
            }
            if (kind == 12) {
                str = 'Gr.2';
            }
            if (kind == 13) {
                str = 'Gr.3';
            }
            if (kind == 14) {
                str = 'Gr.4';
            }
            if (kind == 15) {
                str = 'Gr.5';
            }
            return str;
        };

        $scope.showBadge = function(listKind) {
            var str = '';
            //$log.info('Badge for listKind:' , listKind);
            if (listKind == 2) {
                str = $translate.instant('TABS.TIMELIMITED');
            }
            if (listKind == 10) {
                str = $translate.instant('TABS.INTERNET');
            }
            if (listKind == 10) {
                str = $translate.instant('TABS.INTERNET');
            }
            if (listKind == 11) {
                str = 'Gr.1';
            }
            if (listKind == 12) {
                str = 'Gr.2';
            }
            if (listKind == 13) {
                str = 'Gr.3';
            }
            if (listKind == 14) {
                str = 'Gr.4';
            }
            if (listKind == 15) {
                str = 'Gr.5';
            }
            return str;
        };


        $scope.calculateHideSome=function() {
            $scope.hideUsageSome= false;
            if ($scope.dataSettings.hideUsageRuntimePrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageRuntimeWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryPrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryBlocked==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistorySystem==true) {$scope.hideUsageSome=true;}
            var hideAny_object = {"hideUsageAny":$scope.hideUsageSome};
            if (!$scope.isReport) { ccUsageFactory.setUsageSettings(hideAny_object);}
        }

        ccUsageFactory.getUsageSettings()
            .then(function success(response) {

                $log.info('getUsageSettings returned SUCCESS');
                $scope.dataSettings = response.data;
                $scope.calculateHideSome();
                $log.info('usage get settings!', $scope.dataSettings);

                //add watchers, important context is here dataSettings!
                //---------------------------------------------------------
                _.each($scope.dataSettings, function(v, k) {
                    $scope.$watch('dataSettings.' + k, function(new_val, old_val) {
                        if (new_val != old_val) {
                            var changed_object = {};
                            changed_object[k] = new_val;
                            $log.info('usage changed settings!',k);
                            scgSharedFactory.needsSync = true;
                            $scope.calculateHideSome();
                            ccUsageFactory.setUsageSettings(changed_object);
                        }
                    }, true);
                });
            });


        $scope.limitClick = function(limitEntry,sectionId) {


            //sectionId is part of uaage:
            //100 -> RunTime Device
            //110 -> Runtime Apps
            //120 -> RunTime Websites

            var entry = limitEntry.limitInfo.entry;
            var kind = limitEntry.limitInfo.kind;     //this is returned from limitentries response!
            var hasLimit = limitEntry.limitInfo.hasLimit;
            var listKind = limitEntry.listKind;

            //$log.info('LimitClick not implemented yet for kind=' + kind + ' and entry ' + entry);

            if( $scope.isReport === true ){
                $log.info('LimitClickCC REPORT-MODE kind=' + kind + ' and entry=' + entry + ' and hasLimit=' + hasLimit) ;

                if (kind<10 || kind>15) {
                    ccUsageFactory.getLimitinfo(entry)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes==undefined || $scope.dataLimitinfo.blockedTimes===scgSharedFactory.blockOff())
                                {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes=undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                $scope.showLimitinfo=true;
                            },
                            function error(response) {
                                $scope.showLimitinfo=false;
                            }
                        )
                } else {
                    //we have to lookup kind instead of entry
                    ccUsageFactory.getLimitinfoByKind(kind)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes==undefined || $scope.dataLimitinfo.blockedTimes===scgSharedFactory.blockOff())
                                {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes=undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                $scope.showLimitinfo=true;
                            },
                            function error(response) {
                                $scope.showLimitinfo=false;
                            }
                        )
                }


            }else {
                $log.info('LimitClickCC PARENTS-MODE --- kind=' + kind + ' listKind=' + listKind);
                //---------------------------- PARENT MODE ------------------------------------
                if (kind==0) {
                    $state.go('cc-timelimits.pc');
                    return;
                }
                if (kind==10) {
                    $scope.loadInetPrgrams();
                    return;
                }

                var modal_settings = {
                    mode: 'edit',
                    //context: 'prg' or 'net',
                    title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                    //newRecord: null,
                    //editRecords: editRecords,
                    refreshStateAfterClose: false
                };

                //Attention: kind is coming here from limitentries .kind
                if( kind == 5 || kind==6 || kind==7 || kind==8 || kind==22){
                    modal_settings.context = 'net';
                }else {
                    modal_settings.context = 'prg';
                }

                if( (kind >= 1 && kind <= 4) || (hasLimit===true && (listKind < 10 && listKind > 15))){
                    //Normal edit modal
                    $log.info('limitclick normal edit entered!');
                    var editRecords = [];
                    var obj = {data: {'entry': entry}};
                    editRecords.push(obj);

                    modal_settings.newRecord = null;
                    modal_settings.editRecords = editRecords;
                    modal_settings.mode = 'edit';

                    openEditModal(modal_settings);

                }else if((listKind >=10 && listKind <=15) || (kind>=10 && kind<=15)){

                    modal_settings.context = 'prg';
                    if (kind>=10 && kind<=15) listKind=kind;
                    $log.info('limitclick groups entered!');
                    //handle group edit (load multimple entries into edit modal)
                    ccListsFactory.getDetailedByListFilter(listKind)
                        .then(function success(response) {
                            var groupEntries = response.data;

                            var editRecords = [];

                            _.each(groupEntries, function(groupEntry){
                                var obj = {data: {'entry': groupEntry.entry}};
                                editRecords.push(obj);
                            });

                            modal_settings.newRecord = null;
                            modal_settings.editRecords = editRecords;
                            modal_settings.mode = 'edit';

                            openEditModal(modal_settings);
                        });

                } else {

                    //This is new entry ADD case, if it is not kind=7 (web) its context prg
                    modal_settings.editRecords = null;
                    modal_settings.newRecord = {
                        kind: kind,
                        texts: [entry]
                    };
                    modal_settings.mode = 'add';
                    openEditModal(modal_settings);
                }

            }

        }




        function openEditModal(modalSettings){
            //open edit modal for this entry
            modalFactory.editModal.open({
                modal_settings: modalSettings,
                callbacks: {
                    ok: function() {},
                    cancel: function() {}
                }
            });
        }

        $scope.exitLimitinfo = function() {
            $log.info('exitLimitinfo clicked');
            $scope.showLimitinfo=false;
        }


        $scope.logout = function() {
            $state.go('login-logoff-success');
        }

        $scope.scrollToUsageSettings = function() {
            $location.hash('UsageSettings');
            $anchorScroll();
        }

        $scope.usageEdit = function(entry, context) {

            $log.info('usageEdit entry=' + entry);
            $log.info('usageEdit context=' + context);

            //Special case Chico Browser -> Redirect time limit page
            if (entry == 'Chico Browser') {
                $state.go('cb-browsertime');
                return;
            }

            var modalSettings = {
                mode: 'add',
                context: context,
                title: $translate.instant('HEADER.LIMITENTRY'),
                newRecord: null, //to be overridden later !!!
                editRecords: null, //to be overridden later !!!
                refreshStateAfterClose: true
            };

            var kind = 1;
            if (context == 'net') {
                kind = 7;
                entry = scgSharedFactory.getTLD(entry);
                modalSettings.mode = 'add';
                modalSettings.newRecord = {
                    kind: kind,
                    texts: [entry]
                };
                modalSettings.editRecords = null;
            }else {
                //entry = entry.replace("/", "");
                modalSettings.mode = 'edit';
                modalSettings.newRecord = null;
                modalSettings.editRecords = [{data: {entry: entry}}];
            }

            $log.info('usageEdit modalSettings=' , modalSettings);

            modalFactory.editModal.open({
                modal_settings: modalSettings,
                callbacks: {
                    ok: $scope.onEditModalReturn,
                    cancel: $scope.onEditModalReturn
                }
            });
        };



        $scope.getWeekStats = function(period) {
            $scope.loadingWeekStats=true;
            ccUsageFactory.getWeekStats(period)
                .then(function success(response) {
                    $scope.weekstats = response.data;
                    $scope.weekstatsPeriod = period;
                    $scope.weekstatsPercentage = $scope.getPercentageFromStats(response.data);
                    $log.info('Weekstats=', $scope.weekstats);
                    $scope.loadingWeekStats=false;
                });
            return;
        }

        //Execute once weekStats
        $scope.getWeekStats($scope.weekstatsPeriod);



        $scope.showDayByWeekStats= function(azdate) {
            $scope.customStartDate = azdate;
            $scope.customStopDate = azdate;
            $scope.perioddropdown = "7"; //custom period
            $scope.loadingUsage = true;
            ccUsageFactory.getUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.hasNoData = false;
                    if (response.data.logs.length == 0 && response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }
                    $scope.loadingUsage = false;
                });
        }

        $scope.getPercentageFromStats = function(weekstats){
            var result = [];
            var total = 0;
            var highestVal = 0;
            var singleVal = 0;
            console.log('x', weekstats)
            _.forEach(weekstats, function(obj,index){
                total += obj.seconds;
                if (obj.seconds>highestVal) {highestVal=obj.seconds};
            })
            _.forEach(weekstats, function(obj,index){
                singleVal = obj.seconds/highestVal*100;
                if (isNaN(singleVal)) {
                    result.push(0)}
                else {result.push(singleVal);}
            })
            return result;
        }



        scgSharedFactory.getServerTimeDiff().then(function(funcresult) {
            $scope.serverTimeDiff = funcresult;
        });

        $scope.getImgName = function(entryName){
            var ret = (entryName || "").replace(".exe", ".png");
            return ret;
        }


        if ($scope.preselectedWeekday!=null)
        {
            $scope.customStartDate = $scope.preselectedWeekday;
            $scope.customStopDate = $scope.preselectedWeekday;
            $scope.perioddropdown = "7"; //custom period

            $scope.loadingUsage = true;
            ccUsageFactory.getUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.hasNoData = false;
                    if (response.data.logs.length == 0 && response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }
                    $scope.loadingUsage = false;

                });
        }



    });

