'use strict';
angular.module('ChicoApp.CB-Emailreport')

    .controller('CB-EmailReportController', function(
      $scope,
      $http,
      $state,
      $translate,
      _,
      $log,
      API,
      cbEmailReportFactory,
      scgSharedFactory,
      scgGlobalSpinnerFactory) {

      scgGlobalSpinnerFactory.blockUI();

      $scope.data = {};
      $scope.hintHide = true;
      $scope.mailResult = '-1';


      cbEmailReportFactory.getSettings()
            .then(function success(response) {

              $log.info('cbEmailReportFactory returned SUCCESS');
              $scope.data = response.data;

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    $log.info('Howdy, settings Changed!');
                    cbEmailReportFactory.setSetting(changed_object);
                  }
                }, true);
              });

              scgGlobalSpinnerFactory.unblockUI(); //Unblock here, because below might by async call!

            });

      $scope.testMail = function() {
        var obj = {};
        obj.receiver = $scope.data.emailReportRecipient;
        obj.ccreceiver = $scope.data.emailReportCC;
        obj.subject = $scope.data.emailReportSubject;
        obj.lang = 'de';
        if ($translate.use() == 'de_DE') {
          obj.lang = 'de';
        } else {
          obj.lang = 'en';
        }
        $log.info('Testmail to:' + $scope.data.emailReportRecipient + ' with obj.lang=' + obj.lang);
        var req = {
          method: 'POST',
          url: API.url + 'action/testmail',
          data: obj
        };
        return $http(req)
                .then(function success(response) {
                  $scope.mailResult = '-1';
                  //-2 = case CC not confirmed
                  if (response.data.responsecode == -2) {
                    $scope.mailResult = '-2';
                  }
                  if (response.data.responsecode == 0) {
                    $scope.mailResult = '0';
                  }
                  if (response.data.responsecode == 1) {
                    $scope.mailResult = '1';
                  }
                  angular.element('#modalTestmail').modal();
                  return response;
                }, function error(err) {
                  $log.info('error while HTTP GET');
                  return err;
                });
      };


      $scope.checkReceiver = function (value)
      {
          //simply do a reload to check
          $log.info('checkReciever value=' , value);
          if (value === undefined) return;
          if (value.indexOf("@") != -1) { $state.reload();}
      }


      $scope.unblockEmail = function() {
          $log.info('unblock arrived for:', $scope.data.blcheck);
          var langID = "de";
          if ($translate.use()== 'en_US') {langID='en';}
          var req = {
              method: 'GET',
              url: API.url + 'action/subscribe/' + langID + "/" + $scope.data.blcheck
          };
          $log.info('unblock url:', req.url);
          return $http(req)
              .then(function success(response) {
              $log.info("SUCCESS!");
              angular.element('#modalSubscribe').modal();
              });
      }

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(2070).//adjust hintID here, leading 20 means CB!
            then(function success(response) {
              if (response.data.hint2070) {                         //adjust hintID here
                $scope.hintHide = response.data.hint2070;}        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;}
            }
        );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(2070).then(function success() {
          $log.info('HINT DONE SUCCESS');
        }
      );};
      //--------------------------------------------------------------------------------

    });

