'use strict';
angular.module('ChicoApp.CC-Wizard')

.controller('CC-Wizard-1-Controller', function(
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $translate,
    $log
    ) {


    $scope.username= "TODO-USER";
    //$scope.username = AuthFactory.activeUser //TODO Dierk8a: retrieve username from auth
    $scope.introtext = $translate.instant('WIZARD.INTRO').replace("%%",$scope.username);


    $scope.goNext = function() {
        $log.info('goNext triggered');
        $state.go('cc-wizard.2');
    }

    $scope.goBack = function() {
        $log.info('goBack triggered');
        $state.go('cc-wizard.6');
    }

    $state.go('cc-wizard.1');





});
