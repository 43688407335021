'use strict';
angular.module('ChicoApp.CB-Settings')

    .controller('CB-Settings-ResetPw', function(
        $rootScope,
        $scope,
        $state,
        $translate,
        $log,
        $http,
        $timeout,
        scgGlobalSpinnerFactory,
        modalFactory,
        PwChangeFactory) {

      $log.info('CB pw reset controller');

      $scope.pw = '';
      $scope.pwrepeat = '';
      $scope.errStyle = 'background-color:white'; //default

      $scope.pwChange = function() {
        if ($scope.pw.length > 3 && $scope.pw == $scope.pwrepeat) {
          scgGlobalSpinnerFactory.blockUI();
          PwChangeFactory.changePassword($scope.pw).then(function() {
            $scope.pw = '';
            $scope.pwrepeat = '';
            $scope.errStyle = 'background-color:white'; //back to default
            scgGlobalSpinnerFactory.unblockUI();
            modalFactory.infoModal.open({
              modal_settings: {
                title: $translate.instant('HEADER.INFO'),
                message: $translate.instant('LOGIN.SUCCESSPWNEW'),
                butOkLabel: $translate.instant('BUTTON.OK'),
                butCancelLabel: null,
                iconNo: 1,
                refreshStateAfterClose: false
              },
              callbacks: {
                ok: function() {
                  $state.go('cb-settings');
                }
              }
            });
          });
        } else {
          //make fields red!
          $scope.errStyle = 'background-color: #EF4836'; //default
        }
      };

    }); //eof controller

