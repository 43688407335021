angular.module('ChicoApp.Shared').controller('HeaderController', function(
    $scope,
    $state,
    $http,
    $cookies,
    $interval,
    $translate,
    $stateParams,
    $log,
    $cookies,
    API,
    apiFunctions,
    AuthFactory,
    sseFactory,
    $anchorScroll,
    modalFactory,
    DeviceFactory,
    scgGlobalSpinnerFactory,
    scgSharedFactory) {

  $scope.scgSharedFactory = scgSharedFactory;

  $scope.syncRunning = false;

  $scope.scrollTop = function() {
    $anchorScroll();
  };

  $scope.logout = function(){
    AuthFactory.logout(true);
  }

  $scope.runSync = function() {
    $scope.syncRunning = true;
    sseFactory.sync()
        .then(function success(response) {
          if (response.data.success == true) {
            $log.info('SYNC SUCCESS');
            scgSharedFactory.needsSync = false;
            $scope.syncRunning = false;
            $scope.syncInfoModal($translate.instant('MSG.SYNCSUCCESS'));
          } else {
            $log.info('SYNC FAILED');
            scgSharedFactory.needsSync = true;
            $scope.syncRunning = false;
            $scope.syncInfoModal($translate.instant('MSG.SYNCFAILURE'));
          }
        });
  };

  //DO NOT WATCH HERE, REPLACED BY hasCHANGED calll
  //DIERK: KEEP AS EXAMPLE FOR A SINGLE VARIABLE WATCHER
  /* $scope.$watch(function(){
   return AuthFactory.activeDevice;
 }, function(nV, oV){
   if(nV && nV != oV){
     console.log('NEEDSSYNC=FALSE 8');
     scgSharedFactory.needsSync = false;
   }
 });*/

  $scope.gotoSync = function() {
    if (AuthFactory.activeContext === 'cb') {
      $state.go('cb-sync');
    }

    if (AuthFactory.activeContext === 'cc') {
      $log.info('SYNC, ENTER CC!');
      //new approach, sync immediately if SSE returns online
      var checkOnlineStatus = function() {
        sseFactory.isOnline()
            .then(function success(response) {
              if (response.data.success == true) {
                $log.info('SYNC, YEPP WE ARE ONLINE!');
                $scope.runSync();
                return;
              }else {
                $log.info('SYNC NOT ONLINE');
                $state.go('cc-sync');
              }
            },function error() {
              $log.info('SYNC NOT ONLINE');
              $state.go('cc-sync');
            }
        );

      };
      checkOnlineStatus();
    }
  };

  $scope.syncInfoModal = function(msg) {
    scgGlobalSpinnerFactory.unblockUI();
    modalFactory.infoModal.open({
      modal_settings: {
        title: $translate.instant('HEADER.INFO'),
        message: msg,
        butOkLabel: $translate.instant('BUTTON.OK'),
        butCancelLabel: null,
        iconNo: 1,
        refreshStateAfterClose: true
      },
      callbacks: {
        ok: function() {
          $log.info('modal closed with ok -> callback');
        }
      }
    });
  };


    $scope.roamingSettings = function() {
        $log.info("roaming settings");
        modalFactory.roamingModal.open({
            modal_settings: {
                refreshStateAfterClose: false
            },callbacks: {
                ok: function () {
                    $log.info('modal-until ok callback');
                    $state.reload();
                }
            }
        });
    }


    //$scope.userRole = AuthFactory.userRole;

    $scope.$watch(function() {
        return AuthFactory.userRole;
    }, function(newVal) {
        $scope.userRole = AuthFactory.userRole;
    });

  $scope.$watch(function() {
    return AuthFactory.loggedin();
  }, function(newVal) {
    if (newVal == true) {
      $scope.loggedin = true;
    } else {
      $scope.loggedin = false;
    }

  });

  //watch active device
  $scope.$watch(function() {
    return AuthFactory.activeDevice;
  }, function(newVal) {
    $scope.activeDevice = newVal;
  });

  //watcher active device roaming
  $scope.$watch('activeDevice.deviceId', function(newVal,oldVal) {
    if (newVal != oldVal) {
        $scope.checkForRoaming();
        if ($scope.activeDevice.versionInfo!=null) {
          $scope.scgSharedFactory.checkForUpdateNotice($scope.activeDevice.versionInfo);
        }
        $log.info('HEADWATCHER - new deviceId:',$scope.activeDevice);
      }
  })




  //watch active user
  $scope.$watch(function() {
    return AuthFactory.activeUser;
  }, function(newVal) {
    $scope.activeUser = newVal;
  });

  //watch for activeUser roaming
    $scope.$watch('activeUser.username', function(newVal,oldVal) {
        if (newVal != oldVal) {
            $scope.checkForRoaming();
            $log.info('HEADWATCHER - new activeUser.username:',$scope.activeUser.username);
        }
    })


    $scope.$watch(function() {
    return DeviceFactory.deviceList;
  }, function(newVal) {

    $scope.devices = newVal;
    $log.info('devices=' , $scope.devices);
  });

  $scope.pagetitle = $stateParams.title;

  $scope.selectActiveDevice = function(device) {
    if (device.users && device.users.length > 0) {
      return;
    }

    if (!$cookies.get('user')) {
      AuthFactory.setActiveDevice(device, null);
      $state.reload();
    } else {
      AuthFactory.setActiveDevice(device, null);
        if ($cookies.get('cb2')=="true") {
            $state.go('cb-dashboard'); }
        else {
            $state.go('cb-dashboard-v3');
        }
    }
  };

  $scope.selectActiveUser = function(user, device) {
    if ($cookies.get('user')) {
      AuthFactory.setActiveDevice(device, user);
      $state.reload();
    } else {
      AuthFactory.setActiveDevice(device, user);
      $state.go('cc-dashboard-v3'); //reload state
    }
  };

  $scope.goToDashboard = function() {
    if (AuthFactory.activeContext == 'cc') {
      $log.info('gocc');
      $state.go('cc-dashboard-v3');
    }else {
      $log.info('gocb');
      if ($cookies.get('cb2')=="true") {
      $state.go('cb-dashboard'); }
      else {
        $state.go('cb-dashboard-v3');
      }
    }
  };





   $scope.checkForRoaming = function() {

     var thisUrl = API.url + 'cbsettings/roaming';

     if (AuthFactory.activeContext == 'cc') {
         thisUrl = apiFunctions.get_cc_base_url() +  'ccsettings?key=roaming';
         $log.info('HEADER checkForRoaming WE ARE CC');
     }

       var req = {
           method: 'GET',
           url: thisUrl
       };

       $http(req).success(function(data) {
         $log.info('HEADER checkForRoaming', data);
         if (data.roaming!=null && data.roaming != '' && data.romaing !='undefined') {
             scgSharedFactory.hasRoaming = true;
         } else {
             scgSharedFactory.hasRoaming = false;
         }
       });
   }



   $scope.checkSession = function() {
       var req = {
           method: 'GET',
           url: API.url + 'action/sessioncheck'
       };
       $http(req).then(function success() {
           $log.info('HEADER Sessioncheck we are still online');
       })
           .catch(function error(error) {
               $log.info('HEADER Sessioncheck EXPIRED');
           });
   }


    //Recurring Timer, update each 30 Seconds statusInfo!
    var intervalStatusInfo = $interval(function () {
      $scope.checkSession();
      $log.info('HEADER TIMER SESSION CHECK');
    }, 60 * 1000);


});
