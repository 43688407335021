'use strict';
angular.module('ChicoApp.CC-Settings')

// TODO: MOVE to own file
   

    //Dierk: "ccSecurityFactory" ist in diesem Controller required
    .controller('CC-Settings-Extended', function(
        $scope,
        $state,
        $translate,
        $stateParams,
        CCSettings,
        $log,
        $modal,
        modalFactory,
        _,
        scgGlobalSpinnerFactory,
        scgLicenceFactory,
        scgSharedFactory) {

      $log.info('CC-Settings-Extended Controller');
      scgGlobalSpinnerFactory.blockUI();

      $scope.highlightedField = $state.params.highlight;
      $scope.hintHide = true;
      $scope.licenceSerial = '';
      $scope.expireDate = '';
      $scope.expireDays = '';

      $scope.$on('$destroy', function() {
        $state.params.highlighted = '';
        $scope.highlightedField = null;
      });

      $log.info('state params on ext', $stateParams);

      //initially set language to scope to have it in the template
      $scope.currentLanguage = $translate.use();

      //watch and implement lang changes
      $scope.$watch('currentLanguage', function(nV, oV) {
        if (nV && nV != oV) {
          $translate.use(nV);
        }
      });

      $scope.data = {};
      //Dierk: Hier werden die Daten aus der Factory (Service Folder) geholt

      $scope.editSerial = function() {
        $state.go('licence');
      };

      $scope.syncNow = function() {
        $state.go('cc-sync');
      };

      $scope.changeWarn = function(extNo, valueToChange) {

          extNo = parseInt(extNo);
          $log.info("changeWarn fired!");
          $log.info("changeWarn  extNo=", extNo);
          $log.info("changeWarn valueToChange=", valueToChange);
        var warntimeModal = $modal.open({
            animation: false,
            template: '<scg-extension-modal></scg-extension-modal>', //see folder directive!!!
            windowTemplateUrl: 'templates/childcontrol/cc_settings/templates/modal-extension-window.html',
            controller: 'SCG-Modal-Extension-Controller',
            resolve: {
                modalCallbacks: function() {},
                extNo: extNo,
              }
          });

        // use modal result that is handed to promise via modalinstance.close(value)
        // in settings-modal-extension-controller
        warntimeModal.result.then(function(result) {
            $log.info('result for warntime:' + result + ' valueToChange:' + valueToChange);
            $scope.data[valueToChange] = result;
          });

      };

      CCSettings.getSettings()
            .then(function success(response) {

              $scope.data = response.data;
              $log.info('CCSettings returned withh reponse.data=' , $scope.data);

              //add watchers
              //Dierk: 2-way databinding l�uft hier, funktion ist in der factory!
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};

                    changed_object[k] = new_val;
                    // console.warn('::::::type:::::',typeof changed_object[k]);
                    $log.info('changed settings!');
                    scgSharedFactory.needsSync = true;
                    CCSettings.setSetting(changed_object);
                  }
                }, true);
              });
              scgGlobalSpinnerFactory.unblockUI();
            });

      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(91).                                //adjust hintID here
            then(function success(response) {
              if (response.data.hint91) {                         //adjust hintID here
                $scope.hintHide = response.data.hint91;}        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;}
            }
        );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(91).then(function success() {
          $log.info('HINT DONE SUCCESS');
        }
      );};
      //--------------------------------------------------------------------------------

      scgLicenceFactory.getSerialInfo()
            .then(function success(response) {
              $log.info('LICENCETEST Success', response);
              $scope.expireDate = scgSharedFactory.getLocalizedDateString(response.data.expireDate);
              if (response.data.serial == '') {
                $scope.licenceSerial = $translate.instant('MISC.TRIALVERSION');
              }else {
                $scope.licenceSerial = response.data.serial;
              }
            },
            function error() {
              $log.info('LICENCETEST ERROR');
            });

    });

