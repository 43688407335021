'use strict';
angular.module('ChicoApp')
    .constant('PREFEERED_LANGUAGE', 'de_DE');
angular.module('ChicoApp')
    /*
    .constant('API', {
      'url': 'https://portal.salfeld.net/api/',
      'filesurl': 'https://files.salfeld.net/',
      'add-dev-headers': true
    })
    */
    .constant('_',window._)
    .constant('moment',window.moment)
    .constant('html2canvas',window.html2canvas)
    .constant('jsPDF',window.jsPDF)
    .constant('google',window.google)
    .constant('Metronic',window.Metronic)

    .constant('FEATURES', {
        'feature1': {'fromVersion':'1.1.1'},
        'cb3background': {'fromVersion':'3.1.1159'},
        'protectSettings': {'fromVersion':'3.1.1159'},
        'hasFeatureNoAppsInBlockTime' : {'fromVersion':'3.1.1181'},
        'onesecinterval' : {'fromVersion':'3.1.1181'},
        'roamingv2' : {'fromVersion':'3.1.1200'},
        'crackProtection' : {'fromVersion':'3.1.1199'}
    })

   .constant('FEATURESCC', {
    'roamingv2': {'fromVersion':'17.0.0.2800'}
        });