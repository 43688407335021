'use strict';
angular.module('ChicoApp.Shared').factory('modalFactory', function (
  $modal,
  $state,
  $log,
  moment,
  scgSharedFactory,
  scgGlobalSpinnerFactory,
  $translate
) {

  var factory = {};

  factory.handleStateOptions = function (modal_settings) {
    if (modal_settings.goToStateAfterClose) {
      $state.go(modal_settings.goToStateAfterClose, {}, {
        reload: true
      });
    } else if (modal_settings.refreshStateAfterClose === true) {
      $state.go($state.current, {}, {
        reload: true
      });
    }
  };

  /*
   * will be called when modal exits in "cancel" status
   */
  factory.func_global_cancel = function (modalInstance, options) {
    $log.info('Modal dismissed');
    factory.handleStateOptions(options.modal_settings);
    modalInstance.dismiss('cancel');
    options.callbacks.cancel();
  };

  /*
   * will be called when modal exits in "ok" status
   */
  factory.func_global_ok = function (modalInstance, options) {
    $log.info('Modal success');
    factory.handleStateOptions(options.modal_settings);
    modalInstance.dismiss('cancel');
    options.callbacks.ok();
  };

  /*
   * EDIT MODAL
   */
  factory.editModal = {

    open: function (options) {

      //unblock ui for modal controls, just in case

      scgGlobalSpinnerFactory.unblockUI();

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<cc-edit-modal></cc-edit-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalEditModal/templates/modal-edit-window.html',
        controller: 'CC-Modal-Edit-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          }
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  };

  /*
   * EDIT GROUP MODAL
   */
  factory.editGroupModal = {

    open: function (options) {

      //unblock ui for modal controls, just in case

      scgGlobalSpinnerFactory.unblockUI();

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<scg-edit-group-modal></scg-edit-group-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalEditGroupModal/templates/modal-edit-group-window.html',
        controller: 'SCG-Modal-Edit-Group-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          }
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  };

  /*
   * ERROR MODAL
   */
  factory.errorModal = {

    open: function (options) {

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<cc-error-modal></cc-error-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalErrorModal/templates/modal-error-window.html',
        controller: 'CC-Modal-Error-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          }
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  };

  /*
   * INFO MODAL
   */
  factory.infoModal = {

    open: function (options) {

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<scg-info-modal></scg-info-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalInfoModal/templates/modal-info-window.html',
        controller: 'SCG-Modal-Info-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          }
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  };

  /*
   * FEEDBACK MODAL
   */
  factory.feedbackModal = {

    open: function (options) {

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<scg-feedback-modal></scg-feedback-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalFeedbackModal/templates/modal-feedback-window.html',
        controller: 'SCG-Modal-Feedback-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          }
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  };

  // Modal with "Information, mymessage  and OK only"
  factory.okModal = function (iconId, mymessage) {
    factory.infoModal.open({
      modal_settings: {
        title: $translate.instant('HEADER.INFO'),
        message: mymessage,
        butOkLabel: $translate.instant('BUTTON.OK'),
        butCancelLabel: null,
        iconNo: iconId,
        refreshStateAfterClose: true
      },
      callbacks: {
        ok: function () {
          $log.info('modal closed with ok -> callback');
        },
        cancel: function () {
          $log.info('modal closed with cancel -> callback');
        }
      }
    });
  };

  /*
   * FAQ MODAL
   */
  factory.faqModal = {

    open: function (options) {

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<scg-faq-modal></scg-faq-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalFaqModal/templates/modal-faq-window.html',
        controller: 'SCG-Modal-Faq-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          }
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  };


  /*
   * UNTIL MODAL
   */

  factory.untilModal = {
    open: function (options) {

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<scg-until-modal></scg-until-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalUntilModal/templates/modal-until-window.html',
        controller: 'SCG-Modal-Until-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          },
          modalParams: options.data || {}
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  }


  factory.roamingModal = {
    open: function (options) {

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<scg-roaming-modal></scg-roaming-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalRoamingModal/templates/modal-roaming-window.html',
        controller: 'SCG-Modal-Roaming-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          },
          modalParams: options.data || {}
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  }


  /*
   * IMPORT MODAL
   */

  factory.importModal = {

    open: function (options) {

      options = options || {};

      var modal_settings = options.modal_settings || {};
      modal_settings.refreshStateAfterClose = modal_settings.refreshStateAfterClose || false;
      modal_settings.goToStateAfterClose = modal_settings.goToStateAfterClose;

      //make sure callbacks are defined
      options.callbacks = options.callbacks || {};
      options.callbacks.ok = options.callbacks.ok || function () {};
      options.callbacks.cancel = options.callbacks.cancel || function () {};

      var modalInstance = $modal.open({
        animation: false,
        template: '<scg-import-modal></scg-import-modal>',
        windowTemplateUrl: 'templates/shared/globalModals/globalImportModal/templates/modal-import-window.html',
        controller: 'SCG-Modal-Import-Controller',
        resolve: {
          modal_settings: function () {
            return modal_settings;
          },
          modalParams: options.data || {}
        }
      });

      var ok_global = function () {
        factory.func_global_ok(modalInstance, options);
      };

      var cancel_global = function () {
        factory.func_global_cancel(modalInstance, options);
      };

      modalInstance.result.then(ok_global, cancel_global);
    }
  };





  factory.getLocalizedDateString = function (apiDate) {
    if (!moment(apiDate).isValid()) {
      apiDate = '2010-01-01';
    }

    if (parseInt(moment(apiDate).format('X')) < parseInt(moment('2010-01-01').format('X'))) {
      apiDate = '2010-01-01';
    }

    if ($translate.use() == 'de_DE') {
      return moment(apiDate).format('DD.MM.YYYY');
    } else {
      return moment(apiDate).format('DD. MMM YYYY');
    }

  };

  return factory;

});