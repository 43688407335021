'use strict';
angular.module('ChicoApp.CB-Settings')

    .controller('CB-SettingsController', function(
      $rootScope,
      $scope,
      $translate,
      $state,
      $http,
      $window,
      $timeout,
      $modal,
      DeviceFactory,
      AuthFactory,
      cbListsFactory,
      DTOptionsBuilder,
      DTColumnDefBuilder,
      scgDataTableFactory,
      modalFactory,
      Metronic,
      moment,
      $log,
      API,
      Upload,
      apiFunctions,
      cbSettingsFactory,
      scgSharedFactory) {

      $scope.hasCBFeature = scgSharedFactory.hasCBFeature;

      $scope.mymodalstring = '';
      $scope.mymodalbool = false;
      $scope.hasOldData = false;
      $scope.remainingDays = '?';
      $scope.remainingDate = '!';

      var modalStartKind = 99; //teststuff

      $scope.tableHeaders = [$translate.instant('HEADER.DOMAIN')];
      $scope.myvariable = 'blubb';
      $scope.isCB3 = undefined;

      $scope.hasCBFeature = scgSharedFactory.hasCBFeature;

      $scope.data = {};
      $scope.cb3data = {};
      $scope.data.table_api_urls = [];
      $scope.data.table_api_keywords = [];

        $scope.backgroundRadio = 1;
        $scope.backgroundCounter = moment().format('X');
        $scope.hasCustomBackground=false;
        $scope.preview=false; //remove later, devflag for backgroundImage, set for cb3 to true
        $scope.imgBack = "";


        scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

      AuthFactory.checkCurrentDeviceLicenceCBwithCache();

      $scope.toDoModal = function() {
        modalFactory.okModal(1, $translate.instant('MSG.COMINGSOON'));
      };

      //Gather lastsync date (async!)
      $scope.lastSync = '   ' + $translate.instant('MISC.RESOLVING');
      // scgSharedFactory.getLastSync().then(function(funcresult) {
      //   $scope.lastSync = scgSharedFactory.formatDateTime(funcresult);
      // });
      scgSharedFactory.getLastSync().then(function success(lastSync) {
        $scope.lastSync = scgSharedFactory.formatDateTime(lastSync);
      });

      //Gather general info details (async!)
      $scope.cbversion = {};
        AuthFactory.getVersionInfo().then(function(version) {
        $scope.cbversion = version;
      });

      //Gather general info details (async!)
      $scope.isCBSecurity = false;
      scgSharedFactory.isCBSecurity().then(function(returnvalue) {
        $scope.isCBSecurity = returnvalue;
      });

      //order goes here
      $scope.order = function() {
        var link = 'http://salfeld.de/bestellen/chico-browser/';
        if ($translate.use() == 'en_US') {
          link = 'http://salfeld.com/order/chico-browser';
          $log.info('Order link=' + link);
          window.open(link, '_blank');
        }
      };

      // upload on file select or drop
      $scope.uploadFiles = function(file, errFiles) {
        $scope.f = file;
        $log.info('Upload entry');
        $scope.errFile = errFiles && errFiles[0];
        if (file) {
          Metronic.blockUI({animate: true});
          file.upload = Upload.http({
            url: API.url + 'service/import/cb',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            data: file
          });

          file.upload.then(function(response) {
            $timeout(function() {
              file.result = response.data;
              $log.info('Upload status A' + response.data);
              Metronic.unblockUI();
              modalFactory.okModal(1, $translate.instant('MSG.IMPORTSUCCESS'));
            });
          }, function(response) {
            if (response.status > 0) {
              $scope.errorMsg = response.status + ': ' + response.data;
            }
            Metronic.unblockUI();
            $log.info('Upload status B');
            modalFactory.okModal(3, $translate.instant('MSG.IMPORTFAILED'));
          }, function(evt) {
            file.progress = Math.min(100, parseInt(100.0 *
                evt.loaded / evt.total));
          });
        }
      };

      //------------------------------------------------------------------------------------
      //EXPORT SETTINGS (Download file)
      //------------------------------------------------------------------------------------
      $scope.exportSettings = function() {

        //Exit on ios (because it displays JSON as text)
        var ua = 'User-agent header sent: ' + navigator.userAgent;
        if (ua && ua.match('iPhone|iPad')) {
          modalFactory.okModal(1, $translate.instant('MSG.NOTONIOS'));
          return;
        }

        var req = {
          method: 'GET',
          url: API.url + '/service/export/cb'
        };
        $http(req)
          .then(function success(response) {
            $log.info('http success has been returned');
            $log.info('response=', response);
            var data = response.data;
            if (typeof data === 'object') {
              data = JSON.stringify(data, undefined, 2);
            }
            var blob = new Blob([data], {
              type: 'text/json'
            });
            var filename = 'export.cbsettings';

            if (window.navigator.msSaveBlob) {
              window.navigator.msSaveBlob(blob, filename);
            } else {
              var e = document.createEvent('MouseEvents');
              var a = document.createElement('a');
              a.download = filename;
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
              e.initMouseEvent('click', true, false, window,
                  0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
            }

            modalFactory.okModal(1, $translate.instant('MSG.DOWNLOADSUCCESS'));

          }, function error() {
            $log.info('error jas been returned');
            modalFactory.okModal(1, $translate.instant('MSG.IMPORTFAILED')); //importfailed msg ok here
          });

      };

      $scope.syncNow = function() {
        $state.go('cb-sync');
      };

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      $scope.myReload = function() {
        $state.go($state.current, {}, {reload: true});
        $log.info('reloaded!');
      };

      $scope.showModal = function() {
        $log.info('showModal fired');
        angular.element('#myModal3').modal();
      };

      $scope.machDE = function() {
        $translate.use('de_DE');
      };

      $scope.machEN = function() {
        $log.info('machEN fired!');
        $translate.use('en_US');
      };

      $scope.editSerial = function() {
        $state.go('licence');
      };

      $scope.myAction = function() {
        var postData = []; //array for the new records we will later post
        var record = {};

        record.kind = 99;
        record.entry = $scope.mymodalstring;

        postData.push(record);
        $log.info('postData = ', postData);

        cbListsFactory.postRecords(postData)
                .then(function success() {
                  //if(cb){cb();}
                  $log.info('Done with postData ', postData);
                  $state.go($state.current, {}, {reload: true});
                  //
                });
      };

      $scope.openErrorModal = function() {
        $log.info('Open Error Modal dummy');

        var req = {
          method: 'GET',
          url: apiFunctions.get_cc_base_url() + 'ccsettingsfail'
        };
        $http(req);

        setTimeout(function() {

          var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccsettingsfail'
          };
          $http(req);

        }, 5000);
      };

      $scope.getHasOldData = function() {
        var req = {
          method: 'GET',
          url: API.url + 'service/CB1HasDataByDeviceID'
        };
        $http(req)
            .then(function success(response) {
              $log.info('HAS DATA 200, response.data=' + response.data);
              if (response.data != null && response.data == 'OK') {
                $scope.hasOldData = true;
              }
            }, function error() {
              $scope.hasOldData = false;
              $log.info('HAS DATA ERROR');
            });
      };

      $scope.importOldData = function() {
        //modalFactory.okModal(1, $translate.instant('MSG.TODO'));

        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.QUESTION'),
            message: $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 2,
            refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('yes, ok was click, he/she is sure');
              Metronic.blockUI({animate: true});
              var req = {
                method: 'GET',
                url: API.url + 'service/CB1ImportByDeviceID'
              };
              $http(req)
                  .then(function success(response) {
                    $log.info('HAS DATA 200, response.data=' + response.data);
                    if (response.data != null && response.data == 'OK') {
                      Metronic.unblockUI();
                      modalFactory.okModal(1, $translate.instant('MSG.IMPORTSUCCESS'));
                    }

                  }, function error() {
                    Metronic.unblockUI();
                    modalFactory.okModal(1, $translate.instant('MSG.IMPORTFAILED')); //importfailed msg ok here
                    $log.info('HAS IMPORT_CB1 DATA ERROR');
                  });

              $log.info('Ok, all done');
            },
          },
          cancel: function() {
            $log.info('canceled!');
          }
        });

      };

      $scope.setSecurity = function(status) {
        var myurl = API.url + 'service/DisableCBSecurity';
        if (status) {
          myurl = API.url + 'service/EnableCBSecurity';
        }
        $log.info('SetSecurity URL:' + myurl);

        var req = {
          method: 'GET',
          url: myurl
        };
        $http(req)
              .then(function success() {
                $log.info('Done with HTTP 200!');
                modalFactory.okModal(1, $translate.instant('MSG.SAVEDCHANGES'));
              });
      };

      $scope.changePW = function() {
        //OLD: $state.go('pwrequest');
        $state.go('cb-pwreset');
      };

      $scope.deleteData = function() {
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.SURE'),
            message: $translate.instant('MSG.DELETEDATA') + '<br>' + $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 3,
            refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
              Metronic.blockUI({animate: true});

              var myurl = API.url + 'action/deletedevice';
              var req = {
                method: 'GET',
                url: myurl
              };
              $http(req)
                            .then(function success() {
                              $log.info('Done with HTTP 200!');
                              Metronic.unblockUI();
                              AuthFactory.logout(true);
                            });
            },
          },
          cancel: function() {
            $log.info('canceled!');
          }
        });
      };

      $scope.okModal = function(mymessage) {
        modalFactory.okModal(1, mymessage);
      };

      $scope.openInfoModal = function() {
        $log.info('opening info modal');

        modalFactory.infoModal.open({
          modal_settings: {
            title: 'Title for the modal',
            message: 'Message for the modal',
            butOkLabel: 'OK',
            butCanelLabel: 'Cancel',
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
            },
            cancel: function() {
              $log.info('modal closed with cancel -> callback');
            }
          }
        });

      };

      $scope.updateBackground = function() {
          if ($scope.cb3data.imgBackgroundURL != null && $scope.cb3data.imgBackgroundURL != "") {
              $log.info('custom background');
              $scope.hasCustomBackground=true;
              var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
              $scope.imgBack= API.rooturl + 'files/background/' + sessionPath+'?nocache='+$scope.backgroundCounter;
          } else {
              $log.info('standard background');
              $scope.hasCustomBackground=false;
              $scope.imgBack = '../images/scg-hero.png';}
        }


      cbSettingsFactory.getSettings().then(function success(response) {

          $log.info('cbSettingsFactory cb3data init');
          if (response.data != null) {
              $scope.cb3data = response.data;

              if (AuthFactory.activeDevice.deviceId=='ff0430f3cfca2d44' || AuthFactory.activeDevice.deviceId=='1597700806c6863a')  {$scope.preview=true;}

              $scope.updateBackground();

          }



          //add watchers for cb3data object
          _.each($scope.cb3data, function(v, k) {
              $scope.$watch('cb3data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                      var changed_object = {};
                      //Make sure warn value is written as integer!
                      if (k=='generalWarn3Value' || k=='generalWarn5Value') {
                          $log.info('changed integer value=' + new_val);
                          if (typeof new_val === 'string' || new_val instanceof String) {
                              new_val = parseInt(new_val);
                          }
                      }

                      changed_object[k] = new_val;
                      scgSharedFactory.needsSync = true;
                      cbSettingsFactory.setSetting(changed_object);
                  }
              }, true);
          });
      });


      $rootScope.$watch(function() {
        return AuthFactory.activeLicence;
      }, function(nv) {
        if (nv) {
          if (typeof AuthFactory.activeLicence.expireDays != 'undefined') {
            var remDays = AuthFactory.activeLicence.expireDays;
            $scope.remainingDays = $translate.instant('MISC.REMAINING').replace('XXX', AuthFactory.activeLicence.expireDays);
            $scope.remainingDate = scgSharedFactory.getLocalizedDateString(moment().add(remDays,'days').format('YYYY-MM-DD'));
            if (AuthFactory.activeLicence.serial == '') {
              $scope.licenceSerial = $translate.instant('MISC.TRIALVERSION');
            } else {
              $scope.licenceSerial = AuthFactory.activeLicence.serial;
            }
          } else {
            $log.info('ELSE CASE');
            $scope.remainingDays = '??'; //is undefined!
          }
        }
      }, true);

      $scope.getHasOldData();



        $scope.changeWarn = function(extNo, valueToChange) {

            extNo = parseInt(extNo);
            $log.info("changeWarn fired!");
            $log.info("changeWarn  extNo=", extNo);
            $log.info("changeWarn valueToChange=", valueToChange);
            var warntimeModal = $modal.open({
                animation: false,
                template: '<scg-extension-modal></scg-extension-modal>', //see folder directive in CC (is only a template!)
                windowTemplateUrl: 'templates/childcontrol/cc_settings/templates/modal-extension-window.html',
                controller: 'SCG-Modal-Extension-Controller',
                resolve: {
                    modalCallbacks: function() {},
                    extNo: extNo,
                }
            });

            warntimeModal.result.then(function(result) {
                $log.info('result CB3data for warntime:' + result + ' valueToChange:' + valueToChange);
                $scope.cb3data[valueToChange] = result;
            });

        };


        // BACKGROUND PICTURE STUFF ------------------------------------------------------------------
        $scope.deleteBackground = function() {
            var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
            $log.info('DELETE BACKGROUND sess=' + sessionPath);

            $http({
                method: 'DELETE',
                url: API.rooturl + 'files/background/' + sessionPath
            }).then(function(result){
                $scope.cb3data.imgBackgroundColor="#33B5E5";
                $scope.cb3data.imgBackgroundURL="";
                $scope.updateBackground();
                //$state.reload();
            });
        }


        $scope.customImgMsg = function() {
            $log.info('customImgMsg called');
            modalFactory.okModal(1, $translate.instant('MSG.CUSTOMIMG'));
        }


        $scope.uploadBackground = function(file, errFiles) {
            $scope.f = file;
            $log.info('Upload entry with file size=', $scope.f.size);
            $scope.errFile = errFiles && errFiles[0];

            if ($scope.f.size>999999)
            {
                modalFactory.okModal(1, $translate.instant('MSG.CUSTOMIMG_TOO_LARGE'));
                return;
            }

            if (file) {
                Metronic.blockUI({animate: true});
                var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;

                file.upload = Upload.upload({
                    url: API.rooturl + 'files/background/' + sessionPath,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    data: {file:file}
                });

                file.upload.then(function(response) {
                    $timeout(function() {
                        file.result = response.data;
                        $log.info('Upload status A' + response.data);
                        Metronic.unblockUI();
                        $scope.backgroundCounter = moment().format('X');
                        $scope.updateBackground();
                        $state.reload();
                    });
                }, function(response) {
                    if (response.status > 0) {
                        $scope.errorMsg = response.status + ': ' + response.data;
                    }
                    Metronic.unblockUI();
                    $log.info('Upload status B');
                    modalFactory.okModal(3, $translate.instant('MSG.IMPORTFAILED'));
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                });
            }
        };
        // EOF BACKGROUND PICTURE STUFF ------------------------------------------------------------------





        if (AuthFactory.activeDevice.deviceType==30) {$scope.isCB3=true;
        } else {
            $scope.isCB3=false;
        }

        window.scrollTo(0,0);
    });
