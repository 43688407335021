'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Edit-Group-Controller', function (
    $scope,
    $modalInstance,
    $translate,
    modal_settings,
    scgGlobalSpinnerFactory,
    AuthFactory,
    scgSettingsFactory,
    _) {

    $scope.data = {};
    $scope.data.groupName = modal_settings.groupItem.name;
    $scope.data.groups = modal_settings.groups;

    $scope.save = function () {

        //get index of edited item to later insert the new one exactly there
        var origIndex = _.findIndex($scope.data.groups, function (group) {
            return group.kind == modal_settings.groupItem.kind;
        });

        //create an array with all the groups that have not been edited
        var notChangedGoups = _.filter($scope.data.groups, function (group) {
            return group.kind != modal_settings.groupItem.kind;
        });

        var changedGoup = _.find($scope.data.groups, function (group) {
            return group.kind == modal_settings.groupItem.kind;
        });

        //change name of the group we edited
        changedGoup.name = $scope.data.groupName;

        //add the edited group to the array
        notChangedGoups.splice(origIndex, 0, changedGoup);

        scgSettingsFactory.setGroups(notChangedGoups)
            .then(function success( /*response*/ ) {
                $modalInstance.close();
            });

    }

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.ok = function (return_val) {
        $modalInstance.close(return_val);
    };

});