angular.module('ChicoApp').factory('ccUsageFactory', function(
    $http,
    API,
    $log,
    apiFunctions) {

  var factory = {};

  var apifields_usage = [
      'isUsageDisabled',
      'isWebFilterActive',
      'isPrgFilterActive',
      'hasRoaming',
      'hideUsageAny',
      'hideUsageRuntimePrograms',
      'hideUsageRuntimeWebsites',
      'hideUsageHistoryPrograms',
      'hideUsageHistoryWebsites',
      'hideUsageHistoryBlocked',
      'hideUsageHistorySystem',
      'roaming'
  ];

  var apikeys_usage = apiFunctions.obj_to_url_params(apifields_usage);


   //Get 50 entries per category
  factory.getUsageLong = function(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccusage/' + params + '/50'
    };
    return $http(req);
  };

  factory.getUsageByDates = function(startDate, stopDate, howMany) {
    $log.info('getUsageByDatesURL : ' +  apiFunctions.get_cc_base_url() +  'ccusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany);
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() +  'ccusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany
    };
    return $http(req);
  };

  factory.deleteUsage = function()   {
    $log.info('delete usage entered');
    var req = {
      method: 'DELETE',
      url: apiFunctions.get_cc_base_url() +  'ccusage/delete'
    };
    return $http(req);
  };

  factory.getUnlockReqs = function() {
    $log.info('factory.getUnlockReqs entered');
    var req = {
      method: 'GET',
      url: API.url + 'ccsync/unlockreq'
    };
    return $http(req);
  };

  factory.deleteUnlockReq = function(_entry) {
    var obj = {entry: _entry};
    $log.info('factory.DeleteUnlockReq entered with obj=' , obj);
    var req = {
      method: 'DELETE',
      url: API.url + 'ccsync/unlockreq',
      data: obj
    };
    return $http(req);
  };

  factory.doUnlock = function(_username, _entry) {
    var obj = {username: _username,entry: _entry};
    $log.info('factory.doUnlock entered with obj=' , obj);
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccusage/unlockreq',
      data: obj
    };
    return $http(req);
  };

  //Get 10 entries per category (this is default call)
  factory.getUsageShort = function(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccusage/' + params + '/10'
    };
    return $http(req);
  };

  factory.getUsageSettings = function() {
    var params = apiFunctions.obj_to_url_params(apifields_usage);
    $log.info('url is:' + apiFunctions.get_cc_base_url() + 'ccsettings/' + params);
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings/' + params
    };
    $log.info('return val=' + $http(req));
    return $http(req);
  };

  factory.setUsageSettings = function(keyvalue_arr) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: keyvalue_arr
    };
    return $http(req);
  };


    //Retrieve if some entries have time limits
    factory.getLimitEntriesInfo = function(entriesArr){
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'ccusage/limitentries',
            data: entriesArr
        };
        return $http(req);
    }

    //Retrieve a single limited entry
    factory.getLimitinfo = function(entry) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/limitinfo/' + entry
        };
        return $http(req);
    }

    //Retrieve a information for a group (10-15)
    factory.getLimitinfoByKind = function(kind) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/limitinfobykind/' + kind
        };
        return $http(req);
    }



    factory.getWeekStats = function(period) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/weekstats/' + period
        };
        return $http(req);
    }


    return factory;
});
