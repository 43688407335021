'use strict';
angular.module('ChicoApp.Login')
    .controller('LoginController', function(
        $scope,
        $state,
        $cookies,
        $window,
        $location,
        Metronic,
        $cookieStore,
        $log,
        AuthFactory,
        $translate,
        localStorageService,
        $stateParams,
        scgSharedFactory
        ) {

      $log.info('init login controller', $scope.$id);

      //FORCE SSL
        /*var forceSSL = function () {
            if ($location.protocol() !== 'https' && $location.absUrl().indexOf('localhost')==-1) {
                $window.location.href = $location.absUrl().replace('http', 'https');
            }
        };*/
        //forceSSL();

      $scope.saveLogin = false;
      $scope.showKeepLoggedIn = false;

      AuthFactory.activeContext = undefined; //reset
      $cookieStore.remove('sessionId');
      $cookieStore.remove('user');
      $cookieStore.remove('pcId');
      $cookieStore.remove('oldPcId');
      $cookieStore.remove('version');

      if (localStorageService.get('username')) {
        //This is login via Portal App and saved username!
          $scope.saveLogin = true;
        $scope.username = localStorageService.get('username');
        $scope.password = localStorageService.get('password');
      }


      if ($stateParams.fullpagereload == true) {
        //$log.info('RELOAD');
        Metronic.blockUI({animate: true});
        setTimeout(function() {
          window.document.location.reload(true);
        }, 3000);
        //
        //$log.info('RELOAD DONE');
      }else {

        $scope.showLoginFailed = false;
        $log.info('NEEDSSYNC=FALSE 6');
        scgSharedFactory.needsSync = false;

        if ($stateParams.logintoken) { //direct login via safleld.net form
            Metronic.blockUI({animate: true});
            $log.info('tokenlogin: ', $stateParams.logintoken);
            var options = {logintoken:$stateParams.logintoken};
            AuthFactory.login(null, null, options);
        } else if ( $stateParams.reportlogintoken ) { //direct login for report role
            Metronic.blockUI({animate: true});
            var options = {reportlogintoken:$stateParams.reportlogintoken};
            $log.info('reporttokenlogin: ', $stateParams.reportlogintoken);
            AuthFactory.login(null, null, options);
        } else { //normal login (display login form)

            //New: do redirect to salfeld main login
            var currenturl = $location.absUrl();
            if (! $stateParams.jmpid && currenturl.indexOf('loginv2')==-1) {
                $log.info('prefilluser is empty!, prefilluser=', $stateParams.prefilluser);
                if (currenturl.indexOf('localhost:') == -1) {
                    AuthFactory.gotoSalfeldLoginPage($stateParams.prefilluser);
                    return;
                }
            }


            Metronic.unblockUI();
        }

        //Keep me logged in and RESET procedure (if /login/ is called again)
        if (window.location.hash.match('loginv2') != null || $state.current.name == 'loginv2' || localStorageService.get('loginv2')) {
          $scope.showKeepLoggedIn = true;
          localStorageService.set('loginv2', true);
        }

        $scope.gotoLogin = function() {
            $log.info('GOTO LOGIN');
            AuthFactory.logout(true);
          }


        $scope.login = function() {

              if (!$scope.username || !$scope.password) {
                $scope.showLoginFailed = true;
              } else {
                Metronic.blockUI({animate: true});
                AuthFactory.login($scope.username, $scope.password)
                          .then(function success(successResponse) {
                            $log.info(successResponse);

                            if ($scope.saveLogin) {
                              //save userdata to localstorage
                              localStorageService.set('username',$scope.username);
                              localStorageService.set('password',$scope.password);
                            } else {
                              localStorageService.set('username',undefined);
                              localStorageService.set('password',undefined);
                            }

                          }, function error(errorResponse) {
                            Metronic.unblockUI();
                            $log.info('errorResponse:' , errorResponse);

                            if (errorResponse.indexOf('next 403') != -1) {
                              $log.info('ITS A 403 HANMDLE IT!');
                              $scope.showLoginFailed = false;
                              $scope.showLoginOld = true;
                              return;
                            }
                            $scope.showLoginFailed = true;
                            $log.info(errorResponse);
                          });
              }
            };

        $log.info('STATE_PARAMS:', $stateParams);
        if ($stateParams.jmpid) {
          scgSharedFactory.jumpId = $stateParams.jmpid;
          $log.info('Yez, we have a jmpId');
        }

        $scope.keepLoggedInOnChage = function() {
            if (!$scope.saveLogin) {
              localStorageService.set('username',undefined);
              localStorageService.set('password',undefined);
            }
          };

        //Handle language
        $scope.loginLang =  $stateParams.lang;
        //$log.info('lang=' + $scope.loginLang);
        if ($scope.loginLang == 'de') {
          $cookies.put('language','de_DE');
        }

        if ($scope.loginLang == 'en') {
          $cookies.put('language','en_US');
        }

        if (!$cookies.get('language')) {
          $translate.use('de_DE');
        }else {
          $translate.use($cookies.get('language'));
        }

        $scope.forgotPW = function() {
          $state.go('pwrequest');
        };

        if ($stateParams.prefilluser) {
          $scope.username = $stateParams.prefilluser;
          angular.element('input[name^=\'password\']').focus();
        }
      }

    });
