'use strict';

angular.module('ChicoApp.CC-Settings')
    .controller('SCG-Modal-AddTans-Controller', function(
        $scope,
        $http,
        $log,
        $translate,
        $state,
        $modalInstance,
        AuthFactory,
        _,
        API,
        apiFunctions,
        ccListsAllFactory,
        scgSharedFactory
    ) {

        //handle tan input and okay button in modal
        $scope.username = $translate.instant('BUTTON.ALL');

        // get all usernames for active device
        $scope.usernames = _.map(AuthFactory.activeDevice.users,function(user) {
            return user.username;
          });
        $scope.usernames.unshift($scope.username);

        //Config for steppers
        $scope.tanMinutes = 30;
        $scope.tanMinutesMin = 5;
        $scope.tanMinutesMax = 600;
        $scope.tanMinutesStep = 5;
        $scope.tanQty = 1;
        $scope.tanQtyMin = 1;
        $scope.tanQtyMax = 20;

        $scope.addTans = function() {

            var tans = [];
            for (var i = 0; i < $scope.tanQty; i++) {
              tans.push({
                  kind: 28,
                  entry: Math.floor(Math.random() * (999999 - 100001)) + 100001,
                  description: $scope.tanMinutes.toString()
                });
            }

            var URIUsername = $scope.username == $translate.instant('BUTTON.ALL') ? 'allusers' : $scope.username.toLowerCase();

            var req = {
                method: 'POST',
                //old approach, url: API.url + URIUsername +  '/ccliststans', NEW approach below due to roaming:
                url: apiFunctions.get_cc_base_url() + 'ccliststans',
                data: tans
              };

            $http(req).then(function success() {
                        scgSharedFactory.needsSync = true;
                        $state.go($state.current, {}, {reload: true});
                        $modalInstance.close('added new Tans');
                      })
                      .catch(function error(error) {
                        $log.error('Error while saving Tans' , error);
                      });
          };

      });
