'use strict';
angular.module('ChicoApp.Licence')

    .controller('LicenceController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $cookies,
        $timeout,
        DeviceFactory,
        AuthFactory,
        modalFactory,
        $log,
        API,
        apiFunctions,
        licenceFactory,
        scgLicenceFactory,
        menuFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $scope.licInfo = {};

      //Defaults
      $scope.hintVisible = false;
      $scope.busyButton = false;
      $scope.loginEmail = '';
      $scope.serialNoArr = {};
      $scope.emailLicencesFor = '';

      $scope.failNoticeMsg = '';
      $scope.currentRadio = 1;

      $log.info('LICENCE active context' , AuthFactory.activeContext);

      //scgGlobalSpinnerFactory.unblockUI();
      AuthFactory.activeLicence.showSideBar = false;
      menuFactory.hideSidebar();

      $scope.licInfo = AuthFactory.activeLicence;
      $scope.licInfo.remainingDays = AuthFactory.activeLicence.expireDays; //needed for if days<5
      $scope.licInfo.expireDate = AuthFactory.activeLicence.expireDate;
      $scope.licInfo.serial = AuthFactory.activeLicence.serial;

      $scope.licInfo.remaining = $translate.instant('LICENCE.SUB_TRIAL').replace('%%', AuthFactory.activeLicence.expireDays);
      $scope.licInfo.remaining_short = $translate.instant('LICENCE.SUB_TRIAL_SHORT').replace('%%', AuthFactory.activeLicence.expireDays);
      $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_TRIAL');  //default
      if ($scope.licInfo.serial != '') {
        $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_MODAL');
      } //overwrite radio1 if there is serial

      $scope.orderURL = $translate.instant('URL.ORDER_CC'); //CC is default
      if (AuthFactory.activeContext == 'cb') {
        $scope.orderURL = $translate.instant('URL.ORDER_CB');
      }

      $scope.licInfo.expireText = '';
      if ($scope.licInfo.remainingDays < 1) {
        if ($scope.licInfo.serial == '') {
          $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_TRIAL');
        } else {
          $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_FULL');
        }
        $scope.licInfo.expireText = $scope.licInfo.expireText.replace('%%', scgSharedFactory.getLocalizedDateString($scope.licInfo.expireDate));
       if ($scope.licInfo.blockedSerial!='') {

           //$scope.licInfo.expireText = $translate.instant('LICENCE.BLOCKED') + ":" +$scope.licInfo.blockedSerial ;
           $scope.licInfo.expireText = "BLOCKED!";
       }
      }

      scgLicenceFactory.getSerialInfo()
            .then(function success(response) {
              $log.info('editSerial Success', response);

              $scope.licInfo = response.data;
              $scope.licInfo.remainingDays = response.data.expireDays; //needed for if days<5
              $scope.licInfo.expireDate = response.data.expireDate;
              $scope.licInfo.serial = response.data.serial;

              $scope.licInfo.remaining = $translate.instant('LICENCE.SUB_TRIAL').replace('%%', response.data.expireDays);
              $scope.licInfo.remaining_short = $translate.instant('LICENCE.SUB_TRIAL_SHORT').replace('%%', response.data.expireDays);
              $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_TRIAL');  //default
              if ($scope.licInfo.serial != '') {
                $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_MODAL');
              } //overwrite radio1 if there is serial

              $scope.licInfo.expireText = '';
              if ($scope.licInfo.remainingDays < 1) {
                if ($scope.licInfo.serial == '') {
                  $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_TRIAL');
                } else {
                  $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_FULL');
                }
                $scope.licInfo.expireText = $scope.licInfo.expireText.replace('%%', scgSharedFactory.getLocalizedDateString($scope.licInfo.expireDate));
              }
              $log.info('editSerial DONE');

              scgGlobalSpinnerFactory.unblockUI();
            },
            function error() {
              $log.info('editSerial ERROR');
            });

      scgLicenceFactory.listserialemail()
            .then(function success(response) {
              var serialData = response.data;
              $scope.serialNoArr = serialData.serialEntries;
              $scope.loginEmail = serialData.loginEmail;
              $scope.emailLicencesFor = $translate.instant('LICENCE.FOUND_LICENCES').replace('%%',$scope.loginEmail);
            },
            function error() {
            });

      $scope.radioClick = function() {
        $scope.currentRadio = 0;
      };

      $scope.showHint = function() {
        $log.info('showHint called!');
        $scope.hintVisible = true;
      };

      $scope.gotoLicenceInfo = function() {
        //$state.go('licenceinfo');
      };


      $scope.deleteDevice = function()
      {
        $log.info('deleteDevice goes here');
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.SURE'),
            message: $translate.instant('MSG.DELETEDATA') + '<br>' + $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 3,
            refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
              Metronic.blockUI({animate: true});

              var myurl = API.url + 'action/deletedevice';
              var req = {
                method: 'GET',
                url: myurl
              };
              $http(req)
                  .then(function success() {
                    $log.info('Done with HTTP 200!');
                    Metronic.unblockUI();
                    AuthFactory.logout(true);
                  });
            },
          },
          cancel: function() {
            $log.info('canceled!');
          }
        });
      }


      $scope.ok = function() {

        menuFactory.showSidebar();

        if ($scope.currentRadio === 1) {
          $log.info('Current radio=1, licInfo:' , $scope.licInfo);
          //Trial or licence
          if ($scope.licInfo.remainingDays <= 0) {
            AuthFactory.logout(true);
            return;
          }
          $scope.gotoDash();
          return;
        }

        $log.info('Entered serial was:' + $scope.licInfo.serial + ' lang:' + $translate.use());
        $log.info('Entered serial context was:' + AuthFactory.activeContext);

        if ($scope.licInfo.serial === undefined || $scope.licInfo.serial == '' || $scope.licInfo.serial.length < 5) {
          $scope.failNoticeMsg = $translate.instant('LICENCE.SERIAL_WRONG');
          return;
        }
        $scope.busyButton = true;
        var language = 'de'; //default
        if ($translate.use() == 'en_US') {
          language = 'en';
        }
        var req = {serial: $scope.licInfo.serial, lang: language, context: AuthFactory.activeContext};
        scgLicenceFactory.enterSerial(req)
                .then(function success(response) {
                  $log.info('LISTSERIAL Success', response);
                  if (response.data.success === true) {
                    $scope.busyButton = false;
                    $scope.showSuccess(response.data.msg);
                    $scope.gotoDash();
                  } else {
                    $scope.busyButton = false;
                    $scope.failNoticeMsg = response.data.msg;
                  }
                },
                function error() {
                  $log.info('LISTSERIAL ERROR');
                  $scope.busyButton = false;
                  $scope.failNoticeMsg = $translate.instant('ERROR.UNKNOWN');
                });

        return;

      };

      scgLicenceFactory.listserial()
            .then(function success(response) {
              var serialData = response.data;
              $scope.serialNoArr = serialData.serialEntries;
              $scope.loginEmail = serialData.loginEmail;
              $scope.emailLicencesFor = $translate.instant('LICENCE.FOUND_LICENCES').replace('%%',$scope.loginEmail);
            });

      $scope.showSuccess = function(licToDate) {
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: $translate.instant('LICENCE.SUCCESS').replace('%%',scgSharedFactory.getLocalizedDateString(licToDate)),
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');

              $scope.gotoDash();
            }
          }
        });
      };

      $scope.gotoDash = function() {
        scgGlobalSpinnerFactory.unblockUI();
        AuthFactory.activeLicence.showSideBar = true;
        if (AuthFactory.activeContext == 'cc') {
          $state.go('cc-dashboard-v3');
        } else {
            $log.info('cb-dash-v3 in licence');
            if ($cookies.get('cb2')=="true") {
                $state.go('cb-dashboard'); }
            else {
                $state.go('cb-dashboard-v3');
            }
        }
      };

    });
