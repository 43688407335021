'use strict';
angular.module('ChicoApp.CB-Webfilter')

    .controller('CB-WebfilterController', function(
      $rootScope,
      $modal,
      $scope,
      $state,
      cbListsFactory,
      cbWebFilterFactory,
      modalFactory,
      $log,
      Metronic,
      _,
      $http,
      $templateCache,
      scgGlobalSpinnerFactory,
      ccInternetMiscFactory,
      scgSharedFactory) {

      $scope.data = {};
      $scope.data.filterLevel = 2;
      $scope.listCounters = {};
      $scope.ageStr = '';
      $scope.selectModel = 0;
      //----------------------------------------

      // templates are already added to templatecache during buildprocess
      // $http.get('templates/timetable/timetable.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-content.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-window.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-content-timelimited.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-content-bonus.html', {cache: $templateCache});
      // $http.get('templates/durationPicker/durationPicker.html', {cache: $templateCache});


      $scope.importExport = function() {
          $log.info('We are importExport this is select=', $scope.selectModel);
          if ($scope.selectModel==0) {$scope.exportWeb(2);}
          if ($scope.selectModel==1) {$scope.exportWeb(1);}
          if ($scope.selectModel==2) {$scope.importWeb(2);}
          if ($scope.selectModel==3) {$scope.importWeb(1);}
      }


        $scope.importWeb = function(kind) {
          //kind=1 blacklist, kind=2 whitelist
            $log.info('IMPORT WEB');
            modalFactory.importModal.open({
                modal_settings: {
                    title: 'aaaa',
                    message: 'xx',
                    butOkLabel: 'xx',
                    butCanelLabel: 'xx',
                    refreshStateAfterClose: false
                },
                data: {
                    kind: kind,
                    context: 'cb'
                },
                callbacks: {
                    ok: function() {
                    },
                    cancel: function() {
                    }
                }
            });
        };


        $scope.exportWeb = function(kind) {
            //kind=1 blacklist, kind=2 whitelist
            scgGlobalSpinnerFactory.blockUI();
            cbWebFilterFactory.export(kind).then(function success(response) {
                scgGlobalSpinnerFactory.unblockUI();
                var res = String(response.data);
                var list = res.replace(new RegExp(',', 'g'), '\n');
                $scope.exportlist = list;
                var modalInstance = $modal.open({
                    animation: false,
                    templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-modal-export.html',
                    windowTemplateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-modal-window-small.html',
                    scope: $scope
                });
            });
        }


      cbWebFilterFactory.getSettings()
                .then(function success(response) {

                  $scope.data = response.data;
                  $scope.data.filterLevel = 2;  //Dieser Wert wird berrechnet
                  if ($scope.data.isNewWebsitesBlocked == true) {
                    $scope.data.filterLevel = 3;
                  }
                  if ($scope.data.isWebLogActive == false) {
                    $scope.data.filterLevel = 1;
                  }

                  $log.info('cbWebFilterFactory returned data:' , $scope.data);
                  if ($scope.data.webFilterAge != '0') {
                    $scope.ageStr = $scope.data.webFilterAge + '+';
                  } else {
                    $scope.ageStr = '';
                  }

                if ($scope.data.filterLevel ==3) $state.go('cb-webfilter.tabs.allowed');

                  //add watchers
                  _.each($scope.data, function(v, k) {
                    $scope.$watch('data.' + k, function(new_val, old_val) {
                      if (new_val != old_val) {
                        var changed_object = {};
                        changed_object[k] = new_val;
                        if ($scope.data.filterLevel == 1) {
                          $scope.data.isWebLogActive = false;
                        }
                        if ($scope.data.filterLevel > 1) {
                          $scope.data.isWebLogActive = true;
                        }
                        if ($scope.data.filterLevel == 2) {
                          $scope.data.isNewWebsitesBlocked = false;
                        }
                        if ($scope.data.filterLevel == 3) {
                          $scope.data.isNewWebsitesBlocked = true;
                        }
                        $log.info('Changed, FilterLevel now ',$scope.data.filterLevel);
                        scgSharedFactory.needsSync = true;
                        cbWebFilterFactory.setSetting(changed_object);
                        if ($scope.data.filterLevel ==3) $state.go('cb-webfilter.tabs.allowed');
                      }
                    }, true);
                  });
                  Metronic.unblockUI();
                });

      cbListsFactory.getListCounters().then(function(response) {
        $log.info('list counters', response);
        $scope.listCounters = response.data;
      });

      window.scrollTo(0,0);

    });

