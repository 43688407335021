'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Roaming-Controller', function(
    $scope,
    $http,
    $log,
    $cookies,
    $modalInstance,
    API,
    apiFunctions,
    scgSharedFactory,
    AuthFactory,
    modal_settings)
    {

    // *********** INFO **************************************************************************************
    // Roaming is stored a F/T and Number, like "F1" (FULL roaming group #1) or "T2" (TIMES roaming group #2)
    // ********************************************************************************************************

    $scope.modal_settings = modal_settings; //transfer settings to scope for use in template
    $scope.currentContext = AuthFactory.activeContext; //will be cc or cb as String
    $scope.activeDevice = AuthFactory.activeDevice;
    $scope.loadingRoaming = true;
    $scope.roamingAccountNo = 0;
    $scope.timesOnly = false;
    $scope.roamingUsers = {};

    $scope.loadSettings = function() {
        if ($scope.currentContext == 'cc') {
            $scope.contextUrl = apiFunctions.get_cc_base_url() + 'ccsettings/roaming';
        } else {
            $scope.contextUrl = API.url + '/cbsettings/roaming';
        }
        var req = {
            method: 'GET',
            url: $scope.contextUrl
        };
        $http(req).then(function success(response) {
            $scope.loadingRoaming = false;
            $log.info('roaming http arrived,', response.data);
            $scope.roamingAccountNo = -1; //default
            if (response.data.roaming) {
                $log.info('roaming arried data.roaming=',response.data.roaming);
                var tmp = response.data.roaming;
                $log.info('roaming arried data.tmp=',tmp);
                $log.info('roaming arried data.tmp.length=',tmp.length);
                $log.info('roaming arried data.tmp.substring=',tmp.substring(1,2));
                if (tmp.length>1 && tmp.substring(0,1)=="T") {
                    $scope.timesOnly=true;
                }
                if (tmp.length>0 && tmp.substring(0,1)=="F") {
                    $scope.timesOnly=false;
                }
                if (tmp.length>1) {
                    $scope.roamingAccountNo=tmp.substring(1,2);
                    $log.info("HEADINFO IN MODAL LOAD, ROAMING ACTIVATED");
                    scgSharedFactory.hasRoaming=true;
                }

            }
        })
            .catch(function error(error) {
                $scope.loadingRoaming = false;
                $log.error('Error while loading roaming', error);
            });
    }

    $scope.saveSettings = function()
    {
        $log.info('save settings, timesOnly=' + $scope.timesOnly);
        if ($scope.currentContext=='cc') {
            $scope.postUrl = apiFunctions.get_cc_base_url() + '/ccsettings';
        } else {
            $scope.postUrl = API.url + '/cbsettings';
        }
        var prefix = "F"; //default
        if ($scope.timesOnly==true) {
            prefix = "T";
        }

        var roamingEntry = prefix + $scope.roamingAccountNo.toString();
        if ($scope.roamingAccountNo<=0) {
            roamingEntry = "";
        }

        $log.info('roaming save settings roamingEntry=' + roamingEntry + '|url=' + $scope.postUrl);
        var myarr = {"roaming" : roamingEntry};
        var req = {
            method: 'POST',
            url: $scope.postUrl,
            data: myarr
        };

        if (roamingEntry != "")  {
            scgSharedFactory.hasRoaming=true;
        } else {
            scgSharedFactory.hasRoaming=false;
        }

        $http(req).then(function success(response) {
            $log.info("HEADINFO IN MODAL SAVE, ROAMING ACTIVATED");

        })
            .catch(function error(error) {
                $log.error('error: roaming saved');
            });
    }



       var showRoamingDevicesV2 = function(roamingGroup) {
            $log.info('show Roaming devices roamingGroup=' + roamingGroup);

            ///api/info/getroamingusers/3/allusers
           /*if ($scope.currentContext == 'cc') {
               $scope.contextUrl = apiFunctions.get_cc_base_url() + 'getroamingusers';
           } else {
               $scope.contextUrl = API.url + '/cbsettings/?key=getroamingusers';
           }*/


            $scope.contextUrl = API.url + '/info/getroamingusersforgroup/' + roamingGroup;
            var req = {
               method: 'GET',
               url: $scope.contextUrl
           };
           $http(req).then(function success(response) {
               $scope.roamingUsers = response.data;
               $log.info("roamingUsers", $scope.roamingUsers);
           });


           }

        $scope.$watch(function () {
            return $scope.roamingAccountNo;
        }, function (nv, ov) {
            //$log.info('Roaming new accountNo A:', $scope.roamingAccountNo);
            if ((nv && !ov) || (nv && ov && nv!=ov)) {
                $log.info('Roaming new accountNo B:', $scope.roamingAccountNo);
                showRoamingDevicesV2($scope.roamingAccountNo);
                //$scope.cancel();
            }
        }, true);




        $scope.$watch(function () {
            return AuthFactory.activeDevice;
        }, function (nv, ov) {
            if ((nv && !ov) || (nv && ov && nv!=ov)) {
                $log.info('Roaming watcher triggered DEVICE CHANGE!');
                $scope.cancel();
            }
        }, true);
        // ------------------------------------------------------------------------------------------


        $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
      };

       $scope.ok = function(return_val) {
        $modalInstance.close(return_val);
    };



        //execute the load func
        $scope.loadSettings();
        showRoamingDevicesV2($scope.roamingAccountNo);



    });
