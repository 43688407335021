angular.module('ChicoApp').factory('cbAppFilterFactory', function(
    $http,
    $cookies,
    API,
    $log,
    apiFunctions
  ) {

  var factory = {}; //Dont forget to init, this is also final return value!

  //all ng-models from specific sub-template must be listed here
  //---------------------------------------------------------------
  var apikeys = [
      'isAppFilterActive',
      'isAppFilterNewBlocked',
      'defaultAppKind'
  ];

  var apikeys_param = apiFunctions.obj_to_url_params(apikeys);

  //main function called by controller
  //---------------------------------------------------------------
  factory.getSettings = function() {
    return factory.getByParams(apikeys_param);
  };

  //only helper func, is called from above func
  //---------------------------------------------------------------
  factory.getByParams = function(params) {
    var req = {
      method: 'GET',
      url: API.url + 'cbsettings' + params
    };
    $log.info('return val=' + $http(req));
    return $http(req);
  };

  //is called by watcher from controller
  //---------------------------------------------------------------
  factory.setSetting = function(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'cbsettings/',
      data: obj
    };
    return $http(req);
  };

  return factory;

});
