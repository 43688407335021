angular.module('ChicoApp.Shared').controller('SidebarController', function(
    $rootScope,
    $cookies,
    $scope,
    $log,
    _,
    $state,
    $window,
    $http,
    API,
    AuthFactory,
    menuFactory,
    DeviceFactory) {

  $scope.$state = $state;
  $scope._ = _;
  $scope.currentActiveDevice = null;

  //show Menu if there is a SessionId
  $scope.$watch(function() {
    return AuthFactory.loggedin();
  }, function(newVal) {
    if (newVal == true) {
      $scope.loggedin = true;
    }else {
      $scope.loggedin = false;
    }
  });

  $scope.$watch(function() {
    return menuFactory.activeMenu;
  }, function(activeMenu) {
    $log.info('MENU active' , activeMenu);
    $scope.menu = activeMenu;
  });

  $scope.logout = function(param) {

    var req = {
      method: 'GET',
      url: API.url + 'action/sendpushall',
    };

    $http(req);

    AuthFactory.logout(param);
  };

  $scope.sync = function() {
    $log.info('SYNC HIT');
  };

  var w = angular.element($window);
  $scope.menutype = $window.matchMedia('(max-width: 991px)').matches ? 'mobil' : 'desktop';

  w.bind('resize', function() {
    if ($window.matchMedia('(max-width: 991px)')
        .matches) {
      $scope.menutype = 'mobil';
    } else {
      $scope.menutype = 'desktop';
    }

    $scope.$apply();
  });

  $rootScope.$watch(function() {
    return AuthFactory.activeDevice;
  }, function(activeDevice) {
    DeviceFactory.getDeviceById(activeDevice.deviceId).then(
            function success(device) {
              $scope.currentActiveDevice = device;
                $log.info('SIDEBAR:', $scope.currentActiveDevice);
                $scope.currentActiveDevice.appVersion = ($scope.currentActiveDevice || "0.0.000").versionInfo.split("|")[0].replace(/\./g, "");
                $log.info('SIDEBAR deviceType=',$scope.currentActiveDevice.deviceType )
            },function error(error) {
              $log.info(error);
            }
        );
  },true);

});
