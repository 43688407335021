/*thisisaplaceholderforAPI-Factories*/
angular.module('ChicoApp').factory('scgDataTableFactory', function(
  $rootScope,
  $translate,
  $state,
  AuthFactory,
  DTOptionsBuilder,
  DTColumnDefBuilder,
  modalFactory,
  ccListsFactory,
  ccListsAllFactory,
  cbListsFactory,
  _,
  $log,
  scgSharedFactory) {

  var factory = {};

  factory.currentContext = AuthFactory.activeContext; //will be cc or cb as String
  factory.currentListFactory = null;

  //maintain active context info
  $rootScope.$watch(function() {
    return AuthFactory.activeContext;
  }, function(nv) {
    if (nv) {
      factory.currentContext = nv;
      refreshCurrentListFactory();
    }
  });

  var refreshCurrentListFactory = function() {
    if (factory.currentContext == 'cc') {
      factory.currentListFactory = ccListsFactory;
    }else if (factory.currentContext == 'cb') {
      factory.currentListFactory = cbListsFactory;
    }
  };

  refreshCurrentListFactory();

  // console.log('current context', factory.currentContext);

  factory.getDefaultDtOptions = function(contrScope) {

    var ret = DTOptionsBuilder.newOptions()
        .withDisplayLength(15)
        .withOption('bAutoWidth', false)
        .withOption('bFilter', false)  //Disable Search Input
        .withOption('bInfo', false)
        .withOption('pagingType', 'simple_numbers')
        .withOption('order', [[1, 'asc']])
        .withOption('responsive', true)
            .withLanguage({
              'emptyTable': $translate.instant('MISC.NODATA'),
              'oPaginate': {
                'sNext':     '»',
                'sPrevious': '«'
              }
            });

    //if we have a scope, add the footerCallback, but not override it
    if (contrScope && contrScope.footerCallback) {
      ret.withOption('footerCallback', function(tfoot, data) { contrScope.footerCallback(tfoot, data); });
    }

    // if(contrScope && contrScope.fnDrawCallback){
    //    ret.withOption('fnDrawCallback', function(oSettings){ contrScope.fnDrawCallback(oSettings); });
    // }

    ret.withOption('fnDrawCallback', function() {
      $rootScope.$emit('DTfnDrawCallback');
    });

    ret.withBootstrap();

    return ret;

  };

  factory.getDefaultDtColumnDefs3Cols = function() {

    return [
        DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','30px'),
        DTColumnDefBuilder.newColumnDef(1),
        DTColumnDefBuilder.newColumnDef(2).notSortable()
    ];

  };

  factory.getDefaultDtColumnDefs4Cols = function() {

    return [
        DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','30px'),
        DTColumnDefBuilder.newColumnDef(1),
        DTColumnDefBuilder.newColumnDef(2),
        DTColumnDefBuilder.newColumnDef(3).notSortable()
    ];

  };

  factory.getDefaultDtColumnDefs5Cols = function() {

    return [
        DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','30px'),
        DTColumnDefBuilder.newColumnDef(1),
        DTColumnDefBuilder.newColumnDef(2),
        DTColumnDefBuilder.newColumnDef(3).renderWith(function(data) {
          return scgSharedFactory.getLocalizedDateString(data);
        }),
        DTColumnDefBuilder.newColumnDef(4).notSortable(),
    ];

  };

    factory.getDefaultColumnsIconTable = function() {

        return [
            DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','30px'),
            DTColumnDefBuilder.newColumnDef(1).notSortable().withOption('sWidth','50px'),
            DTColumnDefBuilder.newColumnDef(2),
            DTColumnDefBuilder.newColumnDef(3).withOption('sWidth','80px'),
            DTColumnDefBuilder.newColumnDef(4).notSortable().withOption('sWidth','30px'),
        ];
    };



    factory.getDefaultColumnsIconGroupTable = function() {

        return [
            DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','50px'),
            DTColumnDefBuilder.newColumnDef(1),
            DTColumnDefBuilder.newColumnDef(2).withOption('sWidth','80px'),
            DTColumnDefBuilder.newColumnDef(3).notSortable().withOption('sWidth','30px'),
        ];
    };



    factory.getDefaultDtColumnDefs5ColsNormal = function() {

    return [
      DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','30px'),
      DTColumnDefBuilder.newColumnDef(1),
      DTColumnDefBuilder.newColumnDef(2),
      DTColumnDefBuilder.newColumnDef(3),
      DTColumnDefBuilder.newColumnDef(4).notSortable(),
    ];

  };

  factory.getDefaultDtColumnDefs6Cols = function() {

    return [
      DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','30px'),
      DTColumnDefBuilder.newColumnDef(1),
      DTColumnDefBuilder.newColumnDef(2),
      //DTColumnDefBuilder.newColumnDef(3),
      DTColumnDefBuilder.newColumnDef(3).renderWith(function(data) {
        return scgSharedFactory.getLocalizedDateString(data);
      }),
      DTColumnDefBuilder.newColumnDef(4),
      DTColumnDefBuilder.newColumnDef(5).notSortable(),
    ];

  };

  factory.prepareScopeForTable = function(contrScope, modalStartKind) {

    //set list context (program or internet) based on kind type
    if (_.contains([1,2,3,4,9,10,26], modalStartKind)) {
      factory.context = 'prg';
    }else {
      factory.context = 'net';
    }

    contrScope.dtInstance = null; //for table plugin callback

    //inner table data array (additional fields for selection and so on)
    contrScope.data.table_inner = [];
    contrScope.data.table_selected_records = [];
    contrScope.data.table_selected_count = 0;
    contrScope.data.table_selected_bool = false;
    contrScope.data.table_bulk_buttons_enabled = false;

    contrScope.tableAllSelected = false;

    contrScope.$watch('data.table_inner', function() {

      //put selected records in own array
      contrScope.data.table_selected_records = _.where(contrScope.data.table_inner, {selected: true});

      //check if any row is selected for flag in scope
      contrScope.data.table_selected_count = contrScope.data.table_selected_records.length;

      //set boolean flag if there are selected rows
      contrScope.data.table_selected_bool = contrScope.data.table_selected_count === 0 ? false : true;

      //check if bulk buttons should be enabled
      contrScope.data.table_bulk_buttons_enabled = contrScope.data.table_selected_count === 0 ? true : false;

    }, true);

    //select all rows monitoring
    contrScope.$watch('tableAllSelected', function(new_val, old_val) {
      $log.info('all selected changed', new_val);
      if (new_val !== undefined && new_val != old_val) {
        _.each(contrScope.data.table_inner, function(obj) {
          obj.selected = contrScope.tableAllSelected;
        });
      }
    });

    contrScope.tableBulkDelete = function(context) {

      var key_arr = [];
      scgSharedFactory.needsSync = true;
      _.each(contrScope.data.table_selected_records, function(v) {
        key_arr.push(v.data.entry);
      });
      if (context == 'net') {
        factory.currentListFactory.deleteRecords(key_arr)
        .then(function success() {
          contrScope.onEditModalReturn();
        });
      }else if (context == 'prg') {
        var postArr = [];
        _.each(key_arr, function(del_rec) {
          var thisEntry = {entry: del_rec, isDeleted: true};
          postArr.push(thisEntry);
        });

        return factory.currentListFactory.postRecords(postArr)
        .then(function success() {
          $state.go($state.current, {}, {reload: true});
        }, function error() {
          $state.go($state.current, {}, {reload: true});
        });
      }

    };


    //this is used for exccludes
    contrScope.tableBulkDeleteAllUsers = function() {

      var key_arr = [];
      scgSharedFactory.needsSync = true;
      _.each(contrScope.data.table_selected_records, function(v) {
        key_arr.push(v.data.entry);
      });

        var postArr = [];
        _.each(key_arr, function(del_rec) {
          var thisEntry = {entry: del_rec, isDeleted: true};
          postArr.push(thisEntry);
        });
        $log.info('DELETE FOR ALL USERS!');
        return ccListsAllFactory.postRecords(postArr)
            .then(function success() {
              $state.go($state.current, {}, {reload: true});
            }, function error() {
              $state.go($state.current, {}, {reload: true});
            });

    };



    contrScope.onEditModalReturn = function() {
      contrScope.reload();
    };

    contrScope.fillInnerListFromApiList = function(api_list) {
      _.each(api_list, function(v) {

        var obj;
        if (v.entry) { //if this is an object
          obj = {selected: false, data: v};
        }else {
          obj = {selected: false, data: {entry: v}};
        }

        contrScope.data.table_inner.push(obj);
      });
    };

    contrScope.clearInnerList = function() {
      contrScope.data.table_inner = [];
    }

    contrScope.tableBulkEdit = function() {
      modalFactory.editModal.open({
        modal_settings: {
          mode: 'edit',
          context: factory.context,
          title: $translate.instant('HEADER.LIMITENTRIES'),
          subtitle: $translate.instant('MODAL_EDIT_RECORD.SUBTITLE'),
          newRecord: null,
          editRecords: contrScope.data.table_selected_records,
          refreshStateAfterClose: true
        },
        callbacks: {
          ok: contrScope.onEditModalReturn,
          cancel: contrScope.onEditModalReturn
        }
      });

    };

    contrScope.tableAdd = function() {

      modalFactory.editModal.open({
        modal_settings: {
          mode: 'add',
          context: factory.context,
          title: $translate.instant('MODAL_EDIT_RECORD.TITLE_ADD'),
          subtitle: $translate.instant('MODAL_EDIT_RECORD.SUBTITLE'),
          newRecord: {
            kind: modalStartKind
          },
          editRecords: null,
          refreshStateAfterClose: true
        },
        callbacks: {
          ok: contrScope.onEditModalReturn,
          cancel: contrScope.onEditModalReturn
        }
      });

    };

    contrScope.tableRowEdit = function(row_index, row_data) {

      var edit_record = {text: row_data};
      var edit_arr = [edit_record];

      modalFactory.editModal.open({
        modal_settings: {
          mode: 'edit',
          context: factory.context,
          title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
          subtitle: $translate.instant('MODAL_EDIT_RECORD.SUBTITLE'),
          newRecord: null,
          editRecords: edit_arr,
          refreshStateAfterClose: true
        },
        callbacks: {
          ok: contrScope.onEditModalReturn,
          cancel: contrScope.onEditModalReturn
        }
      });

    };

    contrScope.tableRowDelete = function(row_index, row_data) {

      factory.currentListFactory.deleteRecord(row_data)
                .then(function success() {
                  contrScope.data.table_inner.splice(row_index, 1);
                });

    };

  };

  return factory;
});
