/*thisisaplaceholderforAPI-Factories*/
angular.module('ChicoApp').factory('scgValidationFactory', function(
    $translate,
    moment
    ) {
  var f = {};

  /*
  * Validate Time Inputs aginst each other
  */
  f.validateTimelimits = function(data, addErrCallback, removeErrCallback) {

    //get the int vars for calculations
    var limitDayInt = moment.duration(data.timeLimitDay, 'minutes');
    var timeLimitWeek = moment.duration(data.timeLimitWeek, 'minutes');
    var timeLimitMonth = moment.duration(data.timeLimitMonth, 'minutes');

    if (limitDayInt * 7 > timeLimitWeek) {
      addErrCallback({
        message: $translate.instant('ERROR.DAY_LIMIT_SUM_BIGGER_THAN_WEEK_LIMIT'),
        id: 'WeekLimitHigherThanDayLimits'
      });
    } else {
      removeErrCallback('WeekLimitHigherThanDayLimits');
    }

    if (timeLimitWeek * 4 > timeLimitMonth) {
      addErrCallback({
        message: $translate.instant('ERROR.WEEK_LIMIT_SUM_BIGGER_THAN_MONTH_LIMIT'),
        id: 'MonthLimitHigherThanWeekLimits'
      });
    } else {
      removeErrCallback('MonthLimitHigherThanWeekLimits');
    }

  };

  return f;
});
