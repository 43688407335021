angular.module('ChicoApp').factory('ccDashboardFactory', function(
    $http,
    apiFunctions,
    $log,
    API) {

  var factory = {};
  var mydata = {};

  var apifields = [
      'isTimeActive',
      'isTimeLimitActive',
      'isWeekDayLimitActive',
      'blockTimesPC',
      'isAppFilterActive',
      'isWebFilterActive',
      'isPrgFilterActive',
      'isEmailReportActive',
      'infoLastSync',
      'isforcedbreak',
      'forcebreakafter',
      'extension',
      'onetimelocklevel',
      'hasunlockreq',

      'isDisableCMD',
      'isDisableRegedit',
      'isDisableControlPanel',
      'isDisableUserAccounts',
      'isDisableStartRun',
      'isDisableStartSearch',
      'isDisableTaskmanager',
      'isDisableTimeDate',
      'isDisableLockStation',
      'isDisableIcon',
      'DrvLock'
  ];

  var apifields_param = apiFunctions.obj_to_url_params(apifields);

  factory.getCCSettings = function() {
    return factory.getCCSettingsByParams(apifields_param);
  };

  //Wird von o.g. Funktion aufgerufen!
  factory.getCCSettingsByParams = function(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
    };
    return $http(req);
  };

  //is called by watcher from controller
  //---------------------------------------------------------------
  factory.setSetting = function(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'cbsettings/',
      data: obj
    };
    return $http(req);
  };

  factory.getUnlockRequests = function() {
    var req = {
      method: 'GET',
      url: API.url + 'ccusage/unlockrequests'
    };
    return $http(req);
  };

  factory.getSecureBoot = function() {
    var req = {
      method: 'GET',
      url: API.url + 'allusers/ccsettings/secureboot'
    };
    return $http(req);
  };

  factory.getCurrentExtension = function() {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings/extension'
    };
    return $http(req);
  };

  factory.getTodayTotal = function(todaystr) {
    $log.info('GetTodayTotal RUL=' + apiFunctions.get_cc_base_url() + 'ccusage/todaytotal/' + todaystr);
    var req = {
      method: 'GET',
      url:   apiFunctions.get_cc_base_url() + 'ccusage/todaytotal/' + todaystr
    };
    return $http(req);
  };

  factory.setExtensionTime = function(extTime) {
    mydata.msg = extTime;
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings/setextension',
      data: mydata
    };
    return $http(req);
  };

  factory.setOneTimeLockLevel = function(obj) {
    $log.info('TURN OFF HTTP: OBJ=', obj);
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: obj
    };
    return $http(req);
  };

  return factory;
});
