angular.module('ChicoApp', [
    'ui.router',
    'ui.router.state.events',
    'ui.bootstrap',
    'ngSanitize',
    'ngCookies',
    'pascalprecht.translate',
    'datatables',
    'datatables.bootstrap',
    'ngTagsInput',
    'ui.bootstrap.datetimepicker',
    'xeditable',
    'ngFileUpload',
    'LocalStorageModule',
    //Chico Modules
    'ChicoApp.Templates',
    'ChicoApp.Shared',
    'ChicoApp.Info',
    'ChicoApp.ChicoBrowser',
    'ChicoApp.Childcontrol',
    //'ApplicationInsightsModule'
]);

angular.module('ChicoApp').config(function ($stateProvider,
                                            $urlRouterProvider,
                                            $httpProvider,
                                            $translateProvider,
                                            $transitionsProvider,
                                            PREFEERED_LANGUAGE,
                                            /*applicationInsightsServiceProvider,*/
                                            DT_BOOTSTRAP_DEFAULT_OPTIONS) {


    //Dierk: disabled because of trouble with too many track XHR requests (e.g. logout scenario)
    /*var options = {
        applicationName: 'chicoThree',
        autoPageViewTracking: true,
        autoLogTracking: false};
    //applicationInsightsServiceProvider.configure('7632544a-bb77-41c8-b060-dd4dac64a0a6', options);*/

    $translateProvider.useSanitizeValueStrategy('escape');

    $translateProvider.useStaticFilesLoader({
        prefix: 'locales/locale-',
        suffix: '.json'
    });

    $translateProvider.preferredLanguage(PREFEERED_LANGUAGE);

    // Redirect any unmatched url
    $urlRouterProvider.otherwise('/login/');
    // Interceptor for 401

    //set static content-type header for all posts
    $httpProvider.defaults.headers.post = {
        'Content-Type': 'application/json'
    };
    $httpProvider.defaults.headers.delete = {
        'Content-Type': 'application/json'
    };
    //move pagination to the left for bootstrap data table plugin
    //DT_BOOTSTRAP_DEFAULT_OPTIONS.dom = '<\'row\'<\'col-xs-6\'l><\'col-xs-6\'f>r>t<\'row\'<\'col-xs-8\'p><\'col-xs-4\'i<"ccttoolsbuttonbar">>>';
    DT_BOOTSTRAP_DEFAULT_OPTIONS.dom = '<\'row\'<\'col-xs-6\'l><\'col-xs-6\'f>r>t<\'row\'<\'col-xs-8\'p><\'col-xs-4\'i>>';

    // scrollToTop after every successfull transition
    $transitionsProvider.onSuccess({to: '**'}, function (transition) {
        var $anchorScroll = transition.injector().get('$anchorScroll');
        $anchorScroll();
    });
    $transitionsProvider.onError({to: '**'}, function (transition) {
        var $anchorScroll = transition.injector().get('$anchorScroll');
        $anchorScroll();
    });

});

angular.module('ChicoApp').config(function ($httpProvider) {
    $httpProvider.interceptors.push(function ($q, $log, $injector, $cookies) {
        return {

            // This is the responseError interceptor
            responseError: function (rejection) {

                if (window.location.hash.match('pwchange')) {
                    $log.info('ignoring 401 because this is password change');
                    return $q.reject(rejection);
                }

                if ($cookies.get('role') && $cookies.get('role') == "report") {
                    $log.info('ignoring 401 because this is reporting role');
                    return $q.reject(rejection);
                }

                if (window.location.hash.match('cc-dashboard') && rejection.status != 401) {
                    $log.info('ignoring interceptor because of  cc-dashbaord (live screenshot!)');
                    return $q.reject(rejection);
                }

                if (rejection.status == 402) {
                    $log.info('ignoring interceptor because of 402');
                    return $q.reject(rejection);
                }

                $log.info('AAA window.location:' + window.location.hash);
                if (rejection.status == 401 &&
                    window.location.hash.match('login') == null &&
                    window.location.hash.match('loginv2') == null &&
                    window.location.hash.match('devlogin') == null &&
                    window.location.hash.match('licenceinfo') == null &&
                    window.location.hash.match('pwrequest') == null) {

                    console.log('401 error from interceptor');
                    console.log(':::::LOGOUT:::::appjs');
                    $injector.get('AuthFactory').logout(true);

                } else if (window.location.hash.match('login') == null &&
                    window.location.hash.match('loginv2') == null &&
                    window.location.hash.match('pwrequest') == null &&
                    window.location.hash.match('licenceinfo') == null) {

                    var $translate = $injector.get('$translate');
                    var errorDisplayFactory = $injector.get('errorDisplayFactory');

                    var err_message = $translate.instant('ERROR.API_CALL_FAIL');

                    errorDisplayFactory.addError({
                        'message': err_message,
                        'details': JSON.stringify(rejection, null, 2),
                        'rejection': rejection
                    });

                }

                /* If not a 401, do nothing with this error.
                 * This is necessary to make a `responseError`
                 * interceptor a no-op. */
                return $q.reject(rejection);
            }
        };
    });
});

/* Setup global settings */
angular.module('ChicoApp').factory('settings', ['$rootScope',
    function ($rootScope) {
        // supported languages
        var settings = {
            layout: {
                pageSidebarClosed: false, // sidebar state
                pageAutoScrollOnLoad: 1000 // auto scroll to top on page load
            }
        };
        $rootScope.settings = settings;
        return settings;
    }]);

/* Setup App Main Controller */
angular.module('ChicoApp').controller('AppController',
    function ($scope,
              $rootScope,
              $cookies,
              $log,
              AuthFactory,
              scgSharedFactory) {

        $scope.scgSharedFactory = scgSharedFactory;

        $scope.isReportingRole = null;
        $scope.activeUser = null;

        $scope.$watch(function () {
            return AuthFactory.userRole;
        }, function (newVal) {
            $scope.isReportingRole = newVal == 'report' ? true : false;
        });

        var userWatcher = $scope.$watch(function () {
            return AuthFactory.activeUser;
        }, function (newVal) {
            $scope.activeUser = newVal;
            if (newVal) {
                userWatcher(); //watcher killing himself after first value was set
            }
        }, true);

    });

/***
 GLobal Directives
 ***/
// Route State Load Spinner(used on page or content load)
angular.module('ChicoApp').directive('ngSpinnerBar', ['$rootScope',
    function ($rootScope) {
        return {
            link: function (scope, element) {
                // by defult hide the spinner bar
                element.addClass('hide'); // hide spinner bar by default
                // display the spinner bar whenever the route changes(the content part started loading)
                $rootScope.$on('$stateChangeStart', function () {
                    element.removeClass('hide'); // show spinner bar
                });
                // hide the spinner bar on rounte change success(after the content loaded)
                $rootScope.$on('$stateChangeSuccess', function () {
                    element.addClass('hide'); // hide spinner bar
                    angular.element('body').removeClass('page-on-load'); // remove page loading indicator
                });
                // handle errors
                $rootScope.$on('$stateNotFound', function () {
                    element.addClass('hide');
                });
                // handle errors
                $rootScope.$on('$stateChangeError', function () {
                    element.addClass('hide');
                });
            }
        };
    }
]);

// Handle global LINK click
angular.module('ChicoApp').directive('a', function () {
    return {
        restrict: 'E',
        link: function (scope, elem, attrs) {
            if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
                elem.on('click', function (e) {
                    e.preventDefault(); // prevent link click for above criteria
                });
            }
        }
    };
});
// Handle Dropdown Hover Plugin Integration
angular.module('ChicoApp').directive('dropdownMenuHover', function () {
    return {
        link: function (scope, elem) {
            elem.dropdownHover();
        }
    };
});
//Insert Scrolling Function for Dropdown Content
angular.module('ChicoApp').directive('dropdownMenuScroll', function (Metronic) {
    return {
        link: function ($scope, elem) {
            Metronic.initSlimScroll(elem);
        }
    };
});
/* Init global settings and run the app */
angular.module('ChicoApp').run(function ($rootScope,
                                         settings,
                                         $window,
                                         $state,
                                         $log,
                                         $translate,
                                         $cookies,
                                         $http,
                                         $templateCache,
                                         AuthFactory,
                                         DeviceFactory,
                                         editableOptions,
                                         API) {

    // $http.get('templates/licence/templates/licence.html', {cache: $templateCache});

    $rootScope.$state = $state;
    //If there is a session-id in the cookie we attach it to the header for crossplattform purposes (localhost vs salfeld)

    if (API['add-dev-headers'] == true) {
        if ($cookies.get('sessionId')) {
            $http.defaults.headers.common['X-sessionId'] = $cookies.get('sessionId');
        }
        if ($cookies.get('pcId')) {
            $http.defaults.headers.common['X-pcId'] = $cookies.get('pcId');
        }

        /*if(AuthFactory.activeContext=='cc'){
            AuthFactory.checkCurrentDeviceLicenceCC();
        }*/
    }
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {

        //if this is reporting role and we are going somewhere other than report state, logout
        var isReport = $cookies.get('role') == 'report' ? true : false;

        if (isReport && (fromState.name == 'cb-usage.reports' || fromState.name == 'cc-usage.reports')) {
            event.preventDefault();
            $log.info('This is reporting role, stopping state change and logging out user');
            AuthFactory.logout();
            //$state.go('login');
            $state.go('login-logoff-success');
        }

        $log.info('going to state: ' + toState.name + ' from state: ' + fromState.name);
        if (toState.Authorization === undefined && !AuthFactory.loggedin()) {
            event.preventDefault();
            $log.info('WACHTA APP.JS');
            $state.go('login');
        }

        // if (toState.redirectTo) {
        //   event.preventDefault();
        //   $state.go(toState.redirectTo, toParams);
        // }

        if ($cookies.get('language') == 'en_US') {
            $translate.use('en_US');
        } else {
            $translate.use('de_DE');
        }

    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

        if ($window._gaq) {
            _gaq.push(['_trackPageview', toState.name]);
        }

    });

    $rootScope.$on('$translateChangeSuccess', function (evt, lng) {

        editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
        var language = lng.language;

        if (language == 'de_DE') {

            $rootScope.globalDateFormat = 'dd.MM.yyyy';
            $rootScope.globalDateFormatWithMinutes = 'dd.MM.yyyy HH:mm:ss';
            $log.info($rootScope.globalDateFormat);
            $window.moment.locale('de', {
                months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
                monthsShort: 'Jan._Feb._Mär._Apr._Mai_Jun._Juli_Aug._Sept._Okt._Nov._Dez.'.split('_'),
                weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
                weekdaysShort: 'Son._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
                weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
                longDateFormat: {
                    LT: 'HH:mm',
                    LTS: 'hh:mm:ss',
                    L: 'DD.MM.YYYY',
                    LL: 'MMMM Do YYYY',
                    LLL: 'DD.MM.YYYY HH:mm:ss',
                    LLLL: 'dddd, MMMM Do YYYY LT'
                }
            });

        } else {

            $rootScope.globalDateFormat = 'dd MMM yyyy';
            $rootScope.globalDateFormatWithMinutes = 'dd MMM yyyy HH:mm';
            $rootScope.globalDateFormatSpecialLimits = 'dd MMM yyyy HH:mm:ss';
            $log.info($rootScope.globalDateFormat);
            $window.moment.locale(language, {
                longDateFormat: {
                    LT: 'HH:mm',
                    LTS: 'hh:mm:ss',
                    L: 'MM/DD/YYYY',
                    LL: 'MMMM Do YYYY',
                    LLL: 'DD. MMM YYYY HH:mm:ss',
                    LLLL: 'dddd, MMMM Do YYYY LT'
                }
            });
        }

    });

});
