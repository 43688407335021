angular.module('ChicoApp.Shared').factory('menuFactory', function ($rootScope,
                                                                   $state,
                                                                   $log,
                                                                   $timeout,
                                                                   $cookies,
                                                                   $location,
                                                                   AuthFactory) {
    var menu = {};

    var internMenu = {
        name: 'Internal Menu',
        menuItems: [{
            title: 'KISIKAT',
            icon: 'fa-home',
            state: 'internal.kisikat',
            visibleForDeviceTypes: [1, 2, 10, 11,30]
        }, {
            title: 'LIZENZEN',
            icon: 'fa-home',
            state: 'internal.lizenzen',
            visibleForDeviceTypes: [1, 2, 10, 11,30]
        },{
            title: 'SIDEBAR.SYNC',
            icon: 'fa-home',
            state: 'cc-sync',
            visibleForDeviceTypes: [1, 2, 10, 11,30]
        }]
    };


    var ccMenu = {
        name: 'Childcontrol Menu',
        menuItems: [{
            title: 'CC_DASH.START',
            icon: 'fa-home',
            state: 'cc-dashboard-v3',
            visibleForDeviceTypes: [1, 2, 10, 11]
        }, {
            title: 'CC_DASH.TIMELIMITS',
            icon: 'fa-desktop',
            state: 'cc-timelimits',
            visibleForDeviceTypes: [1, 2, 10, 11],
            submenu: [{
                title: 'CC_DASH.PCTIMELIMIT',
                icon: 'fa-desktop',
                state: 'cc-timelimits.pc',
                visibleForDeviceTypes: [1, 2, 10, 11]
            }, {
                title: 'CC_DASH.INETTIMELIMIT',
                icon: 'fa-globe',
                state: 'cc-timelimits.internet',
                visibleForDeviceTypes: [1, 2, 10, 11]
            }, {
                title: 'CC_DASH.SPECIALTIMELIMIT',
                icon: 'fa-wrench',
                state: 'cc-timelimits.special',
                visibleForDeviceTypes: [1, 2, 10, 11]
            }, {
                title: 'CC_DASH.ROAMING',
                icon: 'fa-files-o',
                state: 'cc-timelimits.roaming',
                visibleForDeviceTypes: [1, 2, 10, 11],
                visibleFromMinAppVersion: 16002253
            }]
        }, {
            title: 'CB_DASH.WEBFILTER',
            icon: 'fa-globe',
            state: 'cc-internet',
            visibleForDeviceTypes: [1, 2, 10, 11]
        },
            /*{
            title: 'CC_DASH.WEBFILTER',
            icon: 'fa-globe',
            state: 'cc-internet',
            visibleForDeviceTypes: [1, 2, 10, 11],
            submenu: [{
                title: 'CC_DASH.OVERVIEW',
                icon: 'fa-globe',
                state: 'cc-internet.tabs.blocked',
                visibleForDeviceTypes: [1, 2, 10, 11]
            }, {
                title: 'CC_DASH.MISC',
                icon: 'fa-cog',
                state: 'cc-internet.misc',
                visibleForDeviceTypes: [1, 2, 10, 11]
            }]
        }, */

            {
            title: 'USAGE.PROGRAMS',
            icon: 'fa-laptop',
            state: 'cc-programs-v3',
            visibleForDeviceTypes: [1, 2, 10, 11]
        }, {
            title: 'HEADER.SCREENSHOTS',
            icon: 'fa-desktop',
            state: 'cc-screenshots',
            visibleForDeviceTypes: [1, 10, 11],
        }, {
            title: 'CC_DASH.SECURITY',
            icon: 'fa-shield',
            state: 'cc-security',
            visibleForDeviceTypes: [1, 2, 10, 11],
            submenu: [{
                title: 'CC_DASH.WINSECURITY',
                icon: 'fa-windows',
                state: 'cc-security.windows',
                visibleForDeviceTypes: [1, 2, 10, 11]
            },]
        }, {
            title: 'CC_DASH.USAGE',
            icon: 'fa-bar-chart',
            state: 'cc-usage.reportsv2',
            visibleForDeviceTypes: [1, 2, 10, 11],
        }, {
            title: 'CC_DASH.EMAILREPORT',
            icon: 'fa-envelope',
            state: 'cc-emailreport',
            visibleForDeviceTypes: [1, 10, 11]
        }, {
            title: 'CC_DASH.SETTINGS',
            icon: 'fa-cog',
            state: 'cc-settings',
            visibleForDeviceTypes: [1, 2, 10, 11] /*,
            submenu: [{
                icon: 'fa-gears',
                title: 'CC_DASH.GENERAL',
                state: 'cc-settings.general',
                visibleForDeviceTypes: [1, 2, 10, 11]
            }, {
                icon: 'fa-star',
                title: 'CC_DASH.TANS',
                state: 'cc-settings.tans',
                visibleForDeviceTypes: [1, 10, 11]
            }, {
                icon: 'fa-file-o',
                title: 'CC_DASH.EXCLUSIONS',
                state: 'cc-settings.exclusions',
                visibleForDeviceTypes: [1, 2, 10, 11]
            }]*/
        }, {
            icon: 'fa-star',
            title: 'CC_DASH.TANS',
            state: 'cc-tans',
            visibleForDeviceTypes: [1, 10, 11]
        }, {
            title: 'SIDEBAR.SYNC',
            icon: 'fa-exchange',
            state: 'cc-sync',
            visibleForDeviceTypes: [1, 2, 10, 11]
        }]
    };

    // *****************************************************************************************************************
    // *****************************************************************************************************************
    // *****************************************************************************************************************

    var cbMenu = {
        name: 'Chicobrowser Menu',
        menuItems: [{
            title: 'CB_DASH.START',
            icon: 'fa-home',
            state: 'cb-dashboard-v3',
            visibleForDeviceTypes: [30]
        }, {
            title: 'CB_DASH.START',
            icon: 'fa-home',
            state: 'cb-dashboard',
            visibleForDeviceTypes: [10,11]
        }, {
            title: 'CB_DASH.TIMELIMITS',
            icon: 'fa-clock-o',
            state: 'cbtime',
            visibleForDeviceTypes: [10,11,30],
            submenu: [{
                icon: 'fa-clock-o',
                title: 'HEADER.DEVICELIMIT',
                state: 'cbtime.devicetime',
                visibleForDeviceTypes: [10,30],
                visibleFromMinAppVersion: 211040 //"2.1.1040"
            }, {
                icon: 'fa-clock-o',
                title: 'Chico Browser Limit',
                state: 'cbtime.browsertime',
                visibleForDeviceTypes: [10]

            }, {
                icon: 'fa-files-o',
                title: 'CC_DASH.ROAMING',
                state: 'cbtime.roaming',
                visibleForDeviceTypes: [10,30],
                visibleFromMinAppVersion: 211055
            }]
        }, {
            title: 'CB_DASH.WEBFILTER',
            icon: 'fa-globe',
            state: 'cb-webfilter',
            visibleForDeviceTypes: [10,11,30]
        }, {
            title: 'USAGE.APPS',
            icon: 'fa-tablet',
            state: 'cb-appfilter-v3',
            visibleForDeviceTypes: [10,30]
        }, {
            title: 'EMAIL.EMAILREPORT',
            icon: 'fa-envelope',
            state: 'cb-emailreport',
            visibleForDeviceTypes: [10,11,30]
        }, {
            title: 'CB_DASH.USAGE',
            icon: 'fa-bar-chart',
            state: 'cb-usage.reportsv2',
            visibleForDeviceTypes: [10,11,30]
        }, {
            title: 'CB_DASH.GPS',
            icon: 'fa-map-marker',
            state: 'cb-gps',
            visibleForDeviceTypes: [10,11,30]
        }, {
            title: 'CB_DASH.SETTINGS',
            icon: 'fa-cog',
            state: 'cb-settings',
            visibleForDeviceTypes: [10,11,30]
        },{
            title: 'CC_DASH.TANS',
            icon: 'fa-star',
            state: 'cb-tans',
            visibleForDeviceTypes: [30]
        },
            {
            title: 'SIDEBAR.SYNC',
            icon: 'fa-exchange',
            state: 'cb-sync',
            visibleForDeviceTypes: [10,11,30]
        }

        ]
    };

    /*if( $location.host() == 'localhost'){
        var devItem = {
            title: 'CB_DASH.APPFILTER_V2',
            icon: 'fa-tablet',
            state: 'cb-appfilter-v2',
            visibleForDeviceTypes: [10]
        };
        cbMenu.menuItems.splice(4, 0, devItem)
        var devItem2 = {
            title: 'CC_DASH.PRGFILTER_V2',
            icon: 'fa-laptop',
            state: 'cc-programs-v2',
            visibleForDeviceTypes: [1]
        };
        ccMenu.menuItems.splice(4, 0, devItem2)
    }*/

    var defaultMenu = {
        name: 'Default Menu',
        menuItems: []
    };

    menu.activeMenu = cbMenu;
    menu.defaultMenu = defaultMenu;

    $rootScope.$watch(function () {
        return AuthFactory.activeContext;
    }, function (context) {
        $rootScope.setMenuForContext(context);
    });

    $rootScope.$watch(function () {
        return AuthFactory.activeLicence;
    }, function (context) {
        $log.info('MENU watch changed', AuthFactory.activeLicence);     //Dierk MenuBug: dieser wurde gefeuert
        $log.info('MENU watch context', context);
        $rootScope.setMenuForContext(context);
    });

    $rootScope.setMenuForContext = function (context) {
        if (context) {
            $log.info('MENU SetMenuForContext', context);

            //Special Case intern menu
            if ($cookies.get('pcId') && $cookies.get('pcId') == '10:bf:48:d0:37:ad') {
                $log.info('MENU INTERN!');
                menu.activeMenu = internMenu;
                return;
            }

            if (AuthFactory.activeLicence.RemainingDays <= 0 || AuthFactory.activeLicence.showSideBar === false) {
                $log.info('MENU disabled!');
                menu.activeMenu = defaultMenu;
                return;
            }

            if (context == 'cc') {
                menu.activeMenu = ccMenu;
            } else if (context == 'cb') {
                menu.activeMenu = cbMenu;
            }
        }
        else {
            $log.info('MENU NO context!');
        }
    };

    $rootScope.$watch(AuthFactory.activeLicence.showSideBar, function (nV, oV) {
        if (nV && nV != oV) {
            $log.info('showSide Change!', nV);
        }
    });

    menu.hideSidebar = function () {
        $log.info('MENU disabled!');
        menu.activeMenu = defaultMenu;
    };

    menu.showSidebar = function () {

        if (AuthFactory.activeContext == 'cc') {
            menu.activeMenu = ccMenu;
        }
        if (AuthFactory.activeContext == 'cb') {
            menu.activeMenu = cbMenu;
        }
    };

    return menu;
});
