angular.module('ChicoApp').factory('cbUsageFactory', function(
  $http,
  apiFunctions,
  $log,
  API) {

  var factory = {};

    var apifields_usage = [
        'isUsageDisabled',
        'hideUsageAny',
        'hideUsageRuntimePrograms',
        'hideUsageRuntimeWebsites',
        'hideUsageHistoryPrograms',
        'hideUsageHistoryWebsites',
        'hideUsageHistoryBlocked',
        'hideUsageHistorySystem'
    ];

    factory.getLimitEntriesInfo = function(entriesArr){
        var req = {
            method: 'POST',
            url: API.url + 'cbusage/limitentries',
            data: entriesArr
        };
        return $http(req);
    }

    //Get variable
  factory.getUsage = function(params, howMany) {
    var req = {
      method: 'GET',
      url: API.url + 'cbusage/' + params + '/' + howMany
    };
    return $http(req);
  };

  factory.getUsageByDates = function(startDate, stopDate, howMany) {
    var req = {
      method: 'GET',
      url: API.url + 'cbusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany
    };
    return $http(req);
  };


    factory.getUsageTimes = function(startDate, stopDate) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/times/' + startDate + '/' + stopDate
        };
        return $http(req);
    };


    factory.getUsageHistory = function(startDate, stopDate, howMany) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/history/' + startDate + '/' + stopDate + '/' + howMany
        };
        return $http(req);
    };




    factory.delUsageAction = function() {
        var req = {
            method: 'GET',
            url: API.url + 'action/delusage'
        };
        return $http(req);
    }

  factory.getUsageByDatesPDF = function(startDate, stopDate, howMany) {
    var req = {
      method: 'GET',
      url: API.url + 'cbusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany + "?format=pdf"
    };
    return $http(req);
  };

    factory.hasRoaming = function()   {
        $log.info('gasRoaming entered');
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings/hasRoaming'
        };
        return $http(req);
    };


  factory.deleteUsage = function()   {
    $log.info('delete usage entered');
    var req = {
      method: 'DELETE',
      url: API.url + 'cbusage/delete'
    };
    return $http(req);
  };

  factory.getAgeDetail = function(myentry) {
    $log.info('getAgeDetail with entry=' + myentry + ' with url=' + API.url + 'ccusage/agedetail/' + myentry + '/de');
    var req = {
      method: 'GET',
      url: API.url + 'ccusage/agedetail/' + myentry + '/de'
    };
    $log.info('getAgeDetail return=' , $http(req));
    return $http(req);
  };


    factory.getUsageSettings = function() {
        var params = apiFunctions.obj_to_url_params(apifields_usage);
        $log.info('url is:' + apiFunctions.get_cc_base_url() + 'cbsettings/' + params);
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings/' + params
        };
        $log.info('return val=' + $http(req));
        return $http(req);
    };

    factory.setUsageSettings = function(keyvalue_arr) {
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings',
            data: keyvalue_arr
        };
        return $http(req);
    };


    factory.getLimitinfo = function(entry) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/limitinfo/' + entry
        };
        return $http(req);
    }


    factory.getLimitinfoByKind = function(entry) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/limitinfokind/' + entry
        };
        return $http(req);
    }


    factory.getWeekStats = function(period) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/weekstats/' + period
        };
        return $http(req);
    }


    return factory;

});
