'use strict';
angular.module('ChicoApp.CC-Programs-V3')
    .controller('CC-ProgramsController-V3', function(
        $rootScope,
        $scope,
        $http,
        $timeout,
        $translate,
        $templateCache,
        $state,
        $window,
        _,
        Metronic,
        $log,
        AuthFactory,
        ccListsFactory,
        ccProgramsFactory,
        modalFactory,
        scgSettingsFactory,
        scgSharedFactory) {

        $scope.data = {};
        $scope.applist = {};
        $scope.filteredApplist = {};
        $scope.selectedListRows = [];
        $scope.groups = null;
        $scope.currentContext = AuthFactory.activeContext;


        $scope.settingsLoading = true;
        ccProgramsFactory.getSettings()
            .then(function success(response) {

                $scope.data = response.data;
                $scope.data.programFilterLevel = 2; //default
                if ($scope.data.isPrgFilterNewBlocked == true) {
                    $log.info('YES ITS BLOCKED!');
                    $scope.data.programFilterLevel = 3;
                }
                if ($scope.data.programFilterAge != '0' && typeof $scope.data.programFilterAge != 'undefined') {
                    $scope.ageStr = $scope.data.programFilterAge + '+';
                } else {
                    $scope.ageStr = '';
                }

                //if ($scope.data.programFilterLevel ==3) $state.go('cc-programs-v2.tabs.allowed');
                $scope.settingsLoading = false;

                //add watchers
                _.each($scope.data, function(v, k) {
                    $scope.$watch('data.' + k, function(new_val, old_val) {
                        if (new_val != old_val) {
                            var changed_object = {};
                            changed_object[k] = new_val;
                            $scope.data.isPrgFilterNewBlocked = ($scope.data.programFilterLevel == 3);
                            scgSharedFactory.needsSync = true;
                            $log.info('CHANGED, now data is:', $scope.data);
                            ccProgramsFactory.setSetting(changed_object);
                            if ($scope.data.programFilterLevel ==3) {
                                $state.go('cc-programs-v2.tabs.allowed');
                            }
                        }
                    }, true);
                });
            });



        //NEW STUFF STARTS HERE
        $scope.editSelectedRows = function () {

            var selectedRecords = [];
            _.forEach($scope.selectedListRows, function (row) {
                selectedRecords.push({
                    selected: true,
                    data: row
                });
            });

            modalFactory.editModal.open({
                modal_settings: {
                    mode: 'edit',
                    context: 'prg',
                    title: $translate.instant('HEADER.LIMITENTRIES'),
                    subtitle: $translate.instant('MODAL_EDIT_RECORD.SUBTITLE'),
                    newRecord: null,
                    editRecords: selectedRecords,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function () {},
                    cancel: function () {}
                }
            });

        }


        $scope.deleteSelectedRows = function()  {

            var postArr = [];

            _.forEach($scope.selectedListRows, function (row) {
                var thisEntry = {entry: row.entry, isDeleted: true};
                postArr.push(thisEntry);
                });

            return ccListsFactory.postRecords(postArr)
                .then(function success() {
                    $state.go($state.current, {}, {reload: true});
                }, function error() {
                    $state.go($state.current, {}, {reload: true});
                });
        }




        $scope.selectedKinds = {};

        $scope.spinnerSettings = true; //must be initalised with true because of switch disabled state
        $scope.spinnerApplist = false;
        $scope.spinnerSettings = true;

        $scope.editKindDropDownItem = function(item){

            modalFactory.editGroupModal.open({
                modal_settings: {
                    mode: 'edit',
                    groupItem: item,
                    groups: $scope.groups,
                    title: $translate.instant('HEADER.SOMEHEADER'),
                    subtitle: $translate.instant('MODAL_EDIT_RECORD.SOMESUBTITLE'),
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function () {
                        setTimeout(function(){
                            $window.location.reload();
                        },0);
                    },
                    cancel: function () {}
                }
            });

        }

        $scope.selectedKinds = {};

        scgSettingsFactory.getGroups(false)
            .then(function success(response) {

                $scope.groups = response.data;

                var availableKindsPrep = [{
                    kind: 1,
                    name: $translate.instant('TABS.ALLOWED'),
                    selected: true,
                    editable: false,
                    addCircleClass: 'scg-multiselect-badge-green',
                    circleContent: '<i class="fa fa-binoculars"></i>'
                }, {
                    kind: 2,
                    name: $translate.instant('TABS.TIMELIMITED'),
                    selected: true,
                    editable: false,
                    addCircleClass: 'scg-multiselect-badge-red',
                    circleContent: '<i class="fa fa-binoculars"></i>'
                }, {
                    kind: 3,
                    name: $translate.instant('TABS.BLOCKED'),
                    selected: true,
                    editable: false,
                    addCircleClass: 'scg-multiselect-badge-yellow',
                    circleContent: '<i class="fa fa-binoculars"></i>'
                }, {
                    kind: 4,
                    name: $translate.instant('MISC.SYS_PRG'),
                    selected: false,
                    editable: false,
                    addCircleClass: 'scg-multiselect-badge-grey',
                    circleContent: '<i class="fa fa-binoculars"></i>'
                },{
                        kind: 9,
                        name: $translate.instant('MISC.WINDOWS_APPS'),
                        selected: false,
                        editable: false,
                        addCircleClass: 'scg-multiselect-badge-darkgreen',
                        circleContent: '<i class="fa fa-binoculars"></i>'
                    }
                ];

                _.each($scope.groups, function (group /*, index*/ ) {

                    var circleContent = group.kind - 10;

                    availableKindsPrep.push({
                        kind: group.kind,
                        name: group.name,
                        selected: true,
                        editable: true,
                        addCircleClass: 'scg-multiselect-badge-yellow',
                        circleContent: circleContent
                    });
                });

                $scope.availabeKinds = availableKindsPrep;

            });

        $scope.onAppListRowClick = function (row) {

            var selectedRecords = [{
                selected: true,
                data: row
            }];

            modalFactory.editModal.open({
                modal_settings: {
                    mode: 'edit',
                    context: 'prg',
                    title: $translate.instant('HEADER.LIMITENTRIES'),
                    subtitle: $translate.instant('MODAL_EDIT_RECORD.SUBTITLE'),
                    newRecord: null,
                    editRecords: selectedRecords,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function () {},
                    cancel: function () {}
                }
            });

        }

        $scope.searchInput = '';
        $scope.selectedPage = 1;
        var paginationNavDefault = {
            pageCount: 1,
            pages: [1]
        };
        var paginationRowsPerPage = 15;

        $scope.paginationNav = paginationNavDefault;

        $scope.$watch('selectedKinds', function (new_val, old_val) {
            if (new_val != old_val) {
                refreshFilteredAppList();
            }
        }, true);

        $scope.refreshSearch = function () {
            refreshFilteredAppList();
        }

        function refreshFilteredAppList() {

            var filtered = $scope.applist;

            //filter for search input
            if ($scope.searchInput) {
                filtered = _.filter($scope.applist, function (object) {
                    //var concatenated = object.entry + object.details;
                    var concatenated = object.entry;
                    return concatenated.toLowerCase().includes($scope.searchInput.toLowerCase());
                });
            }

            //filter for selected kinds
            if ($scope.selectedKinds) {
                var filtered2 = _.filter(filtered, function (object) {
                    if (!$scope.selectedKinds[object.kind]) {
                        return false; //what to do if we have no filter information for that kind, true=show, false=hide
                    }
                    return $scope.selectedKinds[object.kind] == true;
                });
                if (filtered2.length < 1) {
                    filtered2 = [];
                }
                filtered = filtered2;
            }

            $scope.paginationNav = paginationNavDefault;
            if (filtered && filtered.length > 0) {
                if (filtered.length > paginationRowsPerPage) {
                    //fill pagination info
                    var pageCount = Math.ceil(filtered.length / paginationRowsPerPage);
                    var pages = [];
                    for (var i = 1; i <= pageCount; i++) {
                        pages.push(i);
                    }
                    $scope.paginationNav = {
                        pageCount: pageCount,
                        pages: pages
                    };

                    //filter list to length of selected page
                    var startingEntry = ($scope.selectedPage - 1) * paginationRowsPerPage;
                    var endingEntry = ($scope.selectedPage * paginationRowsPerPage) - 1;

                    if (filtered.length - 1 < endingEntry) {
                        endingEntry = filtered.length - 1;
                    }

                    var paginated = [];
                    for (var j = startingEntry; j <= endingEntry; j++) {
                        paginated.push(filtered[j]);
                    }

                    filtered = paginated;
                }
            }

            //reset selected page to 1 if we have filtered too much and now do not
            //have a page that high anymore
            if ($scope.selectedPage > 1 && $scope.selectedPage > $scope.paginationNav.pageCount) {
                $scope.selectedPage = 1;
                refreshFilteredAppList();
            } else {
                $scope.filteredApplist = filtered;
            }

        }

        $scope.changeSelectedPage = function (pageNo) {
            $scope.selectedPage = pageNo;
            refreshFilteredAppList();
        }

        $scope.goToPrevPage = function () {
            if ($scope.selectedPage > 1) {
                $scope.selectedPage--;
                refreshFilteredAppList();
            }
        }

        $scope.goToNextPage = function () {
            if ($scope.selectedPage < $scope.paginationNav.pageCount) {
                $scope.selectedPage++;
                refreshFilteredAppList();
            }
        }


        $scope.entryAdd = function() {

            modalFactory.editModal.open({
                modal_settings: {
                    mode: 'add',
                    context: 'prg',
                    title: $translate.instant('MODAL_EDIT_RECORD.TITLE_ADD'),
                    subtitle: $translate.instant('MODAL_EDIT_RECORD.SUBTITLE'),
                    newRecord: {
                        kind: 1
                    },
                    editRecords: null,
                    refreshStateAfterClose: true
                }/*,
                callbacks: {
                    ok: contrScope.onEditModalReturn,
                    cancel: contrScope.onEditModalReturn
                }*/
            });
        }


        $scope.entryDelete = function() {

            var key_arr = [];
            scgSharedFactory.needsSync = true;

            /*_.each(contrScope.data.table_selected_records, function(v) {
                key_arr.push(v.data.entry);
            });*/

                var postArr = [];
                _.each(key_arr, function(del_rec) {
                    var thisEntry = {entry: del_rec, isDeleted: true};
                    postArr.push(thisEntry);
                });

                return ccListsFactory.postRecords(postArr)
                    .then(function success() {
                        $state.go($state.current, {}, {reload: true});
                    }, function error() {
                        $state.go($state.current, {}, {reload: true});
                    });


        };


        scgSettingsFactory.getGroups(false)
            .then(function success(response) {
                $scope.data.groups = response.data;
                $log.info('$scope.data.groups', $scope.data.groups);
                if ($scope.data.groups.length == 5) {
                    $scope.group1name = $scope.data.groups[0].name;
                    $scope.group2name = $scope.data.groups[1].name;
                    $scope.group3name = $scope.data.groups[2].name;
                    $scope.group4name = $scope.data.groups[3].name;
                    $scope.group5name = $scope.data.groups[4].name;
                }
            });

       var loadList = function () {
            $scope.spinnerApplist = true;
            ccListsFactory.getPrgList()
                .then(function success(response) {
                    $log.info('prglist arrived ',response.data);
                    $scope.applist = response.data;
                    refreshFilteredAppList();
                    $scope.spinnerApplist = false;
                });
        };

        loadList();


        //agge loading
        $scope.ageLoading = false;


    });
