'use strict';
angular.module('ChicoApp.CB-V3-Dashboard')

    .controller('CB-DashboardV3Controller', function ($rootScope,
                                                    $scope,
                                                    $translate,
                                                    $state,
                                                    $modal,
                                                    $http,
                                                    $window,
                                                    moment,
                                                    API,
                                                    scgSharedFactory,
                                                    cbDashboardFactory,
                                                    cbUsageFactory,
                                                    AuthFactory,
                                                    $log,
                                                    $timeout,
                                                    $interval,
                                                    DeviceFactory,
                                                    Metronic,
                                                    _,
                                                    modalFactory,
                                                    menuFactory,
                                                    errorDisplayFactory,
                                                    scgGlobalSpinnerFactory) {

        $scope.scgSharedFactory = scgSharedFactory;

        AuthFactory.activeLicence.showSideBar = true;
        menuFactory.showSidebar();

        $scope.data = {};
        $scope.status = {};
        $scope.statusInfo = {};
        $scope.statusInfoApps = {};
        $scope.headlineDevice = "";
        $scope.formatSecsV2 = scgSharedFactory.formatSecs;
        $scope.logbookTeaser = {};
        var modalInstance;

        $timeout(function () {
            $scope.trueDemo = false;
        }, 5000);

        $timeout(function () {
            $scope.trueDemo = true;
        }, 10000);

        $timeout(function () {
            $scope.trueDemo = undefined;
        }, 15000);

        $scope.activeLicence = {};
        $scope.onOrOff = '...';
        $scope.lastSync = '...';
        $scope.lastSyncShort = '...';
        $scope.todayTotal = '...';
        $scope.hasNotice = false;
        $scope.noticeText = '';
        $scope.isCBSecurity = true; //here because of "do not show as default"
        $scope.serverTimeDiff = 0;
        $scope.hasFeatureDeviceLimit = false;
        $scope.extTimeInfo = "..."; //$translate.instant('ALL.NONE');
        $scope.addExtCBMinutes = '00:15'; //default
        $scope.hintHide = true;  //for deviceWarning
        $scope.deviceWarning = '';
        $scope.deviceWarningURL = '';
        $scope.totaltime = 0;
        $scope.giveExt = 0; //default
        $scope.extensionTime = 0;
        $scope.isActive = true; //main switch true
        $scope.cbMajor = 0;
        $scope.deviceTimeWarning = false;
        $scope.hasOldTime = false;
        $scope.deviceHint = "";

        $scope.offDeviceAdmin = false;
        $scope.offUsageStats = false;
        $scope.offAccessibility = false;

        $scope.timeBoxCaption = "";
        $scope.timeDiff = -1;
        $scope.timeTop3 = "";
        $scope.timeBoxTime = "";
        $scope.timeBoxRemaining = "";
        $scope.timeBoxExtension = "";
        $scope.hasAppData = false;
        $scope.hasCBFeature = scgSharedFactory.hasCBFeature;
        $scope.spinnerMain = true;



        //First of all run watcher to check if state should be cc-dashboard or cc-dashboard-v3
        // ------------------------------------------------------------------------------------------
        $rootScope.$watch(function () {
            return AuthFactory.activeDevice;
        }, function (nv, ov) {
            if ((nv && !ov) || (nv && ov && nv!=ov)) {
                $log.info('CB-DASH-V3 activeDevice=', AuthFactory.activeDevice);
                if (AuthFactory.activeDevice.deviceType==30) {scgGlobalSpinnerFactory.unblockUI();}
                if (AuthFactory.activeDevice.deviceType==10) {
                    scgGlobalSpinnerFactory.unblockUI();
                    $state.go("cb-dashboard", {reload: true});
                }
            }
        }, true);
        // ------------------------------------------------------------------------------------------


        $log.info('dash-v3 init, activeDevice is', AuthFactory.activeDevice);

        scgSharedFactory.getLastSync().then(function success(lastSync) {
            $scope.lastSync = scgSharedFactory.formatDateTime(lastSync);
            $scope.lastSyncShort = scgSharedFactory.formatDateTimeShort(lastSync);
            $log.info('LASTSYNC ONCE', lastSync);
            $log.info('LASTSYNCSHORT ONCE', $scope.lastSyncShort);

            //Case Sync never successful
            if (moment(lastSync) < moment('2002-01-01') && scgSharedFactory.syncProbShown == false) {
                $log.info('LASTSYNC PROBLEM !!! SHOW NOTICE', scgSharedFactory.syncProbShown);
                scgSharedFactory.syncProbShown = true;    //Globale Variable innerhalb scgSharedFactory, beachte $scope ganz oben!
                //appInsights.trackEvent("Modal: SyncProb");
                modalFactory.okModal(1, $translate.instant('MSG.SYNCPROB1') + '<br><br>' + $translate.instant('MSG.SYNCPROB2'));
            }
            //eof special case
        }); //eof getLastsync()

        AuthFactory.checkCurrentDeviceLicenceCBwithCache();

        $scope.isIOS = false;
        $log.info('CB-DashboardController: Active Device');
        $log.info(AuthFactory.activeDevice);

        DeviceFactory.getDeviceById(AuthFactory.activeDevice.deviceId).then(
            function success(device) {
                $log.info('DEVICENAME=', device.deviceName);
                //Check here for a deviceWarning
                var ourdevice = device.deviceName.toLowerCase();
                if (device.deviceName.toLowerCase().indexOf('huawei') != -1) {
                    $scope.deviceWarning = "Huawei"
                    $scope.deviceWarningURL = $translate.instant('URL.FAQ_HUAWEI');
                }

                $log.info('THIS IS OURDEVICE:', ourdevice);
                if (device.deviceType === 11) {
                    $log.info('CB-DashboardController: IOS Check : is IOS Device');
                    $scope.isIOS = true;
                }
            }
        );

        $scope.syncNow = function () {
            $state.go('cb-sync');
        };

        $scope.showUsage = function () {
            $state.go('cb-usage.reportsv2');
        };

        $scope.showSettings = function () {
            $state.go('cb-settings');
        };

        $scope.turnOff = function () {
            $log.info('TURN OFF!');

            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('INFO.TURN_OFF_NOTICE') + " " + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
                },
                callbacks: {
                    ok: function () {
                        $log.info('yes, ok was click, he/she is sure');
                        scgSharedFactory.logMeCB('Web Portal: ' + $translate.instant('MSG.OFF_SUCCESS'));
                        $scope.data.blockTimesBrowser = scgSharedFactory.blockOff();
                        $scope.data.isTimeLimitActive = false;
                        $scope.data.isWeekDayLimitActive = false;
                        //aus diesen beiden folgt:
                        $scope.data.isTimeActive = false;
                        $scope.data.isAppFilterActive = false;
                        $scope.data.isWebFilterActive = false;
                        $scope.data.isEmailReportActive = false;
                        $scope.data.isSomeActive = false;

                        //set also new devicelimits to false!
                        $scope.data.isTimeLimitActiveDevice = false;
                        $scope.data.isWeekDayLimitActiveDevice = false;
                        $scope.data.blockTimesDevice = scgSharedFactory.blockOff();
                        $scope.onOrOff = $translate.instant('ALL.OFF');
                    },
                },
                cancel: function () {
                    $log.info('canceled!');
                }
            });
        };

        $scope.turnOn = function () {
            $log.info('TURN ON!');
            $scope.data.isAppFilterActive = true;
            $scope.data.isWebFilterActive = true;
            $scope.isSomethingActive();
            scgSharedFactory.logMeCB('Web Portal: ' + $translate.instant('MSG.ON_SUCCESS'));
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: $translate.instant('MSG.CBTURNEDON'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function () {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };

        $scope.untilOff = function () {
            $log.info("untilOff");
            $scope.data.untilAction=0;
        };





        $scope.comingSoonMsg = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: $translate.instant('MSG.COMINGSOON'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function () {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };



        $scope.isSomethingActive = function () {
            //Berrechnet für den TurnON TurnOff Switch
            $scope.data.isSomeActive = false;
            if ($scope.data.isTimeActive || $scope.data.isWebFilterActive) {
                $scope.data.isSomeActive = true;
            }
            if ($scope.data.isAppFilterActive || $scope.data.isEmailReportActive) {
                $scope.data.isSomeActive = true;
            }
            if ($scope.data.isSomeActive) {
                $scope.onOrOff = $translate.instant('ALL.ON');
            } else {
                $scope.onOrOff = $translate.instant('ALL.OFF');
            }
        };

        $scope.showUsages = function () {
            $state.go('cb-usage.reportsv2');
        };



        $scope.setExtText = function (extTime) {
            $log.info('setExtText, extTime=', extTime);
            if (extTime > 0) {
                $scope.extTimeInfo = $translate.instant('ALL.ACTIVE');
            } else {
                $scope.extTimeInfo = $translate.instant('ALL.NONE');
            }
        }


        $scope.sendMsgV3 = function (msg) {
            $log.info('sendMsg v3 msg=' + msg);
            cbDashboardFactory.sendMsg(msg)
                .then(function success() {
                    $log.info('sendMsg v3 SUCCESS');
                }, function error() {
                    $log.info('sendMsg ERROR');
                });

        };

        $scope.isAfterUntilDate = function(azDateTime) {
            $log.info("untildate=", azDateTime);
            if (azDateTime == null)
            {
                return false;
            }
            if (azDateTime.length != 19) {
                return false;
            }
            var momentObj = moment(azDateTime, 'YYYY-MM-DD HH:mm:ss');
            if (momentObj.isAfter(moment())) {
                return true;
            }
            return false;
        }



        scgSharedFactory.isCBSecurity().then(function (returnvalue) {
            $scope.isCBSecurity = returnvalue;
        });

        cbDashboardFactory.getCBSettings()
            .then(function success(response) {
                $scope.data = response.data;
                scgGlobalSpinnerFactory.unblockUI();
                if (AuthFactory.activeDevice.deviceType==30) {$scope.cbMajor==3;
                }

                //check if untilDate already passed by (then reset to untilAction0)
                if ($scope.data.onetimelocklevel==2 && $scope.isAfterUntilDate($scope.data.unlockdate)==false) {
                    $scope.data.onetimelocklevel=0;
                }
                if ($scope.data.onetimelocklevel==3 && $scope.isAfterUntilDate($scope.data.lockdate)==false) {
                    $scope.data.onetimelocklevel=0;
                }

                $scope.data.isTimeActive = false;       //Das feld hier wird berrechnet aus den beiden Limits!
                if ($scope.data.isTimeLimitActive ||
                    $scope.data.isWeekDayLimitActive ||
                    $scope.data.isTimeLimitActiveDevice ||
                    $scope.data.isWeekDayLimitActiveDevice ||
                    $scope.data.blockTimesBrowser != scgSharedFactory.blockOff() ||
                    $scope.data.blockTimesDevice != scgSharedFactory.blockOff()) {
                    $scope.data.isTimeActive = true;
                }
                $scope.isSomethingActive();
                $scope.howManyUnlockRequests = -1;

                if ($scope.data.blockTimesDevice != scgSharedFactory.blockOff()) {
                    $scope.data.isBlockTimesDevice = true;
                } else {
                    $scope.data.isBlockTimesDevice = false;
                }

                //Extension handling for CB
                $scope.giveExt = $scope.data.giveExt;
                if ($scope.giveExt > 0 || $scope.data.currentextensionmin > 0) {
                    $scope.setExtText(1); //1 is dummy for show extension "Active"
                } else {
                    $scope.setExtText(0); //0 is dummy for show extension "None"
                }

                if ($scope.data.roaming!=null && $scope.data.roaming != '' && $scope.data.romaing !='undefined') {
                    scgSharedFactory.hasRoaming = true;
                } else {
                    scgSharedFactory.hasRoaming = false;
                }


                cbDashboardFactory.getTodayTotal(moment(new Date()).format('YYYY-MM-DD'))
                    .then(function success(response) {
                            $scope.todayTotal = $translate.instant('USAGE.TODAYTOTAL') + ': ' + response.data;
                        }
                    );

                cbDashboardFactory.getUnlockRequests()
                    .then(function success(response) {
                            $scope.howManyUnlockRequests = response.data.length;
                        }
                    );

                $scope.spinnerMain = false;


                //add watchers
                _.each($scope.data, function (v, k) {
                    $scope.$watch('data.' + k, function (new_val, old_val) {
                        if (new_val != old_val) {
                            var changed_object = {};
                            changed_object[k] = new_val;
                            //ORIG
                            cbDashboardFactory.setSetting(changed_object);
                            $log.info('DATA CHANGED and saved');
                            scgSharedFactory.needsSync = true;
                        }
                    }, true);
                });


            });

        $scope.showUnlock = function () {
            $state.go('cb-usage.unblock');
        };

        setTimeout(function () {
            $log.info('Refreash lastsync here');
            scgSharedFactory.getLastSync().then(function (funcresult) {
                //Dierk: Callback! Must be done this way
                $scope.lastSync = scgSharedFactory.formatDateTime(funcresult);
            });
        }, 5000);

        $log.info('WATCHER DASHBOARD licence entry');

        $rootScope.$watch(function () {
            return AuthFactory.activeLicence;
        }, function (nv) {
            if (nv) {
                $log.info('DASHBOARD sb licence watcher running', AuthFactory.activeLicence);
                if (AuthFactory.activeLicence.isTrial | AuthFactory.activeLicence.expireDays < 10) {
                    $log.info('DASHBOARD is trial or remaining<10 is TRUE');
                    $scope.hasNotice = true;
                    if (typeof AuthFactory.activeLicence.isTrial != 'undefined') {
                        var remaining = AuthFactory.activeLicence.expireDays;
                        if (remaining < 0) {
                            remaining = 0;
                        }
                        if (AuthFactory.activeLicence.isTrial) {
                            $scope.noticeText = $translate.instant('MISC.TRIALVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', remaining);
                        }
                        if (!AuthFactory.activeLicence.isTrial) {
                            $scope.noticeText = $translate.instant('MISC.FULLVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', remaining);
                        }
                    } else {
                        $scope.hasNotice = false; //is undefined!
                    }
                } else {
                    $scope.hasNotice = false;
                }
            }
        }, true);

        $scope.testStuff = function () {
            //alert('Test stuff');
            //appInsights.trackEvent("testStuff fired");
            errorDisplayFactory.addError({
                'message': 'MyError',
                'details': 'I am the error detail'
            });

        };

        $scope.delCBExtension = function () {
            cbDashboardFactory.delExt()
                .then(function success(response) {
                        $log.info("CB extensideleted, response=", response.data);
                        $scope.data.giveExt = 0;
                        $scope.giveExt = 0;
                        $scope.setExtText($scope.givenExt);
                        cbDashboardFactory.syncNow()
                            .then(function success(response) {
                                $log.info("CB extension syncNow, response=", response.data);
                                $state.go($state.current, {}, {reload: true}); //refresh page
                            })
                    }
                );

        }

        //This is CB2 extension routine
        $scope.setExtCB = function() {
            $log.info('setExtCB triggered!');
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-extension.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-window-small.html',
                scope: $scope
            });

        }

        //Here extension will be set in database (called by the modal)
        $scope.doExtensionCB = function(extensionTime) {
            modalInstance.dismiss('cancel');
            var extTimeMinutes = 0; //default
            if (typeof(extensionTime) == 'number') {
                $log.info("We are number");
                extTimeMinutes = extensionTime;
            } else {
                $log.info("We are string");
                extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');
            }
            $log.info('doExtensionCB with extTimeMinutes=' + extTimeMinutes);
            $scope.data.giveExt = extTimeMinutes; //this for table database binding
            $scope.giveExt = extTimeMinutes; //this for immediate
            $scope.setExtText($scope.giveExt);
            if (extTimeMinutes>0) {scgSharedFactory.needsSync=true}
        };


        //This is CB3 extension routine
        $scope.setExtension = function () {
            $log.info('setExtension triggered!');
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-extension-cb3.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-window-small.html',
                scope: $scope
            });
        }

        //This is CB3 extension routine
        $scope.showFAQTimes = function () {
            $log.info('show FAQ Times');
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-faqtimes.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-window-small.html',
                scope: $scope
            });
        }


        $scope.formatSecs = function(secs) {

            if (isNaN(secs)) {
                return "---";
            }

            if (secs==0)
            {
                return "0 min";
            }

            if (secs<60)
            {
                return "<1 min";
            }

            hours = Math.floor(secs / 3600);
            secs %= 3600;
            minutes = Math.floor(secs / 60);
            seconds = secs % 60;
            var minString =  $translate.instant('ALL.MINUTES'); //default plural
            if (minutes==1) {
                minString = $translate.instant('ALL.MINUTE'); //singular!
            }
            if (hours>0) {
                if (minutes>0) {
                    return hours + " h " + minutes + " min";
                } else {
                    return hours + " h ";
                }
            } else {

                return minutes + " " + minString;
            }
        }


        $scope.formatAzTime = function(aztime) {
            //$log.info("aztime init=", aztime);
            var date = new Date(aztime);
            //$log.info("aztime new date=", date);
            return scgSharedFactory.formatDateTimeShort(aztime);
        }



        //Here extension will be set in database (called by the modal)
        $scope.doExtension = function (extensionTime) {
            modalInstance.dismiss('cancel');
            var extTimeMinutes = 0; //default
            if (typeof(extensionTime) == 'number') {
                $log.info("We are number");
                extTimeMinutes = extensionTime;
            } else {
                $log.info("We are string");
                extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');
            }
            $log.info('doExtensionCB with extTimeMinutes=' + extTimeMinutes);
            cbDashboardFactory.setExtension(extTimeMinutes)
                .then(function success(response) {
                        //update extension again
                        getStatusInfo();
                    }
                );
        };


        $scope.adjustBlockTimeSettings = function()
        {
            modalInstance.dismiss('cancel');
            $state.go('cbtime.devicetime');
        }


        //do we have DeviceLimit
        AuthFactory.getVersionInfo().then(function (version) {

            var myversion = version.VersionInfo.substring(0, 9);
            myversion = myversion.replace("2.1.", "");
            myversion = myversion.replace("3.1.", "");
            $log.info("version after replace is:", myversion);
            if (myversion > 1043) {
                $scope.hasFeatureDeviceLimit = true
            }
        });

        //Logbook Teaser
        scgSharedFactory.getLogbookTeaserV2().then(function (funcresult) {
            $scope.logbookTeaser = funcresult.data;
        });

        //$scope.serverTimeDiff = scgSharedFactory.getServerTimeDiff();
        $log.info("server diff hours=" + $scope.serverTimeDiff);


        scgSharedFactory.getServerTimeDiff().then(function (funcresult) {
            $scope.serverTimeDiff = funcresult;
        });


        //Hint stuff for device Warning
        //--------------------------------------------------------------------------------
        scgSharedFactory.getHint(2001).//adjust hintID here, leading 20 means CB!
        then(function success(response) {
                if (response.data.hint2001) {                         //adjust hintID here
                    $scope.hintHide = response.data.hint2001;
                }        //adjust hintID here
                else {
                    //not assgined, set to false!
                    $scope.hintHide = false;
                }
            }
        );
        $scope.hintDone = function () {
            $scope.hintHide = true;
            scgSharedFactory.setHint(2001).then(function success() {
                    $log.info('HINT DONE SUCCESS');
                }
            );
        };
        //--------------------------------------------------------------------------------

        //Call StatusInfo directly OnLoad
        getStatusInfo();
        $log.info('getStatusInfo onLoad');

        $scope.getScreenshot = function() {
            $log.info('screenshot');
            $scope.comingSoonMsg();
        };





        $scope.deleteDevicetimeWarning = function() {
            cbDashboardFactory.deleteDevicetimeWarning().then(function success(response) {
                $scope.deviceTimeWarning=false;
            });
        }


        //Recurring Timer, update each 30 Seconds statusInfo!
        //---------------------------------------------------
        var intervalStatusInfo = $interval(function () {
            getStatusInfo();
        }, 30 * 1000);


        function getStatusInfo() {

            cbDashboardFactory.getStatusInfo().then(function success(response) {
                if (response.data) {
                    $scope.statusInfo = response.data;
                    $scope.extensionTime = response.data.extTime;

                    if (response.data.deviceTimeWarning!=null) {
                        $scope.deviceTimeWarning = response.data.deviceTimeWarning;
                    }

                    //check if untilDate already passed by (then reset to untilAction0)
                    if ($scope.statusInfo.untilAction>0 && $scope.isAfterUntilDate($scope.statusInfo.untilDateTime)==false) {
                        $scope.data.untilAction=0;
                    } else {
                        $log.info("untilaction ACTIVE in statusinfo");
                        $scope.data.untilAction=$scope.statusInfo.untilAction;
                        $scope.data.untilDateTime=$scope.statusInfo.untilDateTime;
                    }


                    var statustime = null;
                    var lastTimeFormated = null;

                    if ($scope.statusInfo.lastAzDateTime!=null) {
                        statustime = $scope.statusInfo.lastAzDateTime.substring(0,10);
                        lastTimeFormated = moment($scope.statusInfo.lastAzDateTime).format('HH:mm');
                    }
                    var browsertime =  moment().format('YYYY-MM-DD');
                    var a = moment(statustime);
                    var b = moment(browsertime);
                    var timeDiff = b.diff(a, 'days');


                    $log.info('status browsertime=' + browsertime + "|statustime=" + statustime + "|timeDiff=" + timeDiff + "|formated=" + lastTimeFormated);

                    //New time calculation for caption today, yesterday, two day ago etc.
                    if (statustime!=null && browsertime!=null) {
                        $scope.timeDiff = timeDiff;
                        if (statustime == browsertime) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_TODAY');
                            $scope.timeDiff = 0;
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_TODAY') + ", " + lastTimeFormated
                        }
                        ;
                        if (timeDiff == 1) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_YESTERDAY');
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_YESTERDAY') + ", " + lastTimeFormated
                        }
                        ;
                        if (timeDiff == 2) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_EREYESTERDAY');
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_EREYESTERDAY') + ", " + lastTimeFormated
                        }
                        ;
                        if (timeDiff > 2) {
                            if ($translate.use() == 'de_DE') {
                                $scope.timeTop3 = "Vor " + timeDiff + " Tagen";
                                $scope.timeBoxCaption = "Vor " + timeDiff + " Tagen, " + lastTimeFormated;
                            } else {
                                $scope.timeTop3 = timeDiff + " days ago";
                                $scope.timeBoxCaption = timeDiff + " days ago, " + lastTimeFormated;
                            }
                        }
                        if (timeDiff > 30) {
                            if ($translate.use() == 'de_DE') {
                                $scope.timeTop3 = " vor mehr als 30 Tagen";
                                $scope.timeBoxCaption = "Vor mehr als 30 Tagen...";
                            } else {
                                $scope.timeTop3 = " more than 30 days ago";
                                $scope.timeBoxCaption = "More than 30 days ago...";
                            }
                        }
                    }

                    if (statustime!=null && browsertime!=null && statustime!==browsertime) {
                        $scope.hasOldTime=true;
                        $scope.totaltime=0;
                    }

                    //check deviceStatus warnings
                    if ($scope.statusInfo.deviceStatus)
                    {
                        var s = $scope.statusInfo.deviceStatus;
                        if (s.length>=3 && s.substring(0,1)==="0") $scope.offDeviceAdmin=true;
                        if (s.length>=3 && s.substring(0,1)==="1") $scope.offDeviceAdmin=false;
                        if (s.length>=3 && s.substring(1,2)==="0") $scope.offAccessibility=true;
                        if (s.length>=3 && s.substring(1,2)==="1") $scope.offAccessibility=false;
                        if (s.length>=3 && s.substring(2,3)==="0") $scope.offUsageStats=true;
                        if (s.length>=3 && s.substring(2,3)==="1") $scope.offUsageStats=false;
                    }

                    $scope.deviceHint = ""; //default
                    $scope.deviceHintUrl = ""; //default
                    if ($translate.use() == 'de_DE' && $scope.statusInfo && $scope.statusInfo.manufacturer) {
                        $log.info('We have manufacturer=' + $scope.statusInfo.manufacturer);
                        if ($scope.statusInfo.manufacturer.indexOf('HUAWEI') != -1 || $scope.statusInfo.manufacturer.indexOf('HONOR') != -1) {
                            $scope.deviceHint = "HUAWEI/HONOR";
                            $scope.deviceHintUrl = "https://salfeld.de/faq/android/huawei/";
                        }
                        if ($scope.statusInfo.manufacturer.indexOf('WIKO') != -1) {
                            $scope.deviceHint = "WIKO";
                            $scope.deviceHintUrl = "https://salfeld.de/faq/android/wiko/";
                        }
                    }


                    //get counted apps
                    var topArr = [];
                    var limit = 3;
                    response.data.apps = _.sortBy(response.data.apps, ['counted']);
                    _.forEach(response.data.apps, function(app, index){
                        if(app.title != 'total' && topArr.length < limit){
                            $scope.hasAppData=true;
                            topArr.push(app);
                        }
                    });
                    var countedSum = topArr[0]?topArr[0].counted:0;
                    $scope.statusInfoApps = {apps:topArr, fullBarSum:countedSum};

                    $log.info('statusinfo debug:',$scope.statusInfo);
                }
            });
        }


        //Watcher for CB3 (necessary if page reload button has been hit)
        $scope.$watch(function() {
            return AuthFactory.activeDevice;
        }, function(activeDevice, prevActiveDevice) {
            console.log('getParentsInfo In Controller', activeDevice, prevActiveDevice);
            if (activeDevice.deviceType==30) {
                $scope.cbMajor=3;
            } else {
                if( activeDevice.deviceType ){
                    $scope.cbMajor=2;
                }
            }

            if(activeDevice && activeDevice.deviceId && activeDevice.deviceName){
                console.log('GetParentsInfo In Watcher, deviceName=' + activeDevice.deviceName);
                $scope.headlineDevice = activeDevice.deviceName;
                getStatusInfo();
            }

        },true);


        $scope.doUntilAction = function(defaultRadio) {
            $log.info('doUntilAction with defaultRadio=' + defaultRadio);
            modalFactory.untilModal.open({
                modal_settings: {
                    defaultRadio: defaultRadio,
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        $state.reload();
                    }
                }
            });
        }


        $scope.getAppIconFile = function (apk) {
            return "https://portal.salfeld.net/files/icon/apps/sess887783995a9d401681c6567398227aac/" + apk + ".png";
        }


        $scope.showWeekdayLog = function(weekday)
        {
            $state.go("cb-usage.reportsv2", {weekday: weekday});
        }


        $scope.getWeekStats = function(period) {
            $scope.spinnerWeekStats=true;
            cbUsageFactory.getWeekStats(period)
                .then(function success(response) {
                    $scope.weekstats = response.data;
                    $scope.weekstatsPeriod = period;
                    $scope.weekstatsPercentage = $scope.getPercentageFromStats(response.data);
                    $log.info('Weekstats=', $scope.weekstats);
                    $scope.spinnerWeekStats=false;
                });
            return;
        }

        $scope.getPercentageFromStats = function(weekstats){
            var result = [];
            var total = 0;
            var highestVal = 0;
            var singleVal = 0;
            console.log('x', weekstats)
            _.forEach(weekstats, function(obj,index){
                total += obj.seconds;
                if (obj.seconds>highestVal) {highestVal=obj.seconds};
            })
            _.forEach(weekstats, function(obj,index){
                singleVal = obj.seconds/highestVal*100;
                if (isNaN(singleVal)) {
                    result.push(0)}
                else {result.push(singleVal);}
            })
            return result;
        }


        $scope.roamingSettings = function() {
            $log.info("roaming settings");
            //modalFactory.okModal(1, $translate.instant('MSG.SYNCPROB1') + '<br><br>' + $translate.instant('MSG.SYNCPROB2'));
            modalFactory.roamingModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        $state.reload();
                    }
                }
            });
        }


        $scope.sendMsg = function (msg) {
            $log.info('sendMsg msg=' + msg);
            cbDashboardFactory.sendMsg(msg)
                .then(function success() {
                    $log.info('sendMsg SUCCESS');
                    //$scope.reload();
                }, function error() {
                    $log.info('sendMsg ERROR');
                    //$scope.reload();
                });

        };

        $rootScope.$watch(function () {
            return AuthFactory.activeDevice.versionInfo;
        }, function (nv) {
            if (nv) {
                $log.info('WATCHER FOR VERSION=',nv);
                $scope.scgSharedFactory.checkForUpdateNotice(nv);
                }
            });



                $scope.$on("$destroy", function() {
            $interval.cancel(intervalStatusInfo);
        });

        $scope.getWeekStats(7);
        window.scrollTo(0, 0);

        //UARGH, jquery for modal clear
        $(document).ready(function() {

            $("#messageModal").on("hidden.bs.modal", function() {
                $log.info('modal hide');
                $(".msgtext").val("");
            });
        });

    });

