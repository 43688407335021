angular.module('ChicoApp.Shared').directive('scgAppList', function (
    $window,
    $document,
    $log,
    $translate,
    $timeout,
    _,
    scgSharedFactory
) {

    return {

        templateUrl: 'templates/shared/scgAppList/scgAppList.html',
        scope: {
            listContent: '=ngModel',
            selectedRows: '=selectedRows',
            scgContext: '=scgContext',
            onItemClick: '&'
        },

        controller: function ($scope, $element) {

            $scope.scgSharedFactory = scgSharedFactory;

            var selectedApps = [];

            $scope.getIcon = function(param){

                if(param === 'checked'){
                    return scgSharedFactory.getImgPrg(param);
                }

                if($scope.scgContext == 'cc'){
                    return scgSharedFactory.getImgPrg(param).toLowerCase() + ".png";
                }else {
                    return scgSharedFactory.getImgApp(param);
                }
            }

            $scope.getIconWinApps = function(param){

                if(param === 'checked'){
                    return scgSharedFactory.getImgPrg(param);
                }

                if($scope.scgContext == 'cc'){
                    return scgSharedFactory.getImgPrg(param).toLowerCase();
                }else {
                    return scgSharedFactory.getImgApp(param);
                }
            }



            $scope.onRowClick = function(row, event){

                var isIconClick = $(event.target).hasClass('ngapplist-row-icon');
                var isCheckBoxClick = $(event.target).is(":checkbox");

                if(isCheckBoxClick || isIconClick ){

                    var included = _.filter(selectedApps, {entry:row.entry});
                    var alreadyChecked = included.length > 0 ? true : false;
                    
                    //manually manage checkbox on incon clicks
                    if(isIconClick){
                        row.isChecked = !alreadyChecked;
                    }

                    if(!alreadyChecked){
                        selectedApps.push(row);
                    }else {
                        selectedApps = _.filter(selectedApps, function(obj){
                            return obj.entry != row.entry;
                        });
                    }

                    //mark or unmark row selected
                    row.selected = !row.selected;

                    $scope.selectedRows = selectedApps;
                    
                } else {
                    $scope.onItemClick({row:row});
                }

            };

        },

        link: function ($scope, $element) {
            // nothing so far
        }
    };

});