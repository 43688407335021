'use strict';
angular.module('ChicoApp.CC-TimeLimits')

.controller('CC-TimeLimitsControllerPC', function(
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $translate,
    $log,
    moment,
    _,
    modalFactory,
    ccTimeLimitsFactory,
    scgGlobalSpinnerFactory,
    ccDashboardFactory,
    scgSharedFactory) {

  var timeTableSaveQueue;

  $scope.data = {};
  $scope.afterExpire = 1;
  $scope.hintHide = false;
  $scope.extensionInfo = '';
  $scope.featureNoExtAvailable = false;

  //init timetable
  $scope.timeTableOptions = {
    reset: true
  };
  $scope.resetTimeTable = function() {

  };

  $scope.openSettingsWithHighlight = function(highlight) {
    $state.go('cc-settings.general', {highlight: highlight});
  };

  $scope.isNoExtAvailable = function() {
    scgSharedFactory.getCCVersionNumber().then(function success(version) {
      $log.info('CCVersion number is:', version);
      if (version.major >= 16 && version.minor > 1942) {
        $scope.featureNoExtAvailable = true;
      }
    });
  };

  $scope.delExtension = function() {
    $log.info('THIS IS DEL EXTENSION');
    scgGlobalSpinnerFactory.blockUI();
    ccDashboardFactory.setExtensionTime(0)
        .then(function success() {
          $log.info('YES, WE DELETED IT');
          $scope.data.extension = 0;
          scgGlobalSpinnerFactory.unblockUI();
          $scope.myExtensionDeleteModal($translate.instant('MSG.EXTENSION_DELETED'));
        });
  };

  $scope.myExtensionDeleteModal = function(msg) {
    scgGlobalSpinnerFactory.unblockUI();
    modalFactory.infoModal.open({
      modal_settings: {
        title: $translate.instant('HEADER.INFO'),
        message: msg,
        butOkLabel: $translate.instant('BUTTON.OK'),
        butCancelLabel: null,
        iconNo: 1,
        refreshStateAfterClose: false
      },
      callbacks: {
        ok: function() {
          $log.info('modal closed with ok -> callback');
        }
      }
    });
  };

  $scope.$on('$viewContentLoaded', function() {

    scgGlobalSpinnerFactory.blockUI();
    $scope.isNoExtAvailable();

    ccTimeLimitsFactory.getPcSettings()

            .then(function success(response) {

              $scope.data = response.data;
              $scope.extensionInfo = $translate.instant('INFO.EXTENSION').replace('%%', response.data.extension);

              $log.info('debug data' , $scope.data);
              $log.info('THIS IS PCDAY=' , $scope.data.pcTimeLimitDay);

              //watch for inputs, if they do not end in having content, refresh page
              $timeout(function() {
                var firstInputValue = angular.element(angular.element('input[ng-model=\'displayedTimeValue\']')[0]).val();
                if (!firstInputValue) {
                  scgSharedFactory.needsSync = false;
                  $state.go($state.current, {}, {reload: true});
                  scgSharedFactory.needsSync = false;
                }
              }, 1200);

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;

                    //save timetable only with timed queue
                    if (k == 'blockedTimesPC') {

                      clearTimeout(timeTableSaveQueue);
                      timeTableSaveQueue = setTimeout(function() {
                        scgSharedFactory.needsSync = true;
                        ccTimeLimitsFactory.setSetting(changed_object);
                      }, 1500);
                    }else {
                      scgSharedFactory.needsSync = true;
                      ccTimeLimitsFactory.setSetting(changed_object);
                    }

                  }
                }, true);
              });

              if (angular.isDefined($scope.data) && angular.isDefined($scope.data.isTimeLimitActive)) {
                scgGlobalSpinnerFactory.unblockUI();
              }else {
                $timeout(function() {
                  $state.go($state.current, {}, {reload: true});
                }, 5000);
              }

            });

  });

  //Hint stuff, Add $scope.hintHide at top of page
  //--------------------------------------------------------------------------------
  scgSharedFactory.getHint(20).                                //adjust hintID here
            then(function success(response) {
              if (response.data.hint20) {                         //adjust hintID here
                $scope.hintHide = response.data.hint20;}        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;}
            }
        );
  $scope.hintDone = function() {
    $scope.hintHide = true;
    scgSharedFactory.setHint(20).then(function success() {
      $log.info('HINT DONE SUCCESS');
    });
  };
  //--------------------------------------------------------------------------------

  ccTimeLimitsFactory.getAllUsersSettings()
            .then(function success(response) {
              $scope.data = response.data;
              $scope.afterExpire = response.data.generalPCLimitLevel;
              $log.info('dierk after expire', $scope.afterExpire);
            }
        );

  ccDashboardFactory.getTodayTotal(moment(new Date()).format('YYYY-MM-DD'))
            .then(function success(response) {
              $scope.todayTotal = $translate.instant('USAGE.TODAYTOTAL') + ': ' + response.data;
            }
        );

});
